import React, { Fragment, FC, useState } from "react";
import { IAsset } from "src/common";

import FullscreenImage from "../../../common/FullscreenImage";
import * as styles from "./NewsArticleItem.style";
import { Wrapper, HeadingWrapper } from "./NewsArticleItem.style";

interface IProps 
{
  headline: string;
  text: any;
  index: number;
  images: IAsset[];
}

const NewsArticleItem: FC<IProps> = props => 
{
  const [isActive, setIsActive] = useState(props?.index === 0);
  if(!props.headline || !props?.text) 
  {
    return null;
  }

  return (
    <Wrapper>
      <div css={`${styles.imgAndText} ${styles.isActive}`}>
        <div css={styles.imgAndSubtitleWrapper}>
          {props.images?.map((asset: IAsset, i: number) => 
            (
              <div className="image" key={`${i}${asset?.src}`}>
                {asset && (
                  <>
                    <FullscreenImage
                      img={[asset]}
                      subtitle={asset.title}
                    />
                   
                  </>
                )}
              </div>
            ))}
        </div>
        <div className="text">
          <HeadingWrapper onClick={() => setIsActive(!isActive)}>
            <h3 className="title">{props.headline}</h3>
          </HeadingWrapper>
          <p>{props.text.content?.[0]?.children?.[0]?.text || ""}</p>
        </div>
        
      </div>
    </Wrapper>
  );
};

export default NewsArticleItem;
