import React, { FunctionComponent } from "react";
import { extrasMapper, IArticle, IAsset } from "src/common";
import { css } from "styled-components";

import RichText from "../RichText/RichText";
import SRichTextGeneral from "../RichText/Styles/SRichTextGeneral";
import * as styles from "./Articles.style";

const Articles: FunctionComponent<any> = (props) => {
    const filteredArticles = props?.connectedArticles?.filter((Article) => Article?.__typename === "Article");

    if (!filteredArticles || filteredArticles.length === 0) {
        return null;
    }

    const Articless = filteredArticles.map((item, index) => {
        const Article = item as IArticle;
        const imgArray = item?.extras?.filter((e) => e?.__typename === "Asset") as IAsset[];
        return (
            <div
                key={index}
                css={
                    index === 0
                        ? css`
                              margin-bottom: 42px;
                          `
                        : css`
                              margin: 42px 0;
                          `
                }
            >
                <h3 css={styles.h3}>{Article?.headline}</h3>
                <div
                    css={css`
                        float: left;
                        width: 33%;
                        margin: 0 30px 0 0;
                    `}
                >
                    {imgArray?.map((img, i) => (
                        <img css={styles.img} src={img?.src} alt={img?.title || "NewsImage"} key={`img-${i}`} />
                    ))}
                </div>
                <RichText overwriteStyle={SRichTextGeneral} textContent={Article?.mainText} />
            </div>
        );
    });

    return (
        <div css={styles.wrapper}>
            {Articless}
            {props.footnote ? (
                <RichText
                    css={styles.footnote}
                    overwriteStyle={SRichTextGeneral}
                    textContent={props.footnote}
                ></RichText>
            ) : null}
        </div>
    );
};

export default Articles;
