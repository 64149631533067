import React, { FunctionComponent, Fragment, useContext } from "react";
import { Context, getPage, ITextElement, ISidebarText, IAsset, Img, IPhotoGallery, IContext } from "src/common";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";
import UniversalComponentsRenderer from "src/common/BaseComponents/UniversalCompoentRenderer/UniversalComponentsRenderer";
import PhotoGallery from "src/common/components/PhotoGalleryVisualization";

import Articles from "../../common/Articles";
import FurtherAwards from "../../common/FurtherAwards";
import { Section } from "../../common/Grid";
import PageHeading from "../../common/PageHeading";
import ProductItem from "../../common/ProductItem";
import { RichTextGeneral } from "../../common/RichText";
import SubNavRight from "../../common/SubNavRight";
import TextBoxRight from "../../common/TextBoxRight";
import Hero from "./Hero";
import * as styles from "./MainContent.style";
import { ContentContainer, ContentWrapper, UniversaElementWrapper, TextElementWrapper, Frame } from "./MainContent.style";

const getExternalLink = (p: IContext): string => p.PageMicro?.slug === p?.slug && p.PageMicro.externalLinkAddress;

const MainContent: FunctionComponent<any> = (props) =>
{
  const p = useContext(Context);
  const page = getPage(p);
  const pageCourse = p?.PageCourse;

  // @ts-ignore
  const sideBarText = page?.extras?.find((e) => e?.__typename === "SidebarText") as ISidebarText;

  // @ts-ignore
  const mixedElements = page?.elements?.filter(
    (e) => e?.__typename === "ContinuingPageGallery" || e?.__typename === "PhotoGallery" || e?.__typename === "TextElement"
  ) as any[] || pageCourse?.elements as any[];

  const connectedArticles = (page as any)?.connectedArticles;
  const navItem = p?.PrimaryNavigation?.items?.find(
    (i) => !!i?.subitems?.find((s) => s?.slug === p.slug) || i?.link?.[0]?.slug === p.slug
  );
  const TopComponent = props.topComponent;
  // @ts-ignore
  const ContinuingPageGallery = mixedElements?.find(item => item?.__typename === "ContinuingPageGallery");
  // @ts-ignore
  const headingTextElement = page?.elements?.find(item => item?.__typename === "TextElement");
  // @ts-ignore
  const headingImageElement = page?.elements?.find(item => item?.__typename === "Asset") as IAsset;

  const externalLinkAddress = getExternalLink(p);
  return (
    <Section>
      {!TopComponent && (
        <PageHeading imageUrl={headingImageElement?.src} headLine={headingTextElement?.headline}/>
      )}
      {(navItem || sideBarText) && (
        <>
          <SubNavRight />
          <TextBoxRight text={sideBarText?.text} />
        </>
      )}

      {externalLinkAddress && (
        <Frame src={externalLinkAddress} />
      )}
      
      <ContentContainer topComponent={TopComponent && true}>
        <Hero/>
        {headingTextElement?.text && (
          <TextElementWrapper news={props.news}>
            {headingTextElement?.text && <RichTextGeneral fragment textContent={headingTextElement?.text} />}
          </TextElementWrapper>
        )}

        <ContentWrapper topComponent={TopComponent && true}>
          {mixedElements &&
              mixedElements.map((e, i) =>
              {
                const textElement = e?.__typename === "TextElement" && e as ITextElement;
                const universlElement = (e?.__typename === "ContinuingPageGallery") && e as ITextElement;
                const photoGallery = e?.__typename === "PhotoGallery" && e as IPhotoGallery;

                if(textElement === headingTextElement) { return null; }
                
                return (
                  <Fragment key={`mixedElements${i}`}>
                    {textElement &&
                      <TextElementWrapper column news={props.news}>
                        {textElement?.icon?.map((i, index) => 
                        {
                          
                          if(i.src) 
                          {
                            return (
                              <Img
                                key={`i${index}`}
                                quality={1020}
                                src={[i]}
                                contain
                                alt={i.title}
                              >{i.title && <p css={styles.figcaption}>{i.title}</p>}</Img>
                            );
                          }
                          return null;
                        })}
                        {textElement?.text && <RichTextGeneral fragment textContent={textElement?.text} />}
                      </TextElementWrapper>
                    }

                    {universlElement &&
                      <UniversaElementWrapper>
                        <UniversalComponentsRenderer items={[universlElement]}>
                          {({ item }) => <ProductItem item={item} />}
                        </UniversalComponentsRenderer>
                        <Spacer bronze={20} silver={30}></Spacer>
                      </UniversaElementWrapper>
                    }

                    {photoGallery &&
                      <UniversaElementWrapper>
                        <PhotoGallery item={photoGallery} />
                        <Spacer bronze={20} silver={30}></Spacer>
                      </UniversaElementWrapper>
                    }
                  </Fragment>
                );
              })}
          <Articles connectedArticles={connectedArticles} footnote={page?.footnote} />
          {TopComponent && (<TopComponent />)}
        </ContentWrapper>
        {props.children}
      </ContentContainer>
      {!props.noAwards && !props.course && <FurtherAwards />}
    </Section>
  );
};

export default MainContent;
