import React, { Fragment, FC, useState } from "react";
import { IAsset } from "src/common";

import FullscreenImage from "../../../common/FullscreenImage";
import { RichText } from "../../../common/RichText";
import * as styles from "./NewsArticleItem.style";
import { Wrapper, HeadingWrapper, ImgAndSubtitleWrapper, RichTextWrapper } from "./NewsArticleItem.style";
import { FancyImageWithModal } from "src/common/BaseComponents/FancyImageWithModal/FancyImageWithModal";
import { css } from "styled-components";

interface IProps {
    headline: string;
    text: any;
    index: number;
    images: IAsset[];
}

const NewsArticleItem: FC<IProps> = (props) => {
    const [isActive, setIsActive] = useState(props?.index === 0);
    if (!props.headline || !props?.text) {
        return null;
    }

    return (
        <Wrapper isActive={isActive && props.images.length > 2}>
            <HeadingWrapper onClick={() => setIsActive(!isActive)}>
                <h3 className="title">{props.headline}</h3>
                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="#92c2d6">
                    <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg>
            </HeadingWrapper>

            <p>{props.text.content?.[0]?.children?.[0]?.text || ""}</p>

            <div
                css={css`
                    ${styles.imgAndText};
                `}
            >
                <ImgAndSubtitleWrapper isActive={isActive}>
                    {props.images?.map((asset: IAsset, i: number) => (
                        <div
                            className="image"
                            key={`${i}${asset?.src}`}
                            css={
                                i !== 0 &&
                                !isActive &&
                                css`
                                    display: none;
                                `
                            }
                        >
                            {asset && (
                                <>
                                    <FancyImageWithModal
                                        img={asset}
                                        responsiveAspectRatio={{ bronze: 230 / 153 }}
                                        responsiveFactor={{ bronze: 0.9, silver: 0.35 }}
                                        responsiveMaxWidth={{ platinum: 1366 * 0.35 }}
                                    />
                                    {asset.description ? <p css={styles.subTitle}>{asset.description}</p> : null}
                                </>
                            )}
                        </div>
                    ))}
                </ImgAndSubtitleWrapper>
                <RichTextWrapper isActive={isActive}>
                    <RichText textContent={props?.text} />
                </RichTextWrapper>
            </div>
            <div
                css={`
                    ${isActive ? styles.isActiveStyle : styles.isNotActiveStyle}
                `}
                onClick={() => setIsActive(!isActive)}
            >
                <div style={{ display: "flex", cursor: "pointer" }}>
                    {isActive ? (
                        <>
                            <p className="bottom-button">SCHLIESSEN</p>
                            <svg
                                style={{
                                    transform: "rotate(180deg) scale(.7)",
                                    marginLeft: "10px",
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="34"
                                height="34"
                                viewBox="0 0 24 24"
                                fill="#92c2d6"
                            >
                                <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                            </svg>
                        </>
                    ) : (
                        <>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="34"
                                height="34"
                                viewBox="0 0 448 512"
                                fill="#92c2d6"
                            >
                                <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                            </svg>
                            <p className="bottom-button">MEHR</p>
                        </>
                    )}
                </div>
            </div>
        </Wrapper>
    );
};

export default NewsArticleItem;
