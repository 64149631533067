import styled from "styled-components";

export const Wrapper: any = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  p {
    font-weight: 300;
    color: #736654;
    font-size: 14px;
  }

  h6,
  h5
  h4,
  h3,
  ul {
    margin-bottom: 21px;
  }
  h6 {
    font-weight: 700;
    &:first-of-type {
      margin-top: 0;
    }
  }

  ul {
    margin-left: 21px;
  }
`;

