import React, { FunctionComponent, Fragment, useContext, useState } from "react";
import { Context, getPage, ITextElement, ISidebarText, IAsset, Img, IPhotoGallery } from "src/common";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";
import UniversalComponentsRenderer from "src/common/BaseComponents/UniversalCompoentRenderer/UniversalComponentsRenderer";
import PhotoGallery from "src/common/components/PhotoGalleryVisualization";

import Articles from "../../common/Articles";
import Micro from "../../common/Micro";
import { PlanningDateModaL } from "../../common/PlanningDateModaL/PlanningDateModaL";
import ProductItem from "../../common/ProductItem";
import { RichTextGeneral } from "../../common/RichText";
import NewsContent from "../NewsContent";
import Hero from "./Hero";
import * as styles from "./MainContent.style";
import {
  ContentContainer,
  ContentWrapper,
  UniversaElementWrapper,
  ElementWrapper,
  ImageInText,
  Title,
  ImageWRapper,
  ImageContent,
  TextWrapper,
  MixedWrapper,
  FirstTextElementWrapper,
  Wrapper,
  NavBarSubitemsWrapper,
  NavBarSubitemsContent,
  NavBarSubitemsButton,
  HorizontalSeparator
} from "./MainContent.style";

const TextElement: FunctionComponent<any> = ({ firstTextElement, textElement, universlElement }) => 
  (
    <>
      {<Title isFirst={firstTextElement === textElement}>{textElement?.headline}</Title>}
      <ImageInText column={textElement?.icon?.length < 1 && textElement?.text} >
        {textElement?.text &&  
          <div className="side">
            <TextWrapper withImg={textElement?.icon?.length} universlElement={universlElement}><RichTextGeneral fragment textContent={textElement?.text} /></TextWrapper>
          </div>
        }
        {textElement?.icon?.length > 0 && (
          <div className="side">
            <ImageWRapper wrap={!textElement?.text}>
              {textElement?.icon?.map((i: IAsset, index: number) => 
              {
                if(i.src) 
                {
                  return (
                    <ImageContent key={`i${index}`}>
                      <Img
                        quality={1020}
                        src={[i]}
                        contain
                        alt={i.title}
                      />
                      {i.title && <p css={styles.figcaption}>{i.title}</p>}
                    </ImageContent>
                  );
                }
                return null;
              })}
            </ImageWRapper>
          </div>
        )
        }
      </ImageInText>
    </>
      
  )

const MainContent: FunctionComponent<any> = (props) => 
{
  const p = useContext(Context);
  const page = getPage(p);
  const [isShowModal, toogleShowModal] = useState(false);
  const sideBarText = page?.extras?.find((e) => e?.__typename === "SidebarText") as ISidebarText;

  // @ts-ignore
  const mixedElements = page?.elements?.filter(
    (e) => e?.__typename === "ContinuingPageGallery" || e?.__typename === "PhotoGallery" || e?.__typename === "TextElement" || e?.__typename === "Asset" || e?.__typename === "CallToAction"
  ) as any[];
  const connectedArticles = (page as any)?.connectedArticles;

  const navBarSubitems =
    p.PrimaryNavigation?.items?.map(item => item.subitems?.length > 0 && item.link[0].slug === p.slug && item)
      .filter(item => item);

  const navBarSubitemsSecondary =
    p.PrimaryNavigation?.items?.map(item => item.subitems?.length > 0 && item.subitems)
      .filter(item => item)
      .map(item => item.find(element => element?.slug === /* "finanzierung"*/ p?.slug) && item)
      .find(item => item);

  const TopComponent = props.topComponent;
  const firstTextElement = mixedElements?.find(element => element?.__typename === "TextElement");
  const isFirstTextElements = !page?.extras?.length;
  const isWithoutHero = mixedElements?.length <= 1 && !p?.PageNews;
  const isPageGallery = mixedElements?.find(element => element?.__typename === "PhotoGallery") && true;
  const isUniversalElement = mixedElements?.find(element => element?.__typename === "ContinuingPageGallery") && true;
  const buttonLabel = mixedElements?.find(element => element.__typename === "CallToAction");
  // const isPlanner = mixedElements?.find(element => element?.text?.content?.find(item => item?.children?.find(child => child?.url)));
  
  return (
    <Wrapper isUniversalElement={isUniversalElement || TopComponent || props.guestBook || props.coursePage || p.slug === "kuechenplaner"}>
      {p?.PageMicro?.externalLinkAddress && (
        <Micro />
      )
      }
      <Hero topComponent={TopComponent} pageBrand={props.pageBrand} />
      {isShowModal && <PlanningDateModaL onSetShowModal={toogleShowModal}/>}
      {p.slug !== "kuechenplaner" && !props.coursePage && (navBarSubitems?.length > 0 || navBarSubitemsSecondary?.length > 0) && (
        <NavBarSubitemsWrapper>
          <NavBarSubitemsContent>
            {navBarSubitems[0]?.subitems?.length > 0 && navBarSubitems[0].subitems.map((subitem, i) => (
              <NavBarSubitemsButton href={`/${subitem?.slug}`} key={i}>
                {subitem?.nameInNavigation}{i !== navBarSubitems[0].subitems.length - 1 && <HorizontalSeparator />}
              </NavBarSubitemsButton>
            ))}

            {navBarSubitemsSecondary?.length > 0 && navBarSubitemsSecondary.map((subitem, i) => (
              <NavBarSubitemsButton href={`/${subitem?.slug}`} key={i} isCurrent={subitem?.slug === p.slug}>
                {subitem?.nameInNavigation}{i !== navBarSubitemsSecondary.length - 1 && <HorizontalSeparator />}
              </NavBarSubitemsButton>
            ))}

          </NavBarSubitemsContent>
        </NavBarSubitemsWrapper>
      )}

      {firstTextElement && (isFirstTextElements || props.news) && (
        <FirstTextElementWrapper news={props.news} withoutHero={isWithoutHero}>
          <ImageInText column={firstTextElement && true} maxWidth={isPageGallery} isUniversalElement={isUniversalElement} coursePage={props.coursePage} planner={p.slug === "kuechenplaner"}>
            {firstTextElement?.icon?.map((i: IAsset, index: number) => 
            {
              if(i.src) 
              {
                return (
                  <Fragment key={`i${index}`}>
                    <Img
                      quality={1020}
                      src={[i]}
                      contain
                      alt={i.title}
                    >{i.title && <p css={styles.figcaption}>{i.title}</p>}</Img>
                  </Fragment>
                );
              }
              return null;
            })}
            {firstTextElement?.text && <RichTextGeneral fragment textContent={firstTextElement?.text} />}
          </ImageInText>
        </FirstTextElementWrapper>
      )}
      <ContentContainer withoutHero={isWithoutHero && !firstTextElement}>
        <ContentWrapper pageBrand={props.pageBrand}>
          <MixedWrapper>
            {mixedElements &&
            mixedElements.map((e, i) => 
            {
              const textElement = e?.__typename === "TextElement" && e as ITextElement;
              const imageElement = TopComponent && e?.__typename === "Asset" && e as IAsset;
              const universlElement = (e?.__typename === "ContinuingPageGallery") && e as ITextElement;
              const photoGallery = e?.__typename === "PhotoGallery" && e as IPhotoGallery;

              if(firstTextElement === textElement && (isFirstTextElements || props.news))
              {
                return null;// <div style={{ marginTop: "-20px" }}/>;
              }

              return (
                <ElementWrapper key={`mixedElements${i}`}>
                  {imageElement &&
                    <ImageInText>
                      <Img
                        alt={imageElement?.title}
                        src={[imageElement]}
                        quality={1080}
                      />
                    </ImageInText>
                  }
                  {textElement &&
                    <TextElement firstTextElement={firstTextElement} textElement={textElement} universlElement={isUniversalElement}/>
                  }

                  {universlElement &&
                    <UniversaElementWrapper universlElement={universlElement}>
                      <UniversalComponentsRenderer items={[universlElement]}>
                        {({ item }) => <ProductItem item={item} />}
                      </UniversalComponentsRenderer>
                      <Spacer bronze={20} silver={30}></Spacer>
                    </UniversaElementWrapper>
                  }

                  {photoGallery &&
                    <UniversaElementWrapper>
                      <PhotoGallery item={photoGallery} />
                      <Spacer bronze={20} silver={30}></Spacer>
                      {buttonLabel && <div className="button-wrapper"><button className="button-label" onClick={() => toogleShowModal(true)}>{buttonLabel.ButtonLabel}</button></div>}
                    </UniversaElementWrapper>
                  }
                </ElementWrapper>
              );
            })}
          </MixedWrapper>
          {props.news && <NewsContent />}
          <Articles connectedArticles={connectedArticles} footnote={page?.footnote} />
        </ContentWrapper>
      </ContentContainer>
      {props.children}
    </Wrapper>
  );
};

export default MainContent;
