import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const Wrapper: any = styled.div`
  padding: 0 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  
  @media (${BREAKPOINTS_DM.gold_1024}) {
    padding: 0 70px;
  }
  @media (${BREAKPOINTS_DM.platinum_1366}) {
    padding: 0;
  }
`;

export const SubNavigationCard: any = styled.div`
  padding: 10px;
  position: relative;
  width: 95%;
  background-color: #BCBCBC;
  color: #5E5E5D;
  margin: 0 10px 24px;
  a {
    width: 50px;
    height: 50px;
    background-color: #F19024;
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    width: 47.3%;
  }
  @media (${BREAKPOINTS_DM.platinum_1366}) {
    width: 48%;
  }
`;

export const SubNavName: any = styled.div`
  font-size: 18px;
  color: #5E5E5D;
  line-height: 24.75px;
  font-weight: 300;
`;

export const SubNavHeadLine: any = styled.div`
  font-size: 18px;
  color: #5E5E5D;
  line-height: 24.75px;
  font-weight: 400;
  margin-bottom: 20px;
`;

export const TextWrapper: any = styled.div`
  p {
    font-size: 16px;
    color: #5E5E5D;
    line-height: 24.75px;
    font-weight: 100;
  }
`;

export const Button: any = styled.div`
  div {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border: 1px solid #F19024;
    background-color: #F19024;
  }
`;
