import React, { FC, useContext } from "react";
import { Context } from "src/common";

import { Wrapper, SubItemsWrapper, SubItem, SubItemName, SubItemImg } from "./SubItemNavigation.styles";
 
const SubItemNavigation: FC<any> = () => 
{
  const p = useContext(Context);
  const subItemsNavigation = 
    p?.PrimaryNavigation?.items?.find(primaryItem => primaryItem?.subitems?.find(secondaryItem => secondaryItem?.slug === p.slug))
    || p?.PrimaryNavigation?.items?.find(primaryNav => primaryNav.link[0]?.slug === p.slug && primaryNav?.subitems);
  
  return (
    <Wrapper>
      <SubItemsWrapper>
        {subItemsNavigation && subItemsNavigation?.subitems?.map((subItem, ind) => (
          <>
            {subItem.nameInNavigation &&  
            <SubItem key={`key-subitem-${ind}`}>
              <a href={subItem.slug}>
                <SubItemImg>
                  <span>Ohne Bild</span>
                </SubItemImg>
              </a>
              <SubItemName href={subItem.slug} isActive={subItem.slug === p.slug}>
                <span className="subitem_name">{subItem.nameInNavigation}</span>
              </SubItemName>
            </SubItem>
            }
          </>
        ))}
        {subItemsNavigation && subItemsNavigation?.subitems?.length % 3 !== 0 && (
          <SubItem/>
        )}
      </SubItemsWrapper>
    </Wrapper>
  );
};

export default SubItemNavigation;
