import React, { FC } from "react";
import RichTextGeneral from "../RichText";
import { Wrapper } from "./TextBoxRight.style";

const TextBoxRight: FC<{
  text?: string | null | undefined;
  isFounderPage?: boolean;
}> = (props) => (
  <>
    {props?.text && (
      <Wrapper isFounderPage={props.isFounderPage}>
        <RichTextGeneral textContent={props.text} />
      </Wrapper>
    )}
  </>
);

export default TextBoxRight;
