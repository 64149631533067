import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import React, { FC } from "react";
import styled from "styled-components";

export const ItemDefaultWrapper: FC<{ isProduct?: boolean }> = styled.div<{ isProduct?: boolean }>`
  width: 100%;
  color: #fff;
  a {
    h2 {
      color: #fff;
      font-size: 26px;
      line-height: 30px;
      font-weight: 400;
      margin: 30px 0 21px;
    }
    p {
      color: #fff;
      font-size: 16px;
      line-height: 21px;
      font-weight: 300;
      margin: 0 0 20px;
    }
     
  }
  button {
    background-color: transparent;
    border: 1px solid #fff;
    padding: 5px 10px;
    font-size: 13px;
    color: #fff;
    font-weight: 300;
    &:hover {
      background-color: var(--color-primary-light);
    }
  }
  div.button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 426.66px;
  }
`;

export const ImageWrapper: FC<{ isProduct?: boolean }> = styled.div<{ isProduct?: boolean }>`
  ${({ isProduct }) => isProduct && `
    height: 100%;

    div > img {
      height: 314.39px;
    }
  `}
`;

export const Description: FC<{ isProduct?: boolean }> = styled.div<{ isProduct?: boolean }>`
  span {
    color: #fff;
    font-size: 15px;
    line-height: 21px;
    font-weight: 300;
  }

  svg {
    vertical-align: middle;
    height: 15px;
    fill: #F8F8F8;
    width: 15px;
  }
`;

export const Price: FC = styled.div`
  display: flex;
  padding-bottom: 31px;
  border-bottom: 1px solid #DDDFDF;
  margin-bottom: 10px;
  span.price {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }

  span.coin {
    font-size: 27px;
    font-weight: 100;
    color: #fff;
    margin-left: 5px;
  }
`;

export const Text: any = styled.div`
  background: #DDDFDF;
  padding: 10.5px;
  color: #777878;
  margin: 0 0 10.5px; 
  span {
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
  }
  span:last-child {
    margin-bottom: 20px;
  }
`;