import React, { useContext, FunctionComponent, Fragment, useState, useEffect } from "react";
import { Context, getPage, ITextElement, ISidebarText, IAsset, Img } from "src/common";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";
import UniversalComponentsRenderer from "src/common/BaseComponents/UniversalCompoentRenderer/UniversalComponentsRenderer";
import { css } from "styled-components";

import ImageWithAspectRatio from "../../../BaseComponents/ImageWithAspectRatio/ImageWithAspectRatio";
import Articles from "../Articles/Articles";
import { Gallery } from "../gallery/Gallery";
import Micro from "../Micro";
import RichText from "../RichText/RichText";
import SRichTextGeneral from "../RichText/Styles/SRichTextGeneral";
import { SubNavRight } from "../SubNavRight/SubNavRight";
import { TextBoxRight } from "../TextBoxRight/TextBoxRight";
import * as styles from "./MainContent.style";
import {
  ContentContainer,
  ContentWrapper,
  SideBarWrapper,
  Title,
 } from "./MainContent.style";

export const MainContent: FunctionComponent<any> = (props) => 
{
  const p = useContext(Context);
  const page = getPage(p);
  const sideBarText = page?.extras?.find((e) => e?.__typename === "SidebarText") as ISidebarText;
  // @ts-ignore
  const imgArray = page?.elements?.filter((e) => e?.__typename === "Asset") as IAsset[];
  // @ts-ignore
  const slider = page?.elements?.filter((e) => e?.__typename === "Slider") as any[];
  // @ts-ignore
  const mixedElements = page?.elements?.filter(
        (e) => e?.__typename === "ContinuingPageGallery" || e?.__typename === "PhotoGallery" || e?.__typename === "TextElement" || e?.__typename === "Asset"
    ) as any[];
  const connectedArticles = (page as any)?.connectedArticles;
  const TopComponent = props.topComponent;
  const navItem = p?.PrimaryNavigation?.items?.find(
        (i) => !!i?.subitems?.find((s) => s?.slug === p.slug) || i?.link?.[0]?.slug === p.slug
    );

  const isShowSlider = slider && (slider.length > 0 || slider?.[0]?.elements.length > 0);
  let imageOrGallery;
  if(imgArray?.[0] || isShowSlider) 
  {
    if(imgArray.length > 1 || isShowSlider) 
    {
      if(slider) 
      {
        imageOrGallery = <Gallery imgArray={slider?.[0]?.elements} />;
      }
      else 
      {
        imageOrGallery = <Gallery imgArray={[imgArray?.[0]]} />;
      }
    }
    else 
    {
      imageOrGallery = (
        <ImageWithAspectRatio
          alt={imgArray?.[0]?.description}
          src={[imgArray?.[0]]}
          height={342}
          ratio={50}
          quality={1080}
        />
      );
    }
  }

  return (
    <>
      <ContentContainer micro={props.micro}>
        <ContentWrapper>
          {props.micro && <Micro/> }
          <>
            {TopComponent ? (
              <>
                <TopComponent />
                <div style={{ margin: "20px 0" }} />
              </>

            ) : (
              <>
                {imageOrGallery}
                {imageOrGallery ? <div style={{ margin: "20px 0" }} /> : null}
              </>
            )}
          </>
          {mixedElements?.map((e, i) => 
          {
            const textElement = e?.__typename === "TextElement" && e as ITextElement;
            const imageElement = TopComponent && e?.__typename === "Asset" && e as IAsset;
            const universlElement = (e?.__typename === "ContinuingPageGallery" || e?.__typename === "PhotoGallery") && e as ITextElement;
            return (
              <Fragment key={`mixedElements${i}`}>
                {imageElement &&
                  <>
                    <div css={css`${SRichTextGeneral};${styles.imageInText}` as any}>
                      <Img
                        alt={imageElement?.description}
                        src={[imageElement]}
                        quality={1080}
                      />
                    </div>
                  </>
                }
                {textElement &&
                  <>
                    <Title>{textElement?.headline}</Title>
                    <div css={css`${SRichTextGeneral};${styles.imageInText}` as any}>
                      {textElement?.icon?.map((img, index) => 
                      {
                        if(img?.src) 
                        {
                          return (
                            <Fragment key={`img${index}`}>
                              <Img
                                quality={1020}
                                src={[img]}
                                contain
                                alt={img.description}
                              >{img.description && <p css={styles.figcaption}>{img.description}</p>}</Img>
                            </Fragment>
                          );
                        }
                        return null;
                      })}
                      {textElement?.text && <RichText fragment textContent={textElement?.text} />}
                    </div>
                  </>
                }
                {universlElement &&
                  <>
                    <UniversalComponentsRenderer items={[universlElement]}></UniversalComponentsRenderer>
                    <Spacer bronze={20} silver={30}></Spacer>
                  </>
                }
              </Fragment>
            );
          })}
          <Articles connectedArticles={connectedArticles} footnote={page?.footnote} />
        </ContentWrapper>
        {(navItem || sideBarText) && !props.micro && (
          <SideBarWrapper>
            <SubNavRight />
            <TextBoxRight text={sideBarText?.text} />
          </SideBarWrapper>
        )}
      </ContentContainer>
      {props.children}
    </>
  );
};
