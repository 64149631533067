import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { theme } from "../../../constants/themes";

export const Wrapper: any = styled.div<{ currentArticle: boolean}>`
  background-color: ${({ currentArticle }) => currentArticle ? "#fff" : `${theme.palette.default}!important`};
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 0px auto 0px;
`;

export const WrapperNews: any = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 0px auto 0px;
  padding: 20px 15px;
`;

export const NavigationPage: any = styled.nav`
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: nowrap;
    margin: 12px 0px 60px 0px;

    svg.olderNewsSvg {
      transform: rotate(90deg);
      padding: 2px 0px 0px 8px;
      margin-right: 10px;
    }

    svg.latestNewsSvg {
      transform: rotate(270deg);
      padding: 2px 8px 0px 0px;
      margin-left: 10px;
    }

    a.olderNews {
      padding: 0px 10px 6px 4px;
    }

    a.latestNews {
      padding: 0px 6px 6px 10px;
    }

    a {
      background-color: ${({ theme }) => theme.palette.light_blue};
      color: white;
      text-align: center;
      border-radius: 4px;
      display: flex;

      > p {
        margin: 6px 0 0;
        color: #fff;
      }
    }
    @media (max-width: 500px)
    {
        a {
          padding: 4px 10px !important;
        }
    }
`;

export const NewsArticleWrapper: any = styled.div`
    width: 100%;
    max-width: 1400px;
    margin: auto;
    @media (${BREAKPOINTS_DM.silver_768}) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 30px;
    }
`;

export const Container = styled.div`
  width: 100%;
`;
