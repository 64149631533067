import React, { FunctionComponent, Fragment, useContext } from "react";
import {
  Context,
  getPage,
  ITextElement,
  ISidebarText,
  IAsset,
  Img,
  IPhotoGallery,
} from "src/common";
import UniversalComponentsRenderer from "src/common/BaseComponents/UniversalCompoentRenderer/UniversalComponentsRenderer";
import PhotoGallery from "src/common/components/PhotoGalleryVisualization";
import { css } from "styled-components";

import Articles from "../../common/Articles";
import { Container, Section } from "../../common/Grid";
import Hero from "../../common/Hero";
import Micro from "../../common/Micro";
import ProductItem from "../../common/ProductItem";
import { RichTextGeneral, SRichTextGeneral } from "../../common/RichText";
import SubNavRight from "../../common/SubNavRight";
import TextBoxRight from "../../common/TextBoxRight";
import * as styles from "./MainContent.style";
import {
  ContentContainer,
  HeroWrapper,
  ContentWrapper,
  SideBarWrapper,
  ElementsWrapper,
  UniversaElementWrapper,
  ImagesContainer,
  ImageWrapper,
  BoxedContent,
  RichTextWrapper,
  ImageInText,
  TextElementWrapper,
} from "./MainContent.style";
import { FounderForm } from "../../common/FounderForm/FounderForm";

const MainContent: FunctionComponent<any> = (props) => {
  const p = useContext(Context);
  const page = getPage(p);

  const sideBarText = page?.extras?.find(
    (e) => e?.__typename === "SidebarText"
  ) as ISidebarText;

  // @ts-ignore
  const mixedElements = page?.elements?.filter(
    (e) =>
      e?.__typename === "ContinuingPageGallery" ||
      e?.__typename === "PhotoGallery" ||
      e?.__typename === "TextElement" ||
      e?.__typename === "Asset"
  ) as any[];
  const connectedArticles = (page as any)?.connectedArticles;
  const headText = mixedElements?.find(
    (e) => e?.__typename === "TextElement"
  ) as ITextElement;

  const imgElement = mixedElements?.find(
    (e) => e?.__typename === "Asset"
  ) as IAsset;

  const isLink = headText?.text?.content?.find((el) =>
    el?.children?.find((link) => link.url)
  );

  const isFounderPage = p?.slug === "gruender";

  return (
    <Container>
      {p?.PageMicro?.externalLinkAddress && <Micro />}
      <Section>
        <HeroWrapper>
          <Hero page={page} />
        </HeroWrapper>
        <ContentContainer>
          {headText && !isLink && (
            <BoxedContent>
              <h1>{headText.headline}</h1>
            </BoxedContent>
          )}

          <ContentWrapper>
            {sideBarText?.text && sideBarText.text.length > 0 && (
              <SideBarWrapper>
                <SubNavRight />
                <TextBoxRight text={sideBarText.text} />
              </SideBarWrapper>
            )}
            <ElementsWrapper isFounderPage={isFounderPage}>
              {mixedElements &&
                mixedElements.map((e, i) => {
                  const textElement =
                    e?.__typename === "TextElement" && (e as ITextElement);
                  const imageElement =
                    e?.__typename === "Asset" && (e as IAsset);

                  const universlElement =
                    e?.__typename === "ContinuingPageGallery" &&
                    (e as ITextElement);

                  const photoGallery =
                    e?.__typename === "PhotoGallery" && (e as IPhotoGallery);

                  const linkElement = textElement?.text?.content
                    ?.find((element) =>
                      element?.children?.find((link) => link?.url)
                    )
                    ?.children?.find((link) => link?.url);

                    if(imageElement && imageElement == imgElement) {
                      return null;  
                    }
                    if(headText && headText == textElement && !headText.text) {
                      return null;  
                    }

                  const isLastChild =
                    textElement?.icon?.length > 0 && textElement?.text && i === mixedElements.length - 1;

                    const classNames: string[] = [];
                    if (isLastChild){
                      classNames.push("last");
                    }
                    if (textElement){
                      classNames.push("text");
                      if (textElement.icon && textElement.icon?.length > 0){
                        classNames.push("withicon");
                      }
                      if (textElement.text){
                        classNames.push("withtext");
                      }
                      if (textElement.headline){
                        classNames.push("withheadline");
                      }
                    }
                    if (imageElement){
                      classNames.push("asset");
                    }
                    
                  return (
                    <section className={classNames.join(" ")} id={`abschnitt${i + 1}`} key={`mixedElements${i}`}>
                      {imageElement !== imgElement && imageElement && (
                        <div
                          css={
                            css`
                              ${SRichTextGeneral}${styles.imageInText}
                            ` as any
                          }
                        >
                          <Img
                            alt={imageElement?.title}
                            src={[imageElement]}
                            quality={1080}
                          />
                        </div>
                      )}
                      {textElement && !linkElement ? (
                        <>
                          {textElement !== headText &&
                            textElement?.headline && (
                              <h1 className="ontextblock">
                                {textElement.headline}
                              </h1>
                            )}
                          {(textElement?.text ||
                            textElement?.icon?.length > 0) && (
                            <TextElementWrapper
                              isLastChild={isLastChild}
                              isFounderPage={isFounderPage}
                              css={
                                css`
                                  ${SRichTextGeneral}${styles.imageInText}
                                ` as any
                              }
                            >
                              {textElement?.icon?.length > 0 && (
                                <ImagesContainer>
                                  {textElement?.icon?.map((i, index) => {
                                    if (i?.src) {
                                      return (
                                        <ImageWrapper key={`i${index}`}>
                                          <Img
                                            quality={1020}
                                            src={[i]}
                                            contain
                                            alt={i.description}
                                          />
                                        </ImageWrapper>
                                      );
                                    }
                                    return null;
                                  })}
                                </ImagesContainer>
                              )}
                              {textElement?.text && (
                                <RichTextWrapper isFounderPage={isFounderPage}>
                                  <RichTextGeneral
                                    fragment
                                    textContent={textElement?.text}
                                  />
                                  {isFounderPage && <FounderForm />}
                                </RichTextWrapper>
                              )}
                            </TextElementWrapper>
                          )}
                        </>
                      ) : (
                        textElement &&
                        linkElement && (
                          <>
                            <ImageInText href={linkElement.url} target="_blank">
                              {textElement?.icon?.length > 0 && (
                                <ImagesContainer>
                                  {textElement?.icon?.map((i, index) => {
                                    if (i?.src) {
                                      return (
                                        <ImageWrapper key={`i${index}`}>
                                          <Img
                                            quality={1020}
                                            src={[i]}
                                            contain
                                            alt={i.description}
                                          >
                                            {i.description && (
                                              <p>{i.description}</p>
                                            )}
                                          </Img>
                                        </ImageWrapper>
                                      );
                                    }
                                    return null;
                                  })}
                                </ImagesContainer>
                              )}
                              <h1 className="link-title">
                                {textElement?.headline}
                              </h1>
                              {textElement?.text && (
                                <RichTextGeneral
                                  fragment
                                  textContent={textElement?.text}
                                />
                              )}
                            </ImageInText>
                          </>
                        )
                      )}
                      {universlElement && (
                        <UniversaElementWrapper>
                          <UniversalComponentsRenderer
                            items={[universlElement]}
                          >
                            {({ item }) => <ProductItem item={item} />}
                          </UniversalComponentsRenderer>
                        </UniversaElementWrapper>
                      )}
                      {photoGallery && (
                        <UniversaElementWrapper>
                          <PhotoGallery item={photoGallery} />
                        </UniversaElementWrapper>
                      )}
                    </section>
                  );
                })}
              {props.children}
              <Articles
                connectedArticles={connectedArticles}
                footnote={page?.footnote}
              />
            </ElementsWrapper>
          </ContentWrapper>
        </ContentContainer>
      </Section>
    </Container>
  );
};

export default MainContent;
