import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const ContentContainer: any = styled.div`
    display: flex;
    flex-direction: column;
    max-width: ${({ theme }) => theme.maxWidth}px;
    width: 100%;
    margin: 0px auto;
    padding: 0 10px;
    @media (${BREAKPOINTS_DM.gold_1024}) {
        flex-direction: row;
        padding: 0 15px;
    }
    .c-frame {
      iframe {
        width: 280%;
        height: 364px;
      }
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      &,
      span {
        color:  ${({ theme }) => theme?.palette?.light_blue} !important;
       
      }
    }

    h1,
    h2,
    h3 {
        &,
        span {
            font-weight: 300;
        }
    }
    h2 {
      font-size: 16px;
      line-height: 20px;
    }

    h1.title {
      margin: 12px 0 16px;
      color: #80C7DC;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }

    
    a > span {
      color:  ${({ theme }) => theme?.palette?.light_blue};
    }
    p.c-empty {
      min-height: 16px;
    }
`;

export const ContentWrapper: any = styled.div<{pageBrand: boolean}>`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${({ pageBrand }) => pageBrand && "0 18px 0 0"};
   
    h1.title_main_head {
      margin: ${({ pageBrand }) => pageBrand ? "0px 0 33px" : "50px 0 33px"};
      width: fit-content;
      background-color: ${({ theme }) => theme?.palette?.light_blue} !important;
      padding: 2px 10px 2px 0p; 
      &,
      span {
          color: #EFEFEF;
          font-weight: 400;
          font-size: 22px;
      }
  }
`;

export const sideBarWrapper: any = css`
  width: 100%;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    max-width: 224px;
    padding-left: 20px;
  }
`;

export const imageInText: any = css`
  > div:first-of-type {
    height: auto;
    > img {
        height: auto;
    }
    float: left;
    margin: 0 20px 20px 0;
    max-width: 33%;
  }
`;

export const figcaption: any = css`
  background-color: rgb(88, 88, 90); 
  text-align: center;
  padding: 10px 10px;
`;

export const UniversaElementWrapper: any = styled.div`
  // margin-top: 75px;
  .slick-prev,
  .slick-next {
    z-index: 9;
    background-color: rgba(255,255,255,.5) !important;
    width: 30px !important;
    height: 30px !important;
    :before {
      opacity: 1;
      display: inline-block;
      content: "" !important;
      background-image: url(/images/sprite-sa3ca3ca63e.png);
      background-repeat: no-repeat;
      background-position: 0 -172px;
      height: 18px;
      width: 12px;
      color: transparent !important;
    }

    :hover {
      background-color: rgba(255,255,255,.5);
    }
  }

  .slick-next {
    right: 0 !important;
  }

  .slick-prev {
    left: 0 !important;
    :before {
      transform: rotate(180deg);
    }
  }

  .slick-slide{
    padding-top: 6px;
    padding-right: 6px;
    &:not(.slick-current) {
      opacity: .5;
    }
  }
`;

export const ElementWrapper: any = styled.div`
`;

export const ImgWrapper: any = styled.div`
  margin-bottom: 10px;
`;
