import React, { FC } from "react";
import RichTextGeneral from "../RichText";
import { Wrapper } from "./TextBoxRight.style";

const TextBoxRight: FC<{ text?: string | null | undefined }> = props => (
  <>
    {props?.text && (
      <Wrapper>
        <RichTextGeneral textContent={props.text} />
      </Wrapper>
    )}
  </>
);

export default TextBoxRight;
