import React, {
  FunctionComponent,
  Fragment,
  useContext,
  useState,
} from "react";
import {
  Context,
  getPage,
  ITextElement,
  ISidebarText,
  IAsset,
  Img,
  IPhotoGallery,
  IContext,
} from "src/common";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";
import UniversalComponentsRenderer from "src/common/BaseComponents/UniversalCompoentRenderer/UniversalComponentsRenderer";
import PhotoGallery from "src/common/components/PhotoGalleryVisualization";
import { css } from "styled-components";
import { IGenContinuingPageGallery } from "../../../../../common/types_dealer_gen";

import ImageWithAspectRatio from "../../../BaseComponents/ImageWithAspectRatio";
import Articles from "../../common/Articles";
import Footer from "../../common/Footer";
import FullscreenImage from "../../common/FullscreenImage";
import Header from "../../common/Header";
import ProductItem from "../../common/ProductItem";
import { RichTextGeneral, SRichTextGeneral } from "../../common/RichText";
import SubNavigation from "../../common/SubNavigation";
import TextBoxRight from "../../common/TextBoxRight";
import Hero from "./Hero";
import * as styles from "./MainContent.style";
import {
  ContentContainer,
  ContentWrapper,
  UniversaElementWrapper,
  Title,
  Wrapper,
  WrapperImage,
  WrapperImageInText,
  MainHead,
  TextElementWrapper,
  ImageContent,
  TextContent,
  WrapperImageFull,
  Frame,
} from "./MainContent.style";

const getExternalLink = (p: IContext): string =>
  p.PageMicro?.slug === p?.slug && p.PageMicro.externalLinkAddress;

const MainContent: FunctionComponent<any> = (props) => {
  const p = useContext(Context);
  const page = getPage(p);
  const [isMenuActive, toggleMenuActive] = useState(false);
  const sideBarText = page?.extras?.find(
    (e) => e?.__typename === "SidebarText"
  ) as ISidebarText;

  // @ts-ignore
  const mixedElements = page?.elements?.filter(
    (e) =>
      e?.__typename === "ContinuingPageGallery" ||
      e?.__typename === "PhotoGallery" ||
      e?.__typename === "TextElement" ||
      e?.__typename === "Asset"
  ) as any[];
  const connectedArticles = (page as any)?.connectedArticles;
  const navItem = p?.PrimaryNavigation?.items?.find(
    (i) =>
      !!i?.subitems?.find((s) => s?.slug === p.slug) ||
      i?.link?.[0]?.slug === p.slug
  );
  const TopComponent = props.topComponent;
  const ContinuingPageGallery = mixedElements?.find(
    (item) => item?.__typename === "ContinuingPageGallery"
  );
  // @ts-ignore
  const headingTextElement = page?.elements?.find(
    (item) => item?.__typename === "TextElement"
  );

  const mainHeadLabel =
    navItem?.link[0].slug === p.slug
      ? navItem.label
      : navItem?.subitems.find((subitem) => subitem.slug === p.slug)
          ?.nameInNavigation;

  const externalLinkAddress = getExternalLink(p);
  return (
    <>
      <Wrapper onClickCapture={() => toggleMenuActive(false)}>
        <Header
          onToggleMenuActive={toggleMenuActive}
          isMenuActive={isMenuActive}
        />
        <ContentContainer>
          <ContentWrapper>
            {navItem?.label && (
              <MainHead>
                <img src="/images/h1_pointer_t21.png" />
                <span className="main_head">{mainHeadLabel}</span>
              </MainHead>
            )}
            {externalLinkAddress && <Frame src={externalLinkAddress} />}
            <Hero topComponent={TopComponent} />
            {headingTextElement?.text && (
              <TextElementWrapper>
                {headingTextElement?.text && (
                  <RichTextGeneral
                    fragment
                    textContent={headingTextElement?.text}
                  />
                )}
              </TextElementWrapper>
            )}
            {mixedElements &&
              mixedElements.map((e, i) => {
                const textElement =
                  e?.__typename === "TextElement" && (e as ITextElement);
                const imageElement =
                  TopComponent && e?.__typename === "Asset" && (e as IAsset);
                const imageElementFullWidth =
                  i > 0 && e?.__typename === "Asset" && (e as IAsset);
                const universlElement =
                  (
                    e?.__typename === "ContinuingPageGallery" ||
                    e?.__typename === "HtmlEmbedding"
                  ) &&
                  (e as any);
                const photoGallery =
                  e?.__typename === "PhotoGallery" && (e as IPhotoGallery);

                const isImageTextColumn =
                  (textElement?.icon?.length === 1 &&
                    textElement?.icon[0]?.src) ||
                  (textElement?.icon?.length > 1 && true);
                const isImageTextColumnFull =
                  textElement?.icon?.length > 1 && true;
                const isListImageText = textElement?.icon?.length > 1;
                if (textElement === headingTextElement) {
                  return null;
                }
                return (
                  <Fragment key={`mixedElements${i}`}>
                    {imageElementFullWidth && (
                      <WrapperImage>
                        <ImageWithAspectRatio
                          alt={imageElementFullWidth?.description}
                          src={[imageElementFullWidth]}
                          ratio={47.7}
                        />
                      </WrapperImage>
                    )}
                    {imageElement && (
                      <div
                        css={
                          css`
                            ${SRichTextGeneral}${styles.imageInText}
                          ` as any
                        }
                      >
                        <Img
                          alt={imageElement?.description}
                          src={[imageElement]}
                          quality={1080}
                        />
                      </div>
                    )}
                    {textElement && (
                      <>
                        {ContinuingPageGallery ? (
                          <Title>
                            <h1>{textElement?.headline}</h1>
                          </Title>
                        ) : (
                          <h1>{textElement?.headline}</h1>
                        )}
                        <WrapperImageInText column={isImageTextColumn}>
                          {textElement?.icon?.find((i) => i?.src) && (
                            <ImageContent
                              column={isImageTextColumn}
                              row={isImageTextColumnFull}
                            >
                              {!isListImageText &&
                                textElement?.icon?.map((i, index) => {
                                  if (i.src) {
                                    return (
                                      <WrapperImageFull key={`i${index}`}>
                                        <Img
                                          quality={1020}
                                          src={[i]}
                                          contain
                                          alt={i.subtitle}
                                        >
                                          {i.subtitle && (
                                            <p css={styles.figcaption}>
                                              {i.subtitle}
                                            </p>
                                          )}
                                        </Img>
                                      </WrapperImageFull>
                                    );
                                  }
                                  return null;
                                })}

                              {isListImageText &&
                                textElement?.icon?.map((i, index) => {
                                  if (i.src) {
                                    return (
                                      <WrapperImageFull
                                        key={`i${index}`}
                                        row={isImageTextColumnFull}
                                      >
                                        <FullscreenImage
                                          img={[i]}
                                          subtitle={i.subtitle}
                                        >
                                          {i.subtitle && (
                                            <p css={styles.figcaption}>
                                              {i.subtitle}
                                            </p>
                                          )}
                                        </FullscreenImage>
                                      </WrapperImageFull>
                                    );
                                  }
                                  return null;
                                })}
                            </ImageContent>
                          )}
                          <TextContent full={isImageTextColumnFull}>
                            {textElement?.text && (
                              <RichTextGeneral
                                fragment
                                textContent={textElement?.text}
                              />
                            )}
                          </TextContent>
                        </WrapperImageInText>
                      </>
                    )}
                    {universlElement && (
                      <>
                        {!!(universlElement as any)?.items?.find((item) =>
                          `${item?.__typename}`.includes("Product")
                        ) ? (
                          <UniversaElementWrapper>
                            <UniversalComponentsRenderer
                              items={[universlElement]}
                            >
                              {({ item }) => <ProductItem item={item} />}
                            </UniversalComponentsRenderer>
                            <Spacer bronze={20} silver={30}></Spacer>
                          </UniversaElementWrapper>
                        ) : (
                          <UniversaElementWrapper>
                            <UniversalComponentsRenderer
                              items={[universlElement]}
                            ></UniversalComponentsRenderer>
                            <Spacer bronze={20} silver={30}></Spacer>
                          </UniversaElementWrapper>
                        )}
                      </>
                    )}
                    {photoGallery && (
                      <UniversaElementWrapper>
                        <PhotoGallery item={photoGallery} />
                        <Spacer bronze={20} silver={30}></Spacer>
                      </UniversaElementWrapper>
                    )}
                  </Fragment>
                );
              })}
            <Articles
              connectedArticles={connectedArticles}
              footnote={page?.footnote}
            />
          </ContentWrapper>

          {sideBarText && (
            <div css={styles.sideBarWrapper}>
              <TextBoxRight text={sideBarText?.text} />
            </div>
          )}
          {navItem && (
            <div css={styles.sideNavWrapper}>
              <SubNavigation />
            </div>
          )}
        </ContentContainer>
        <Footer />
      </Wrapper>
      {props.children}
    </>
  );
};

export default MainContent;
