import React, { FC, useContext } from "react";
import { Context, getPage, IAsset } from "src/common";

import ImageWithAspectRatio from "../../../BaseComponents/ImageWithAspectRatio/ImageWithAspectRatio";
import Gallery from "../../common/Gallery";

const Hero: FC<any> = (props) => 
{
  const p = useContext(Context);
  const page = getPage(p);

  // @ts-ignore
  const imgArray = page?.elements?.filter((e) => e?.__typename === "Asset") as IAsset[];
  // @ts-ignore
  const slider = page?.elements?.filter((e) => e?.__typename === "Slider") as any[];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const showSlider = slider && (slider.length > 0 || slider?.[0]?.elements.length > 0);
  let imageOrGallery;
  if(imgArray?.[0] || showSlider) 
  {
    if(imgArray.length > 1 || showSlider) 
    {
      if(slider) 
      {
        imageOrGallery = <Gallery imgArray={slider?.[0]?.elements} />;
      }
      else 
      {
        imageOrGallery = <Gallery imgArray={imgArray} />;
      }
    }
    else 
    {
      imageOrGallery = (
        <ImageWithAspectRatio
          alt={imgArray?.[0]?.title}
          src={imgArray}
          height={342}
          ratio={50}
          quality={1080}
        />
      );
    }
  }

  const TopComponent = props.topComponent;

  return (
    <>
      {TopComponent ? (
        <div style={{ marginBottom: "20px" }}>
          <TopComponent />
        </div>
      ) : (
        <>
          {imageOrGallery && (
            <div style={{ marginBottom: "20px", marginTop: props.pageBrand && "64px" }}>
              {imageOrGallery}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Hero;
