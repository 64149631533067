import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const ContentContainer: any = styled.div<{ micro: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.maxWidth}px;
  width: 100%;
  margin: ${({ micro }) => (micro ? "0 auto 50px" : "50px auto 50px auto")};
  padding: 0 10px;

  @media (${BREAKPOINTS_DM.gold_1024}) {
    padding: ${({ micro }) => (micro ? "0" : "0 15px")};
    flex-direction: row;
  }
  h1 {
    &,
    span {
      color: ${({ theme }) => theme?.textStyles?.h1Red};
    }
  }
  h1,
  h2,
  h3 {
    &,
    span {
     color: ${({ theme }) => theme.palette.red};
      font-weight: 600;
    }
  }
  h1 {
    &,
    span {
      font-weight: 700;
      font-size: 1.6rem;
    }
  }
`;

export const ContentWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SideBarWrapper: any = styled.div`
  width: 100%;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    max-width: 224px;
    padding-left: 20px;
  }
`;

export const Title: any = styled.h1`
  ${({ theme }) => theme?.textStyles?.h1Red}
`;

export const imageInText: any = css`
  > div:first-of-type {
    height: auto;
    > img {
      height: auto;
    }
    float: left;
    margin: 0 20px 20px 0;
    max-width: 33%;
  }
`;

export const figcaption: any = css`
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 10px 10px;
`;
