import React, { FC } from "react";
import { getIfNewsShouldBeDisplayByDate, Img, IProduct } from "src/common";

import { ItemDefaultWrapper, ImageWrapper, Description, Price } from "./ItemDefault.style";
import { RichTextGeneral } from "../RichText";

const ItemDefault: FC<{ item: any }> = ({ item }) => 
{
  if(!item) 
  {
    return null;
  }

  const product = (item?.link?.[0]) as IProduct;
  const isProduct = product?.__typename.includes("Product");
  const link = item?.link?.[0] as any;
  const price = link?.ourPrice;
  const slug = link?.slug;
  const externalLink = link?.externalLinkAddress;
  const isLinkHttps = externalLink && /https:/.test(externalLink);
  if(isProduct && product?.acceptAGB && !getIfNewsShouldBeDisplayByDate({ startDate: product?.startDate, endDate: product?.endDate })) 
  {
    return null;
  }
  // console.log(item)
  return (
    <ItemDefaultWrapper isProduct={isProduct} isExternalLink={externalLink}>
      {/* <a href={`/${isProduct ? "produkt/" : ""}${slug}`}> */}
      <a href={externalLink ? `${isLinkHttps ? externalLink : `//${externalLink}`}` : `/${isProduct ? "produkt/" : ""}${slug}`} target={externalLink && "_blank"}>
        <ImageWrapper isProduct={isProduct}>
          <Img src={item.thumbnail} />
        </ImageWrapper>
        <h2 className="title">{item.title}</h2>
        <RichTextGeneral textContent={item.text}/>
        {externalLink && <p>{item?.text?.content[0]?.children?.[0].text}</p>}
        {isProduct && (
          <Description>
            <div>{price}</div>
            <Price>
              {price && <span className="price">Statt 4.500 Euro</span>}
            </Price>
          </Description>
        )}
      </a>
    </ItemDefaultWrapper>
  );
};

export default ItemDefault;
