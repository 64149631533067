import React, { FC, useContext } from "react";
import { Context, getPage, IAsset } from "src/common";

import ImageWithAspectRatio from "../../../BaseComponents/ImageWithAspectRatio/ImageWithAspectRatio";
import Gallery from "../../common/Gallery";
import styled, { css } from "styled-components";

const Wrapper = styled.div<{
  isImage: boolean;
  isPageBrand: boolean;
  isFounderPage: boolean;
}>`
  ${({ isImage }) =>
    isImage &&
    css`
      margin-bottom: 30px;
      height: 210px;
    `};
  ${({ isPageBrand }) =>
    isPageBrand &&
    css`
      margin-top: 64px;
    `};
  ${({ isFounderPage }) =>
    isFounderPage &&
    css`
      height: 730px;
      > div {
        height: 100%;
      }
    `};
`;

const Hero: FC<any> = (props) => {
  const p = useContext(Context);
  const page = getPage(p);

  // @ts-ignore
  const imgArray = page?.elements?.filter(
    (e) => e?.__typename === "Asset"
  ) as IAsset[];
  // @ts-ignore
  const slider = page?.elements?.filter(
    (e) => e?.__typename === "Slider"
  ) as any[];

  const isFounderPage = p?.slug === "gruender";

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const showSlider =
    slider && (slider.length > 0 || slider?.[0]?.elements.length > 0);
  let imageOrGallery;
  if (imgArray?.[0] || showSlider) {
    if (imgArray.length > 1 || showSlider) {
      if (slider) {
        imageOrGallery = <Gallery imgArray={slider?.[0]?.elements} />;
      } else {
        imageOrGallery = <Gallery imgArray={imgArray} />;
      }
    } else {
      imageOrGallery = (
        <>
          {imgArray?.length > 0 && (
            <ImageWithAspectRatio
              alt={imgArray?.[0]?.description}
              src={imgArray}
              height={isFounderPage ? 730 : 210}
              ratio={20}
              quality={1080}
            />
          )}
        </>
      );
    }
  }

  const TopComponent = props.topComponent;
  return (
    <>
      {TopComponent ? (
        <div style={{ marginBottom: "20px"}}>
          <TopComponent />
        </div>
      ) : (
        <>
          {imageOrGallery && (
            <Wrapper
              isImage={imgArray?.length > 0}
              isPageBrand={props.PageBrand}
              isFounderPage={isFounderPage}
            >
              {imageOrGallery}
            </Wrapper>
          )}
        </>
      )}
    </>
  );
};

export default Hero;
