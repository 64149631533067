import React, { FunctionComponent } from "react";
import { IAsset } from "src/common";
import { css } from "styled-components";

import { RichTextGeneral } from "../RichText";
import { Wrapper, Footnote, ImgContent, ImageText } from "./Articles.style";
const Articles: FunctionComponent<any> = props => 
{
  const filteredArticles = props?.connectedArticles?.filter(Article => Article?.__typename === "Article");

  if(!filteredArticles || filteredArticles.length === 0) 
  {
    return null;
  }

  return (
    <Wrapper>
      {filteredArticles.map((item, index) => 
      {
        const imgArray = item?.extras?.filter(e => e?.__typename === "Asset") as IAsset[];
        return (
          <div key={index} css={index === 0 ? css`margin-bottom: 42px;` : css` margin: 42px 0;`}>
            <h3>{item?.headline}</h3>
            <ImageText>
              {imgArray?.length > 0 &&
              <ImgContent>
                {imgArray?.map((img, i) => (
                  <img
                    key={`art-img-${i}`}
                    src={img?.src}
                    alt={img?.title || "NewsImage"}
                  />
                ))}
              </ImgContent>
              }
              <RichTextGeneral textContent={item?.mainText} />
            </ImageText>
           
          </div>
        );
      })}

      {props.footnote && (
        <Footnote>
          <RichTextGeneral textContent={props.footnote} />
        </Footnote>
      )}
    </Wrapper>
  );
};

export default Articles;
