import React, { useContext } from "react";
import { Context, getPage, ITextElement } from "src/common";
import { MicroWrapper } from "./Micro.style";

const Micro: React.FC<any> = () => {
  const props = useContext(Context);

  // @ts-ignore
  const headText = props?.PageMicro?.elements?.find((e) => e?.__typename === "TextElement") as ITextElement;
  return (
    <MicroWrapper>
      <h1>{headText?.headline}</h1>
      <iframe src={props?.PageMicro?.externalLinkAddress} />
    </MicroWrapper>
  );
};

export default Micro;
