import styled, { css } from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Wrapper: any = styled.ul`
  display: none;
  position: relative;
  right: 0px;

  li {
    margin: 0 0 5px;
    background-color: ${props => props.theme.palette.light_blue};
    a {
      white-space: nowrap;
      display: block;
      padding: 2px 10px 2px 0;
      background-color: ${props => props.theme.palette.light_blue};
      line-height: 21px;
      font-weight: 300;
      width: fit-content;
      font-size: 14px;
    }
    a:hover {
      background-color: #fff;
      color: #008288;
    }
  }

  @media (${BREAKPOINTS_DM.gold_1024}){
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .active {
    background-color: #fff;
    color: #008288 !important;
  }
`;
