import styled from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { Container } from "../Grid";

export const SubNavigationWrapper: any = styled.div`
  width: 100%;
  position: relative;
  background-color: #f5f5f5;
  padding: 15px 0;

  ul {
    margin: auto;
    padding: 0 10px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    li {
      position: relative;
      padding: 0 10px;

      &:first-child {
        padding-left: 0;
      }

      &:not(:first-child):after {
        content: '';
        display: block;
        position: absolute;
        height: 14px;
        left: 0px;
        top: 6px;
        border-left: 1px solid #999;
      }

      a {
        display: inline-block;
        background-color: transparent;

        font-size: 13px;
        font-weight: 600;
        line-height: 25px;
        white-space: nowrap;
        color: ${props => props.theme.palette.textColor};

        &:hover,
        &.active {
          color: #adadad;
        }
      }
    }
  }
`;
