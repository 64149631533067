import React, { FunctionComponent, useState } from "react";
import { IAsset } from "src/common";

import { RichTextGeneral } from "../RichText";
import { Wrapper, Footnote, ImgContent, WrapperTitle, ContentWrapper } from "./Articles.style";

const Item: FunctionComponent<{item: Record<string, any>; first: boolean}> = ({ item, first }) => 
{
  const [isShowContent, toogleShowContent] = useState(false);
  const imgArray = item?.extras?.filter(e => e?.__typename === "Asset") as IAsset[];
  return (
    <>
      <WrapperTitle first={first}>
        <div className="title" onClick={() => toogleShowContent(true)}>
          {!isShowContent && <div className="icon-down"><i className="fa fa-caret-down"/></div>}
          <h3>{item?.headline}</h3></div>
      </WrapperTitle>
      <ContentWrapper isShowContent={isShowContent}>
        <ImgContent>
          {imgArray?.map((img, i) => (
            <img
              key={`art-img-${i}`}
              src={img?.src}
              alt={img?.title || "NewsImage"}
            />
          ))}
        </ImgContent>
        <RichTextGeneral textContent={item?.mainText} />
        <div className="title-bottom" onClick={() => toogleShowContent(false)}><h3 className="bottom">SCHLIESSEN</h3><div className="icon-up"><i className="fa fa-caret-up"/></div></div>
      </ContentWrapper>
    </>
  );
};

const Articles: FunctionComponent<any> = props => 
{
  const filteredArticles = props?.connectedArticles?.filter(Article => Article?.__typename === "Article");

  if(!filteredArticles || filteredArticles.length === 0) 
  {
    return null;
  }

  return (
    <Wrapper>
      {filteredArticles.map((item, index) => 
        (
          <Item item={item} first={index === 0} key={index} />
        ))}

      {props.footnote && (
        <Footnote>
          <RichTextGeneral textContent={props.footnote} />
        </Footnote>
      )}
    </Wrapper>
  );
};

export default Articles;
