import React, { FC } from "react";
import styled from "styled-components";

export const ItemDefaultWrapper: FC<{ isProduct?: boolean }> = styled.div<{ isProduct?: boolean }>`
  margin-bottom: 21px;
  h2 {
    margin-bottom: 34px !important;
  }
`;

export const ImageWrapper: FC<{ isProduct?: boolean }> = styled.div<{ isProduct?: boolean }>`
  ${({ isProduct }) => isProduct && `
    height: 100%;
    background: white;

    img {
      object-fit: contain !important;
    }
  `}
`;

export const Description: FC<{ isProduct?: boolean }> = styled.div<{ isProduct?: boolean }>`
  background-color: #000;
  padding: 10.5px;
  color: ${({ theme }) => theme.palette.primary};
  font-size: 15px;
  line-height: 21px;

  svg {
    vertical-align: middle;
    height: 15px;
    fill: ${({ theme }) => theme.palette.white};
    width: 15px;
  }

  &, div {
    font-weight: 300;
  }
`;

export const Price: FC = styled.div`
  display: flex;
  justify-content: space-between;
  > span {
    font-weight: 300 !important;
  }
`;
