import React, { FC } from "react";
import styled from "styled-components";

export const ItemDefaultWrapper: FC<{ isProduct?: boolean; isExternalLink?: boolean }> = styled.div<{ isProduct?: boolean; isExternalLink?: boolean }>`
  width: 360px;
  h2.title {
    color: #555555;
    font-size: ${({ isExternalLink }) => isExternalLink ? "30px" : "20px"};
    font-weight: 300;
    line-height: ${({ isExternalLink }) => isExternalLink ? "42px" : "28px"};
    margin: ${({ isExternalLink }) => isExternalLink ? "5px 0 10px" : "20px 0 30px"};
    }
  }
  div > p {
    color: #555555;
  }
  
  ${({ isExternalLink }) => isExternalLink ? `
    p {
      color: #e67c19;
      font-size: 15px;
      font-weight: 300;
      line-height: 24px;
      margin: 0 0 10px;
    }
    a {
      &:hover {
        p {
          color: #fa902d;
        }
        h2.title {
          color: #e67c19;
        }
      }
    }`
    :
    `
    h2::after {
      background-color: rgb(230, 124, 25);
      content: "";
      display: block;
      height: 2px;
      left: 0px;
      margin-top: 10px;
      width: 30px;
    }
  `}
  
`;

export const ImageWrapper: FC<{ isProduct?: boolean }> = styled.div<{ isProduct?: boolean }>`
  ${({ isProduct }) => isProduct && `
    height: 174.19px;
    background: white;
  `}
`;

export const Description: FC<{ isProduct?: boolean }> = styled.div<{ isProduct?: boolean }>`
  color: #000;
  div {
    font-size: 24px;
    line-height: 24px;
    font-weight: 300;
  }
  svg {
    vertical-align: middle;
    height: 15px;
    fill: #fff;
    width: 15px;
  }
`;

export const Price: FC = styled.div`
  display: flex;
  justify-content: space-between;
  color: #CCCCCC;
  span.price {
    color: #CCCCCC;
    font-weight: 300;
  }
  span.link {
    color: #CCCCCC;
    font-size: 13px;
    font-weight: 300;
  }
`;
