import React, { FunctionComponent, useContext } from "react";
import { Context } from "src/common";

import {
  Wrapper,
  CardWrapper,
  LinksWrapper,
  Link,
} from "./InformationAndService.style";

const Card: FunctionComponent<{ linksList: Record<string, any>[]; title: string }> = ({ linksList, title }) => 
  (
    <CardWrapper>
      <div className="spacer"></div>
      <h1 className="title"><div className="icon"><i className="fa fa-caret-down"/></div>{title}</h1>
      <LinksWrapper>
        {
          linksList?.map((link, index) => (
            <Link href={`/${link?.slug}`}key={`link-key-${index}`}>
              {link?.nameInNavigation}
            </Link>
          ))
        }
      </LinksWrapper>
      
    </CardWrapper>
  );

const InformationAndService: FunctionComponent = () => 
{ 
  const props = useContext(Context);
  const information = props?.Settings?.t12_positionInformation;
  const service = props?.Settings?.t12_positionService;
  return (
    <Wrapper>
      <Card linksList={information} title="Informationen"/>
      <Card linksList={service} title="Service"/>
    </Wrapper>
  );
};

export default InformationAndService;
