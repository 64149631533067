import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const ContentContainer: any = styled.div<{ withoutHero: boolean }>`
    display: flex;
    flex-direction: column;
    max-width: ${({ theme }) => theme.maxWidth}px;
    width: 100%;
    margin: 0px auto 48px;
    padding: 20px;
    color: #85b2c3;
    @media (${BREAKPOINTS_DM.gold_1024}) {
        flex-direction: row;
        padding: ${({ withoutHero }) => (withoutHero ? "300px 30px" : "20px")};
    }
    .c-frame {
        iframe {
            width: 280%;
            height: 364px;
        }
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        &,
        span {
            color: #85b2c3 !important;
        }
    }

    h2,
    h3 {
        &,
        span {
            font-weight: 300;
        }
    }

    h1 {
        padding: 2px 10px 2px 0p;
        &,
        span {
            color: #85b2c3;
            font-weight: 700;
        }
    }
    a > span {
        color: #85b2c3;
    }
    p {
        color: #85b2c3;
        margin-bottom: 21px;
        display: flex;
        flex-direction: column;
        span {
            color: #85b2c3;
            font-size: 15px;
            line-height: 21px;
            font-weight: 300;
            letter-spacing: 0.4px;
        }
    }
    strong {
        color: #85b2c3;
        font-weight: 300;
    }
    span {
        color: #85b2c3;
        font-size: 15px;
        line-height: 21px;
        font-weight: 300;
        letter-spacing: 0.4px;
    }
`;

export const HeroWrapper: any = styled.div<{
    isFirstText: boolean;
    headerParagraphHeight: number;
}>`
    ${({ isFirstText, headerParagraphHeight }) =>
        isFirstText
            ? css`
                  /* Image height + FirstElement P height */
                  min-height: calc(210px + ${headerParagraphHeight}px);
              `
            : css`
                  min-height: 210px;
              `}

    position: relative;
`;

export const ImageGaleryWrapper: any = styled.div<{
    marginBottom: boolean;
    pageBrand: boolean;
    maxWidth: boolean;
}>`
    height: 260px;
    > div {
        height: 100%;
    }
    background-color: #92c2d6;

    @media (${BREAKPOINTS_DM.silver_768}) {
        height: 210px;
    }

    ${({ marginBottom }) =>
        marginBottom &&
        css`
            margin-bottom: 30px;
        `}

    max-width: ${({ maxWidth }) => maxWidth && "834.75px"};

    ${({ pageBrand }) =>
        pageBrand &&
        css`
            div > img {
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
        `}

    @media (${BREAKPOINTS_DM.silver_768}) {
        margin-top: ${({ pageBrand }) => (pageBrand ? "72px" : "90px")};
    }
`;

export const ContentWrapper: any = styled.div<{
    pageBrand: boolean;
    isSideRight: boolean;
}>`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${({ pageBrand }) => pageBrand && "0 18px 0 0"};

    @media (${BREAKPOINTS_DM.gold_1024}) {
        padding-left: ${({ isSideRight }) => (isSideRight ? "0" : "20px")};
    }

    ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 0px;
        margin-block-end: 1.4em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 15px;
        li {
            list-style-type: disc;
            span {
                color: #85b2c3;
                font-size: 15px;
                line-height: 21px;
                font-weight: 300;
                letter-spacing: 0.4px;
            }
        }
    }
`;

export const SideLeftWrapper: any = styled.div<{ isSideRight: boolean }>`
    width: 100%;
    @media (${BREAKPOINTS_DM.gold_1024}) {
        max-width: 255.25px;
        ${({ isSideRight }) => isSideRight && "margin-top: -98px;"}
    }
`;

export const ImageInText: any = styled.div<{
    column: boolean;
}>`
    padding: 20px 0;

    @media (${BREAKPOINTS_DM.silver_768}) {
        padding: 0;
    }

    p {
        color: #85b2c3;
        display: flex;
        flex-direction: column;

        p {
            margin-bottom: 0;
        }
        span {
            color: #85b2c3;
            font-size: 15px;
            line-height: 21px;
            font-weight: 300;
            letter-spacing: 0.4px;
            // strong {
            //   line-height: 10px;
            //   vertical-align: bottom;
            // }
        }
        a {
            span {
                color: #85b2c3;
                font-size: 30px;
                line-height: 42px;
                font-weight: 300;
            }
        }
    }
    ul {
        list-style-type: disc;
        margin-left: 21px;
        margin-bottom: 21px;
        li {
            list-style-type: disc;
            span {
                font-size: 15px;
                font-weight: 300;
                line-height: 21px;
            }
        }
    }

    ${({ column }) =>
        column
            ? css`
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
              `
            : css`
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  @media (${BREAKPOINTS_DM.silver_768}) {
                      flex-direction: row;
                  }
              `}
`;

export const ImageWRapper: any = styled.div`
    width: 100%;
    order: -1;
    padding-top: 20px;
    img {
        > img {
            height: auto;
        }
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
        max-width: 300px;
    }
`;

export const ImageContent: any = styled.div`
    height: auto;
`;

export const figcaption: any = css`
    padding: 10.5px;
    font-size: 13px;
    font-weight: 300;
`;

export const UniversaElementWrapper: any = styled.div`
    margin-top: 0px;
    width: 100%;
    .slick-prev,
    .slick-next {
        z-index: 9;
        background-color: rgba(255, 255, 255, 0.5) !important;
        width: 30px !important;
        height: 30px !important;
        :before {
            opacity: 1;
            display: inline-block;
            content: "" !important;
            background-image: url(/images/sprite-sa3ca3ca63e.png);
            background-repeat: no-repeat;
            background-position: 0 -172px;
            height: 18px;
            width: 12px;
            color: transparent !important;
        }

        :hover {
            background-color: rgba(255, 255, 255, 0.5);
        }
    }

    .slick-next {
        right: 0 !important;
    }

    .slick-prev {
        left: 0 !important;
        :before {
            transform: rotate(180deg);
        }
    }

    .slick-slide {
        img {
            max-height: 443.98px;
        }
        padding-top: 6px;
        &:not(.slick-current) {
            opacity: 0.5;
        }
    }

    [data-gallery-slider-tracker] {
        img {
            padding-right: 5px;
            max-height: 73px;
        }
    }

    [data-caption] {
        color: transparent;
    }
`;

export const ElementWrapper: any = styled.section<{
    isFounderPage: boolean;
    isLastElement: boolean;
    hasTextElement: boolean;
}>`
    ${({ hasTextElement }) =>
        hasTextElement
            ? css`
                  margin-bottom: 40px;
              `
            : ""};
    &:not(:last-child) {
        &:first-child {
            padding-top: 20px;
        }
    }

    ${({ isFounderPage }) =>
        isFounderPage &&
        css`
            &:nth-child(1) {
                > h1 {
                    display: none;
                }

                > div {
                    justify-content: space-between;

                    @media (${BREAKPOINTS_DM.silver_768}) {
                        justify-content: flex-end;

                        > div:nth-child(1) {
                            position: absolute;
                            top: 65px;
                            left: -75px;
                        }
                    }

                    div:nth-child(2) {
                        width: auto;
                    }

                    p {
                        color: #85b2c3;
                    }
                }
            }

            &:nth-child(2) {
                padding: 30px;
                border: 13px solid #92c2d6;
                margin-left: auto;

                @media (${BREAKPOINTS_DM.silver_768}) {
                    width: 75%;
                }

                > h1 {
                    font-size: 27px;
                    line-height: 32px;
                    letter-spacing: 2px;
                    word-wrap: break-word;
                }

                > div :first-of-type {
                    > div {
                        border: none;
                        padding: 0;
                    }
                }
            }
        `}

    > p {
        font-size: 15px;
    }

    @media (${BREAKPOINTS_DM.silver_768}) {
        padding: 0 20px;
    }

    ${({ isLastElement }) =>
        isLastElement &&
        css`
            &:last-child {
                border: 13px solid #92c2d6;
                padding: 30px;
                margin-bottom: 40px;

                > h1 {
                    margin: 0;
                }

                > div {
                    > div {
                        &:nth-child(1) {
                            order: -1;
                            padding: 0;
                            width: 100%;

                            @media (${BREAKPOINTS_DM.silver_768}) {
                                width: 35%;
                            }
                        }

                        &:nth-child(2) {
                            display: flex;
                            flex-direction: column;
                            gap: 20px;
                            width: 100%;
                            padding: 0;
                            border: none;
                            > h2 {
                                color: #85b2c3;
                                font-weight: 700;
                                font-size: 27px;
                                line-height: 32px;
                                letter-spacing: 2px;
                            }

                            > p {
                                margin: 0;

                                :first-of-type {
                                    strong {
                                        color: #565656;
                                    }
                                }

                                :not(:first-of-type) {
                                    margin: 0;
                                    font-size: 14px;

                                    strong {
                                        color: #85b2c3;
                                    }

                                    br {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
`;

export const BorderBottom: any = styled.div`
    width: 100%;
    margin: 20px auto 0;
    max-width: 980px;
    border-bottom: 2px dotted #fff;
`;
export const Title: any = styled.h1<{ isFirst: boolean }>`
    color: #ffffff;
    font-weight: 100;
    letter-spacing: 0.4px;
    ${({ isFirst }) =>
        isFirst
            ? css`
                  font-weight: 600;
                  margin-bottom: 40px;
                  font-size: 20px;
                  line-height: 22px;
                  text-transform: uppercase;
                  letter-spacing: 0.02em;
                  text-align: left;

                  @media (${BREAKPOINTS_DM.silver_768}) {
                      font-size: 27px;
                      line-height: 32px;
                  }
              `
            : css`
                  margin: 0 0 21px;
                  font-size: 34px;
                  line-height: 38px;
                  font-weight: 700;
              `}
`;

export const TextWrapper: any = styled.div<{
    withImg: boolean;
}>`
    @media (${BREAKPOINTS_DM.silver_768}) {
        width: ${({ withImg }) => (withImg ? "65%" : "100%")};
    }

    padding: 20px;
    border: 13px solid #92c2d6;

    h1 {
        margin: 0 0 20px 0;
        &,
        strong,
        span {
            color: #85b2c3;
            font-weight: 700;
            font-size: 34px;
        }
    }

    strong {
        color: #575656;
        font-weight: bold;
    }

    ul,
    li,
    ul li span {
        color: #575656;
    }

    p {
        margin-bottom: 20px;
        color: #575656;
        font-size: 15px;
        line-height: 20px;

        &:first-of-type {
            strong {
                color: #85b2c3;
                font-weight: 700;
            }
        }

        br {
            display: none;
        }

        a {
            span {
                &:hover {
                    color: #fff;
                }
            }
        }
    }
`;

export const MixedWrapper: any = styled.div<{ isFounderPage: boolean }>`
    @media (${BREAKPOINTS_DM.silver_768}) {
        ${({ isFounderPage }) =>
            isFounderPage &&
            css`
                position: relative;
            `}
    }
`;

export const LinkWrapper: any = styled.a`
    &:hover {
        > div > div > p > a > span {
            color: #76b729;
        }
    }
`;

export const FirstTextElementWrapper: any = styled.div<{
    news: boolean;
    withoutHero: boolean;
}>`
    width: 100%;
    max-width: 1100px;
    padding: 10px 5px;
    position: absolute;
    top: 150px;

    @media (${BREAKPOINTS_DM.silver_768}) {
        top: 105px;
        left: 50%;
        width: 50%;
    }
`;
// ${ ({ news }) => news && "padding: 300px 30px 21px" };
// ${ ({ withoutHero }) => withoutHero && "padding: 300px 30px 21px" };

export const TextBoxRightWrapper: any = styled.div`
    @media (${BREAKPOINTS_DM.silver_768}) {
        width: 198.94px;
        margin: -80px 0 0 auto;
    }
`;
