import React, { FC } from "react";
import styled from "styled-components";

export const ItemDefaultWrapper: FC<{ isProduct?: boolean }> = styled.div<{ isProduct?: boolean }>`
  h2 {
    font-size: 21px;
    line-height: 1;
    margin-bottom: 10.5px;
  }
  .link-title {
    color: #fff!important;
    font-family: sofia_pro_lightregular, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
  }
  .external-link {
    p {
      span {
        color: rgb(239, 239, 239);
        font-family: sofia_pro_lightregular, Helvetica, Arial, sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 19.9999px;
      }
    }
    &:hover {
      p > span {
        color: #80C7DC;
      }
    }
  }
`;

export const ImageWrapper: FC<{ isProduct?: boolean }> = styled.div<{ isProduct?: boolean }>`
  ${({ isProduct }) => isProduct && `
    height: 100%;
    background: white;

    img {
      object-fit: contain !important;
    }
  `}
`;

export const Description: FC<{ isProduct?: boolean }> = styled.div<{ isProduct?: boolean }>`
  background: ${({ theme }) => theme?.palette?.productBg};
  padding: 10.5px;
  color: #F8F8F8;
  font-size: 15px;
  line-height: 21px;

  svg {
    vertical-align: middle;
    height: 15px;
    fill: #F8F8F8;
    width: 15px;
  }
`;

export const Price: FC = styled.div`
  display: flex;
  justify-content: space-between;
  > span {
    font-weight: 700;
  }
`;

export const RichTextGeneralWrapper: any = styled.div`
 
`;
