import React, { FC, useContext, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Context, IAsset, getIfNewsShouldBeDisplayByDate, INews } from "src/common";

import NewsArticleItem from "./NewsArticleItem";
import { Wrapper, WrapperNews } from "./NewsContent.style";
 
const NewsContent: FC<any> = () => 
{
  const p = useContext(Context);
  const [currentPage, setCurrentPage] = useState<number>(1);

  if(!p.PageNews?.NewsArticles) 
  {
    return null;
  }

  const news = p.PageNews;
  const newsArticles = news?.NewsArticles?.filter((article) =>
    getIfNewsShouldBeDisplayByDate({ startDate: article?.startDate, endDate: article?.endDate })
  ).filter((article) => article !== null) || [];
  
  const newsCustomArticles = news?.NewsArticlesCustom?.filter((article) =>
    getIfNewsShouldBeDisplayByDate({ startDate: article?.startDate, endDate: article?.endDate })
  ).filter((article) => article !== null) || [];

  const articles = [...newsCustomArticles, ...newsArticles];

  const itemsPerPage = 10;

  const articlesToRender: INews[] = articles.slice((currentPage - 1) * itemsPerPage, itemsPerPage);
  return (
    <Wrapper>
      <WrapperNews>
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry>
            {articlesToRender && articlesToRender.map((article, index) => article && (
              <NewsArticleItem
                key={`newsArticleItem${index}`}
                index={index}
                headline={article?.headline}
                text={article?.text}
                images={
                  (article?.extras?.filter(e => e?.__typename === "Asset") as
            | IAsset[]
            | undefined) ?? []
                }
              />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </WrapperNews>
    </Wrapper>
  );
};

export default NewsContent;
