import styled from "styled-components";

export const PageHeadingWrapper: any = styled.div<{ src: string, pageContact: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;

  h1 {
    max-width: 690px;
    vertical-align: middle;
    margin: 0;
    color: ${({ pageContact }) => pageContact ? "#333" : "#fff"};
    height: 180px;
    line-height: 1.4;
    font-size: 33px;
    font-weight: 500;
    text-align: center;
    text-transform: none;
    text-shadow: ${({ pageContact }) => !pageContact && "#444 0 1px 1px"};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  :after {
    display: block;
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
    top:0;
    left:0;
    background-color: #f5f5f5;
    background-image: url("${({ src }) => src }");
    background-attachment: fixed;
    ${({ src }) => src && "filter: brightness(70%) grayscale(0.2);"}
  }
`;
