import { FC } from "react";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const ItemDefaultWrapper: FC<{ isProduct?: boolean }> = styled.div<{ isProduct?: boolean }>`
  span.price {
    color: #E4007E;
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
  }
  
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 200%
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    display: flex;
  }
`;

export const ImageWrapper: FC<{ isProduct?: boolean }> = styled.div<{ isProduct?: boolean }>`
  @media (${BREAKPOINTS_DM.gold_1024}) {
    width: 50%;
    height: 338px;
  }
  ${({ isProduct }) => isProduct && `
    
  `}
`;

export const Description: FC<{ isProduct?: boolean }> = styled.div<{ isProduct?: boolean }>`
  padding: 10.5px;
  color: #5E5E5D;
  h2.title {
    color: #F19024; 
    font-size: 26px;
    line-height: 35.75px;
    margin: 32px 0 10px;
    font-weight: 400;
  }
  svg {
    vertical-align: middle;
    height: 15px;
    fill: #F8F8F8;
    width: 15px;
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    padding: 0 0 0 20px;
  }
`;

export const Price: FC = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const NormalPrice: any = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  span {
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
  }
`;

export const Button: any =styled.div`
  width: fit-content;
  
  background-color: #E4007E;
  padding: 8px;
  
  span {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: .85px;
    line-height: 20px;
    text-transform: uppercase;
  }
`;
