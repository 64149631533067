import styled from "styled-components";

export const Wrapper: any = styled.div`
  width: 100%;
  margin: auto;
  padding: 20px 15px 0;
  max-width: 1170px;
`;

export const CourseItemWrapper: any = styled.div`
  h3 {
    font-size: 20px;
  }

  > div {
    margin-top: 20px;
    display: flex;
  }
  margin-bottom: 40px;
`;

export const CourseContentWrapper: any = styled.div`
  width: 75%;

  [data-image] {
    width: calc(33.3% - 16px);
    margin: 0 21px 10px 16px;
    height: 130px;
    float: left;
    object-fit: cover;
  }

  [data-content] {
    p {
      &, span {
        font-family: helvetica,sans-serif;
        font-size: 15px;
      }
      width: unset;
    }

    a {
      color: #80C7DC;
    }
  }
`;

export const CourseDateWrapper: any = styled.div`
  width: 25%;
  padding-left: 16px;
  h3 {
    margin-bottom: 4px;

    &, span {
      font-size: 20px;
    }
  }

  p {
    flex-direction: row!important;
    flex-wrap: wrap;
    span {
      font-size: 14px!important;
      font-weight: 100!important;
      padding-top: 1px;
    }

    :not(.c-empty) {
      font-size: 15px;
      margin-bottom: 0;
      line-height: 21px;
    }
  }
  a {
    display: block;
    color: white;
    text-align: center;
    background-color: #76b729;
    border: 1px solid #76b729;
    line-height: 3;
    padding: 0 10px;
    margin-top: 20px;

    :hover {
      cursor: pointer;
      background-color: #706f6f;
      border: 1px solid #706f6f;
    }
  }
  
`;
