import React, { FunctionComponent } from "react";
import { IAsset } from "src/common";

import { RichTextGeneral } from "../RichText";
import { Wrapper, Footnote, ContentWrapper, ItemWrapper, ImgWrapper } from "./Articles.style";
const Articles: FunctionComponent<any> = props => 
{
  const filteredArticles = props?.connectedArticles?.filter(Article => Article?.__typename === "Article");

  if(!filteredArticles || filteredArticles.length === 0) 
  {
    return null;
  }

  return (
    <Wrapper>
      <ContentWrapper>
        {filteredArticles.map((item, index) => 
        {
          const imgArray = item?.extras?.filter(e => e?.__typename === "Asset") as IAsset[];
          if(index !== 0) 
          {
            return (
              <ItemWrapper>
                <h3>{item?.headline}</h3>
                <RichTextGeneral textContent={item?.mainText} />
                {imgArray?.map((img, i) => (
                  <ImgWrapper key={`art-img-${i}`}>
                    <img
                      src={img?.src}
                      alt={img?.title || "NewsImage"}
                      width={"300px%"}
                      height={"200px"}
                    />
                  </ImgWrapper>
                ))}
              </ItemWrapper>
            );
          }
        })}
      </ContentWrapper>

      {props.footnote && (
        <Footnote>
          <RichTextGeneral textContent={props.footnote} />
        </Footnote>
      )}
    </Wrapper>
  );
};

export default Articles;
