import styled, { css } from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const FounderFormWrapper = styled.div`
  padding-top: 20px;

  > h2,
  > h3 {
    font-family: "FFSuperGroteskWebMedium", sans-serif;
    font-weight: 600;
    letter-spacing: 2px;
    color: #575656 !important;
  }

  > h2 {
    font-size: 29px;
    line-height: 32px;
  }

  > h3 {
    font-size: 23px;
    line-height: 25px;
    margin-top: 5px;
  }
`;

export const SFounderForm = styled.form`
  margin: 0 auto;
  padding: 5px;
  width: 100%;
`;

export const FounderFormContainer = styled.div`
  margin: 0 60px;
  margin-left: -15px;
  margin-right: -15px;

  &::after {
    content: "";
    display: table;
    clear: both;
  }
`;

export const FounderFormSuccessMessage = styled.p`
  font-size: 15px;
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
`;

export const FounderFormErrorMessage = styled.p`
  font-size: 15px;
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
`;

export const FounderFormItem = styled.div`
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
  width: 100%;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    width: 33.33333333%;
  }
`;

export const FounderFormLabel = styled.label`
  display: block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #575656;
  a {
    color: #337ab7;
  }
`;

export const FounderFormInput = styled.input`
  width: 100%;
  height: 40px;
  font-size: 14px;
  background-color: white;
  padding-left: 5px;
  color: #575656;
  border: 2px solid #9dc1d4;
  padding: 1rem;
  &:focus {
    border: 2px solid #212afbff;
  }
`;

export const FounderFormTextAreaWrapper = styled.div`
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
  width: 100%;
`;

export const FounderFormTextArea = styled.textarea`
  width: 100%;
  height: 100px;
  font-size: 14px;
  border: 2px solid #9dc1d4;
  background-color: white;
  color: #575656;
  padding: 1rem;
  &:focus {
    border: 2px solid #212afbff;
  }
`;

export const FounderFormCheckboxWrapper = styled.div`
    display: flex;
    align-items: self-start;
    justify-content: space-between;
    gap: 10px;
    flex-direction: column;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;

    @media (${BREAKPOINTS_DM.silver_768}) {
        flex-direction: row;
    }

    .frc {
        &-captcha {
            overflow: hidden;
            display: none;
            margin-bottom: 15px;
            margin-top: 0;
            padding: 5px 10px;
            width: 100%;
            background-color: #ffffff;

            &[data-attached] {
                display: block !important;
            }
        }

        &-container {
            display: flex;
            align-items: center;
        }

        &-icon {
            color: #505050;
            fill: #505050;
            stroke: #505050;
            margin: 0;
            margin-right: 10px;
        }

        &-content {
            button {
                color: #505050;
                border-color: #505050;
            }
        }

        &-banner {
            &,
            a {
                &,
                b {
                    color: #505050;
                }
            }
        }
    }
`;

export const FounderFormCheckbox = styled.input``;

export const FounderFormButton = styled.button`
  padding: 1rem;
  border: 2px solid #9dc1d4;
  background: white;
  color: #575656;
  outline: 0;
  &:hover {
    color: white;
    background: #575656;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }
`;

export const FounderLineBreak = styled.div``;
