import React, { useContext, FunctionComponent, Fragment, useState } from "react";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
import { Context, ISidebarText, getPage, ITextElement, IAsset } from "src/common";
import ImageWithAspectRatio from "src/common/BaseComponents/ImageWithAspectRatio/ImageWithAspectRatio";
import { handleFormSubmit } from "src/common/utils/formSubmit";
import { getContactCompanyNameShort } from "../../../../../common/components/ImprintStart";

import { Col, Row } from "../../../BaseComponents/Grid";
import { PlanningDateModaL } from "../PlanningDateModaL/PlanningDateModaL";
import RichText, { RichTextGeneral } from "../RichText";
import * as styles from "./Contact.style";
import {
  FormWrapper, CompanyInfo, OfficeInfo, SidebarText, ContactForm, SubmitWrapper,
  H2,
  H1,
  Separator,
  Centered,
  TextGreen,
  Button,
  ButtonText,
  CheckWrapper,
  Container,
} from "./Contact.style";

interface IContact
{
  isHeader?: boolean;
  wFull?: boolean;
}

const Contact: FunctionComponent<IContact> = ({ isHeader, wFull }) =>
{
  const props = useContext(Context);
  const page = getPage(props);
  const {
    register,
    handleSubmit,
    errors,
    reset,
  } = useForm();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [success, setSuccess] = useState(false);
  const [isShowModal, setShowModal] = useState(false);

  const onSubmit = async (data): Promise<any> =>
  {
    // Kontaktformular: d-26922068a30343d98a725c3fdaa663da
    // Terminanfrage: d-deb56cdeb9c34309be7a1a521bda38e6
    // Reparaturanfrage: d-82dc22fc5dee48db9e177c0140b37441
    let title: string | undefined;
    if(typeof window !== "undefined")
    {
      title = document.title;
    }
    await handleFormSubmit({
      ...data,
      line: props?.CompanyInformationPrivate?.line,
      subject: `Neue Anfrage über Ihre Homepage ${data?.Name ? `von ${data?.Name} ` : ""}${title ? `- ${title}` : ""}`,
      dealerMail: props?.CompanyInformationPublic?.email,
      templateId: "d-26922068a30343d98a725c3fdaa663da"
    });

    reset();
    setSuccess(true);
  };

  const name = props.UniversalTexts?.name || "Name";
  const eMail = props.UniversalTexts?.eMail || "E-Mail";
  const message = props.UniversalTexts?.message || "Nachricht";

  const sidebarText = props.PageContact?.extras?.find(
    extraItem => extraItem?.__typename === "SidebarText"
  ) as ISidebarText;

  // @ts-ignore
  const imgArray = page?.elements?.filter((e) => e?.__typename === "Asset") as IAsset[];
  // @ts-ignore
  const textElements = page?.elements?.filter(item => item?.__typename === "TextElement") as ITextElement[];

  textElements?.map(textElement =>
  {
    if(textElement?.icon)
    {
      textElement?.icon?.map(icon => imgArray.push(icon as any));
    }
  });
  return (
    <FormWrapper wfull={wFull}>
      {isShowModal && (
        <PlanningDateModaL onSetShowModal={setShowModal}/>
      )}
      {isHeader && (
        <>
          <H1>Kontakt</H1>
          <Separator/>
          <Container>
            <Col sm={12} md={4}>
              <CompanyInfo>
                <H2>
                Anschrift
                </H2 >
                <p>
                  {getContactCompanyNameShort() }
                </p>
                <p>{props.CompanyInformationPublic?.street}</p>
                <p>
                  {props.CompanyInformationPublic?.postcode + " "}{" "}
                  {props.CompanyInformationPublic?.place}
                </p>
              </CompanyInfo >
            </Col>
            <Col sm={12} md={4}>
              <CompanyInfo>
                <H2>
                Erreichbarkeit
                </H2 >
                {props.CompanyInformationPublic?.phone && (
                  <p>Tel: <TextGreen>{props.CompanyInformationPublic?.phone}</TextGreen></p>
                )}
                {props.CompanyInformationPublic?.fax && (
                  <p>Fax: <TextGreen>{props.CompanyInformationPublic?.fax}</TextGreen></p>
                )}
                {props.CompanyInformationPublic?.email && (
                  <p>E-Mail: <TextGreen>{props.CompanyInformationPublic?.email}</TextGreen></p>
                )}
                <a onClick={() => setShowModal(!isShowModal)}>
                  <Button>
                    <ButtonText>
                    Beratungstermin vereinbaren
                    </ButtonText>
                  </Button>
                </a>

              </CompanyInfo >
            </Col>

            <Col sm={12} md={4}>
              <OfficeInfo>
                <H2>
            Öffnungszeiten
                </H2 >
                {props?.CompanyInformationPublic?.openingHours && (
                  <RichTextGeneral
                    textContent={
                    props?.CompanyInformationPublic?.openingHours
                    }
                  />
                )}
                <div>
                  {(props.PageContact?.elements?.filter(
                e => e?.__typename === "TextElement"
              ) as ITextElement[])?.map((t, i) => (
                <Fragment key={i}>
                  {i === 1 ? (
                    <RichText
                      textContent={t.text}
                    >
                      <h1>{t.headline}</h1>
                    </RichText>
                  ) : (
                    i > 1 && (
                      <RichText
                        textContent={t.text}
                      >
                        <h1>{t.headline}</h1>
                      </RichText>
                    )
                  )}
                </Fragment>
              ))}
                </div>

                {imgArray?.map((imgItem, index) => (
                  <div key={`imageContact${index}`} >
                    <ImageWithAspectRatio
                      alt={imgItem?.title}
                      src={imgItem?.src}
                      height={342}
                      ratio={50}
                      quality={720}
                    />
                  </div>
                ))}
                {sidebarText?.text && (
                  <SidebarText>
                    <RichText textContent={sidebarText?.text}/>
                  </SidebarText>
                )}
              </OfficeInfo>
            </Col>

          </Container>
        </>
      )}
      <Separator/>
      <Row>
        <ContactForm wfull={wFull}>
          {props?.UniversalTexts?.contactForm && (
            <Centered>
              <H1 wfull={wFull}>{wFull ? "Unverbindliche Anfrage" : props?.UniversalTexts?.contactForm}</H1>
            </Centered>
          )}
          {success ? (
            <div>
              <h1>DANKE!</h1>
              Wir werden uns sobald wie möglich bei Ihnen melden.
            </div>
          )

            :
            <form onSubmit={handleSubmit(onSubmit)}>
              <div css={styles.inputsWrapper}>
                <Row expand>
                  <Col md={6}>
                    <label htmlFor={"Name"}>{name}</label>
                    <div css={styles.sidebar}>
                      <input
                        required={true}
                        type="text"
                        name="Name"
                        ref={register({
                          options: { required: true, unique: true },
                          min: 12
                        })}
                      />
                      <div css={styles.sidebarExpandIconContainer}>
                        <i className={"fa fa-fw fa-lg fa-user"} css={styles.sidebarExpandIcon}></i>
                      </div>
                    </div>

                  </Col>

                  <Col md={6}>
                    <label htmlFor={"E-Mail"}>{eMail}</label>
                    <div css={styles.sidebar}>
                      <input
                        required={true}
                        type="email"
                        name="E-Mail"
                        ref={register({
                          options: { required: true, unique: true },
                          min: 4
                        })}
                      />
                      <div css={styles.sidebarExpandIconContainer}>
                        <i className={"fa fa-fw fa-lg fa-envelope"} css={styles.sidebarExpandIcon}></i>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row expand>
                  <Col>
                    <label htmlFor={"Nachricht"}>{message}</label>
                    <textarea
                      rows={8}
                      required={true}
                      name="Nachricht"
                      ref={register({ required: true })}
                    />
                  </Col>
                </Row>
              </div>
              <SubmitWrapper>
                <CheckWrapper>
                  <input
                    required={true}
                    type="checkbox"
                    placeholder="Ich akzeptiere die Datenschutzerklärung"
                    name="checkbox"
                    ref={register({ required: true })}
                  />
                  <label
                    htmlFor={"checkbox"}
                    css={styles.datenschutzAkzeptieren}
                  >
                    Ich akzeptiere die <a href={`/${props.PageImprint?.slug || ""}`}>Datenschutzerklärung</a>
                  </label>
                </CheckWrapper>

                <div>
                  <button type="submit">
                    <i className={"fa fa-paper-plane"} css={styles.sidebarExpandIcon}></i>{props?.UniversalTexts?.Submit || "Absenden"}
                  </button>
                </div>
              </SubmitWrapper>

            </form>}
        </ContactForm>
      </Row>
    </FormWrapper>
  );
};

export default Contact;
