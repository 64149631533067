import React, { FC, useContext } from "react";
import { Context, getPage, IAsset, IPage_Elements } from "src/common";

import ImageWithAspectRatio from "../../../BaseComponents/ImageWithAspectRatio/ ImageWithAspectRatio";
import Gallery from "../../common/Gallery/Gallery";

const Hero: FC<any> = (props) =>
{
  const p = useContext(Context);
  const page = getPage(p);

  const imgArray = (page?.elements as IPage_Elements[])?.filter((e) => e?.__typename === "Asset") as IAsset[];
  const slider = (page?.elements as IPage_Elements[])?.filter((e) => e?.__typename === "Slider") as any[];

  const connectedArticles = (page as any)?.connectedArticles && (page as any)?.connectedArticles.filter(Article => Article?.__typename === "Article");

  const isShowSlider = slider && (slider.length > 0 || slider?.[0]?.elements.length > 0);
  let imageOrGallery;
  if(imgArray?.[0] || isShowSlider)
  {
    if(imgArray.length > 1 || isShowSlider)
    {
      if(slider)
      {
        imageOrGallery = <Gallery imgArray={slider?.[0]?.elements} />;
      }
      else
      {
        imageOrGallery = <Gallery imgArray={imgArray} />;
      }
    }
    else
    {
      imageOrGallery = (
        <ImageWithAspectRatio
          alt={imgArray?.[0]?.title}
          src={imgArray}
          height={420}
          ratio={25}
          quality={1080}
        />
      );
    }
  }

  if(connectedArticles && imgArray?.length < 1) 
  {
    imageOrGallery = (
      <ImageWithAspectRatio
        alt={connectedArticles[0]?.extras[0]?.subtitle || "NewsImage"}
        src={connectedArticles[0]?.extras[0]?.file}
        height={420}
        ratio={25}
        quality={1080}
      />
    );
  }  
  
  const TopComponent = props.topComponent;
  return (
    <>
      {TopComponent ? (
        <>
          <TopComponent />
        </>

      ) : (
        <>
          {imageOrGallery}
          {imageOrGallery ? <div style={{ margin: "20px 0" }} /> : null}
          {!imageOrGallery && <div style={{ margin: "240px 0 0" }} />}
        </>
      )}
    </>
  );
};

export default Hero;
