import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const Wrapper: any = styled.div`
  h3 {
    font-size: 15px;
    color: ${props => props.theme.palette.light_blue};
    font-weight: 700;
    margin-bottom: 10px;
  }
  strong {
    color: ${props => props.theme.palette.light_blue};
  }
  a > span {
    color: ${props => props.theme.palette.light_blue};
  }
  a {
    text-decoration-color: ${props => props.theme.palette.light_blue};
  }
  img {
     width: 100%;
    height: auto;
    @media (${BREAKPOINTS_DM.silver_768})
    {
        height: auto;
    }
  }
`;

export const Footnote: any = styled.div`
    margin: 40px 0 20px 0;
`;

export const ImgAndHeadline: any = css`
    display: flex;
    flex-direction: column;
    @media (${BREAKPOINTS_DM.silver_768})
    {
        flex-direction: row;
        align-items: center;
    }
`;

export const ImgContent: any = styled.div`
    diplay: flex;
    flex-direction: column;
    width: 100%;
    img {
      margin: 10px 0;
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
      flex-wrap: wrap;
      flex-direction: row;
      img {
        width: 30%;
        margin: 10px 20px 10px 0;
      }
    }
`;
