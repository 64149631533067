import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

type Props = {
    isActive: boolean;
};

export const Wrapper: any = styled.div<Props>`
    margin-bottom: 40px;
    border: 13px solid ${({ theme }) => theme.palette.light_blue};
    padding: 20px;
    svg {
        transform: scale(0.7);
        padding-left: 10px;
    }

    p {
        margin: 0 0 20px;
        font-weight: 300;
    }
    div.image {
        margin: 10px 0;
        width: 100%;
    }

    @media (${BREAKPOINTS_DM.silver_768}) {
        width: 75%;
        margin-left: auto;
        position: relative;

        ${({ isActive }) =>
            isActive &&
            css`
                margin-bottom: 185px;
            `}
    }
`;

export const HeadingWrapper: any = styled.div`
    margin: 10px 0;
    cursor: pointer;
    display: flex;
    align-items: center;

    h3.title {
        color: #85b2c3;
        font-weight: 700;
        font-size: 34px;
        font-family: sofia_pro_lightregular, Helvetica, Arial, sans-serif;
        letter-spacing: 0.68px;
        line-height: 38px;
        text-transform: uppercase;
    }
`;

export const RichTextWrapper: any = styled.div<Props>`
    * {
        color: #575656 !important;
    }

    div > h3 {
        color: #575656 !important;
    }

    ${({ isActive }) => (isActive ? isActiveStyle :  isNotActiveStyle)};
`;

export const isActiveStyle: any = css`
    overflow: hidden;
    height: auto;
    max-height: 2000px;
    transition: max-height 1s ease-in;

    font-family: sofia_pro_lightregular, Helvetica, Arial, sans-serif;
    ul {
        padding-left: 35px;
        list-style-position: inside;
        * {
            list-style-position: inside;
        }
        li {
            display: list-item;
            list-style: disc;
        }
    }
    strong ol li {
        font-weight: 700;
    }
    ul,
    ol {
        display: inline-block;
        text-align: left;
        li,
        span {
            text-align: left;
        }
    }
    ol {
        padding-left: 15px;
        list-style-position: inside;
        * {
            list-style-position: inside;
        }
        li {
            display: list-item;
            list-style: decimal;
        }
    }
    a {
        &,
        span {
            color: #d30032;
        }
        span {
            &::after {
                content: "";
                display: inline-block;
                background-image: url("/images/sprite-s82c5aa31b4.png");
                background-repeat: no-repeat;
                background-position: -20px -36px;
                width: 15px;
                height: 15px;
                margin-left: 5px;
                transform: translateY(3px);
            }
        }
    }
    h1,
    h2,
    h3,
    h4 {
        &,
        span {
            font-size: 14px;
            color: #fff;
            font-weight: 400;
        }
    }
    h3,
    h4 {
        &,
        span {
            font-weight: 400;
            letter-spacing: 0.02em;
            color: #fff;
            font-size: 1.2rem;
        }
    }
    p {
        font-weight: 300;
        span {
            font-weight: 300;
            font-size: 15px;
            font-family: sofia_pro_lightregular, Helvetica, Arial, sans-serif;
        }
    }
    p.bottom-button {
        color: #92c2d6;
        font-size: 15px;
        font-weight: 100;
        line-height: 34px;
    }
`;

export const isNotActiveStyle: any = css`
    overflow: hidden;
    height: auto;
    max-height: 65px;
    transition: max-height 1s ease-out 0s;

    h1,
    h2,
    h3,
    h4 {
        &,
        span {
            font-size: 14px;
            color: #fff;
            font-weight: 400;
        }
    }
    h3,
    h4 {
        &,
        span {
            font-weight: 400;
            letter-spacing: 0.02em;
            color: #fff;
            font-size: 1.2rem;
        }
    }
    p {
        font-weight: 300;
        span {
            font-weight: 300;
            font-size: 15px;
            font-family: sofia_pro_lightregular, Helvetica, Arial, sans-serif;
        }
    }
    p.bottom-button {
        color: #92c2d6;
        font-size: 15px;
        font-weight: 100;
        line-height: 34px;
    }
`;

export const imgAndText: any = css`
    display: flex;
    flex-direction: column;
    @media (${BREAKPOINTS_DM.silver_768}) {
        flex-direction: row;
        align-items: flex-start;
    }
`;

export const ImgAndSubtitleWrapper = styled.div<Props>`
    display: flex;
    flex-direction: column;

    @media (${BREAKPOINTS_DM.silver_768}) {
        position: absolute;
        left: -41%;
        top: -13px;
        width: 35%;
    }
`;

export const subTitle: any = css`
    &:not(:last-of-type) {
        margin-bottom: -20px;
    }
    background-color: rgb(112, 111, 111);
    padding: 10px;
    font-size: 12px;
    text-align: center;
    margin-bottom: 20px;
`;
