import React, { FunctionComponent, Fragment, useContext, useState } from "react";
import { Context, getPage, ITextElement, ICallToAction, IPhotoGallery } from "src/common";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";
import UniversalComponentsRenderer from "src/common/BaseComponents/UniversalCompoentRenderer/UniversalComponentsRenderer";
import FullscreenImage from "src/common/components/FullscreenImage";
import PhotoGallery from "src/common/components/PhotoGalleryVisualization";
import { css } from "styled-components";

import Articles from "../../common/Articles";
import { PlanningDateModaL } from "../../common/PlanningDateModaL/PlanningDateModaL";
import { RichTextGeneral, SRichTextGeneral } from "../../common/RichText";
import Hero from "./Hero";
import * as styles from "./MainContent.style";
import {
  ContentContainer,
  ContentWrapper,
  Title,
  Title1,
  Title2,
  TitleContent,
  TitleContent1,
  CallToActionWrapper,
  CallToActionButton,
  UniversalElementWrapper,
  Separator,
  Figcaption,
  Figcaption1,
  Figcaption2,
  FigcaptionWrapper,
  Figcaption2Wrapper,
  TextIconFileWrapper,
  WidthFull,
  WidthFullText,
  NavBarSubitemsWrapper,
  NavBarSubitemsContent,
  NavBarSubitemsButton,
  HorizontalSeparator,
  TextWrapper,
  TextArticleWrapper,
  PhotoGalleryWrapper,
} from "./MainContent.style";

const MainContent: FunctionComponent<any> = (props) => 
{
  const p = useContext(Context);
  const page = getPage(p);

  const [isShowModal, setShowModal] = useState(false);

  // I verify if the slug of the current page is in the list of subitems of the PrimaryNavigation.items. If the slug is found I use that list of subitems to create the navBarSubitems line 73
  const navBarSubitems =
    p.PrimaryNavigation?.items?.map(item => item.subitems?.length > 0 && item.link[0].slug === p.slug && item)
      .filter(item => item);

  const navBarSubitemsSecondary =
    p.PrimaryNavigation?.items?.map(item => item.subitems?.length > 0 && item.subitems)
      .filter(item => item)
      .map(item => item.find(element => element?.slug === /* "finanzierung"*/ p?.slug) && item)
      .find(item => item);

  // @ts-ignore
  const mixedElements = page?.elements?.filter((e) =>
    e?.__typename === "ContinuingPageGallery"
    || e?.__typename === "PhotoGallery"
    || e?.__typename === "TextElement"
    || e?.__typename === "Asset"
    || e?.__typename === "CallToAction"
  ) as any[];

  const TopComponent = props.topComponent;

  const connectedArticles = (page as any)?.connectedArticles;

  const ArticlesWhitImage = connectedArticles && connectedArticles[0]?.extras[0]?.file;
  
  const isAssetRefSlice = mixedElements?.find(item => item?.__typename === "Asset");
  const isTextElement = mixedElements?.filter(item => item?.__typename === "TextElement");
  return (
    <>
      <ContentContainer>
        <ContentWrapper>
          <Hero topComponent={TopComponent} />
          {isShowModal && (
            <PlanningDateModaL onSetShowModal={setShowModal} />
          )}
          {navBarSubitems && (
            <NavBarSubitemsWrapper isArticle={connectedArticles ? true : false} isArticleWithImage={ArticlesWhitImage ? true : false}>
              <NavBarSubitemsContent>
                {navBarSubitems[0]?.subitems?.length > 0 && navBarSubitems[0].subitems.map((subitem, i) => (
                  <NavBarSubitemsButton href={`/${subitem?.slug}`} key={i}>
                    {subitem?.nameInNavigation}{i !== navBarSubitems[0].subitems.length - 1 && <HorizontalSeparator />}
                  </NavBarSubitemsButton>
                ))}

                {navBarSubitemsSecondary?.length > 0 && navBarSubitemsSecondary.map((subitem, i) => (
                  <NavBarSubitemsButton href={`/${subitem?.slug}`} key={i} isCurrent={subitem?.slug === p.slug}>
                    {subitem?.nameInNavigation}{i !== navBarSubitemsSecondary.length - 1 && <HorizontalSeparator />}
                  </NavBarSubitemsButton>
                ))}

              </NavBarSubitemsContent>
            </NavBarSubitemsWrapper>
          )}

          {mixedElements &&
            mixedElements.map((element, i) => 
            {
              const textElement = element?.__typename === "TextElement" && element as ITextElement;
              const universlElement = (element?.__typename === "ContinuingPageGallery" || element?.__typename === "PhotoGallery") && element as ITextElement;
              const callToAction = element?.__typename === "CallToAction" && element as ICallToAction;
              const isTextIconFile = textElement?.icon && textElement.icon.length > 0;
              const photoGallery = element?.__typename === "PhotoGallery" && element.visualization === "mosaicimage" && element as IPhotoGallery;
              return (
                <Fragment key={`mixedElements${i}`}>
                  <div>
                    {callToAction && (
                      <CallToActionWrapper>
                        <RichTextGeneral textContent={callToAction.TextAboveButton} />
                        <CallToActionButton onClick={() => setShowModal(!isShowModal)}>
                          {callToAction.ButtonLabel}
                        </CallToActionButton>
                      </CallToActionWrapper>

                    )}
                    {textElement &&
                      <div>
                        {isAssetRefSlice && isTextElement.length === 1 && (
                          <div css={css`${SRichTextGeneral}`}>
                            <TitleContent>
                              <Title >{textElement?.headline}</Title>
                            </TitleContent>
                          </div>
                        )}
                        {isAssetRefSlice && isTextElement.length > 1 && isTextElement[0].headline === textElement.headline && !connectedArticles && (
                          <div css={css`${SRichTextGeneral}` as any}>
                            <TitleContent>
                              <Title>{textElement?.headline}</Title>
                            </TitleContent>
                            {textElement?.text && <TextWrapper><RichTextGeneral fragment textContent={textElement?.text} /></TextWrapper>}
                            {isTextIconFile && (
                              <div>
                                {textElement.icon.map((icons, index) => (
                                  <Figcaption key={`i${index}`}>
                                    <FullscreenImage
                                      quality={1020}
                                      img={[{ src: icons.src, __typename: "Asset" }]}
                                      subtitle={icons.title}
                                    />
                                  </Figcaption>
                                ))}
                              </div>
                            )}
                          </div>
                        )}

                        {isTextElement.length === 1 && (
                          <div css={css`${SRichTextGeneral}${styles.imageInText}` as any}>
                            {!isAssetRefSlice && isTextElement.length === 1 && (
                              <TitleContent>
                                <Title2 >{textElement?.headline}</Title2>
                              </TitleContent>
                            )}
                            {textElement?.text && <TextWrapper><RichTextGeneral fragment textContent={textElement?.text} /></TextWrapper>}
                            {isTextIconFile && (
                              <Figcaption2Wrapper>
                                {textElement.icon.map((icons, index) => (
                                  <Figcaption2 key={`i${index}`}>
                                    <FullscreenImage
                                      quality={1020}
                                      img={[{ src: icons.src, __typename: "Asset" }]}
                                      subtitle={icons.title}
                                    />
                                  </Figcaption2>
                                ))}
                              </Figcaption2Wrapper>
                            )}
                          </div>
                        )}
                      </div>
                    }
                    {universlElement && !photoGallery &&
                      <>
                        <UniversalElementWrapper>
                          <UniversalComponentsRenderer items={[universlElement]} />
                        </UniversalElementWrapper>
                      </>
                    }
                    {photoGallery &&
                      <PhotoGalleryWrapper>
                        <PhotoGallery item={photoGallery} />
                        <Spacer bronze={20} silver={30}></Spacer>
                      </PhotoGalleryWrapper>
                    }
                  </div>
                </Fragment>
              );
            })}

          {isTextElement &&
            <FigcaptionWrapper>
              {isTextElement.map((element, i) => 
              {
                if(i === 0) { return; }
                const textElement = element?.__typename === "TextElement" && element as ITextElement;
                const isTextIconFile = textElement?.icon && textElement.icon.length > 0;
                return (
                  <TextIconFileWrapper key={`mixedElements${i}`}>
                    {isTextElement.length > 1 && (
                      <WidthFull>
                        {isTextElement[0].headline !== textElement.headline && (
                          <div css={css`${SRichTextGeneral}${styles.imageInText2}` as any}>
                            {textElement.headline && <Title1>{textElement.headline}</Title1>}
                            {textElement?.text && <WidthFullText><RichTextGeneral fragment textContent={textElement?.text} /> </WidthFullText>}
                            {isTextIconFile && (
                              <div>
                                {textElement.icon.map((icons, index) => (
                                  <Figcaption1 key={`i${index}`}>
                                    <FullscreenImage
                                      quality={1020}
                                      img={[{ src: icons.src, __typename: "Asset" }]}
                                      subtitle={""}
                                    />
                                  </Figcaption1>
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                      </WidthFull>
                    )}
                  </TextIconFileWrapper>
                );
              })}
            </FigcaptionWrapper>
          }

          {connectedArticles && (mixedElements?.[0].__typename === "Asset"|| mixedElements?.length < 1) && (
            <div css={css`${SRichTextGeneral}` as any}>
              <TitleContent1>
                <Title>{connectedArticles[0]?.headline}</Title>
              </TitleContent1>
              {connectedArticles[0]?.mainText && <TextArticleWrapper><RichTextGeneral fragment textContent={connectedArticles[0]?.mainText} /></TextArticleWrapper>}
            </div>
          )}
          <Articles connectedArticles={connectedArticles} footnote={page?.footnote} />
        </ContentWrapper>
      </ContentContainer>
      {props.children}
    </>
  );
};

export default MainContent;
