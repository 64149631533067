import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const Title: any = styled.div<{withOutImg: boolean}>`
  top: -150px;
  height: ${({ withOutImg }) => withOutImg ? "250px" : "366px"};
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  h1 {
    width: 1170px;
    color: rgb(255, 255, 255);
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 40px;
    font-weight: 800;
    line-height: 56px;
    text-shadow rgb(68, 68, 68) 0px 1px 1px;
    margin: 0 0 10px;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    top: 112px;
    ${({ withOutImg }) => withOutImg ? "250px" : "366px"};
  }
`;

export const HeroWrapper: any = styled.div`
  margin-top: 80px;
  > div {
    img {
      height: 366px;
    }
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    margin-top: 112px;
  }
`;

export const WithOutImage: any = styled.div`
  width: 100%;
  height: 250px;
  background-color: rgb(230, 124, 25);
  margin-top: 112px;
`;
