import React, { FunctionComponent, useState, useContext } from "react";
import { Context, getPage, ITextElement, IAsset, IPhotoGallery, IContext } from "src/common";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";
import UniversalComponentsRenderer from "src/common/BaseComponents/UniversalCompoentRenderer/UniversalComponentsRenderer";
import PhotoGallery from "src/common/components/PhotoGalleryVisualization";
import { css } from "styled-components";

import Articles from "../../common/Articles";
import FullscreenImage from "../../common/FullscreenImage";
import InformationAndService from "../../common/InformationAndService";
import ProductItem from "../../common/ProductItem";
import { RichTextGeneral, SRichTextGeneral } from "../../common/RichText";
import SubItemNavigation from "../../common/SubItemNavigation";
import NewsContent from "../NewsContent";
import Hero from "./Hero";
import * as styles from "./MainContent.style";
import {
  ContentContainer,
  ContentWrapper,
  UniversaElementWrapper,
  ElementWrapper,
  RichTextContent,
  ImgWrapper,
  ImgContent,
  Frame,
  WrapperTitle,
  ContentTextWrapper,
  ElementTextWrapper,
  ImgTextContent,
  ImageWrapper,
} from "./MainContent.style";

const getExternalLink = (p: IContext): string => p.PageMicro?.slug === p?.slug && p.PageMicro.externalLinkAddress;

const TextElement: FunctionComponent<{textElement: Record<string, any>}> = ({ textElement }) =>
{
  const [isShowContent, toogleShowContent] = useState(false);
  return (
    <ElementTextWrapper>
      <WrapperTitle>
        <div className="title" onClick={() => toogleShowContent(true)}>
          {!isShowContent && <div className="icon-down"><i className="fa fa-caret-down"/></div>}
          <h3>{textElement?.headline}</h3></div>
      </WrapperTitle>
      <ContentTextWrapper isShowContent={isShowContent}>
        <ImgTextContent>
          <div className="text">
            <RichTextGeneral textContent={textElement?.text} />
          </div>
          <div className="image">
            {textElement?.icon?.map((img, i) => (
              <img
                key={`art-img-${i}`}
                src={img?.file?.[0]?.src}
                alt={img?.subtitle || "NewsImage"}
              />
            ))}
          </div>
        </ImgTextContent>
        <div className="title-bottom" onClick={() => toogleShowContent(false)}><h3 className="bottom">SCHLIESSEN</h3><div className="icon-up"><i className="fa fa-caret-up"/></div></div>
      </ContentTextWrapper>
    </ElementTextWrapper>
  );
};

const MainContent: FunctionComponent<any> = (props) =>
{
  const p = useContext(Context);
  const page = getPage(p);
  const pageCourse = p?.PageCourse;
  // @ts-ignore
  const mixedElements = page?.elements?.filter(
    (e) => e?.__typename === "ContinuingPageGallery" || e?.__typename === "PhotoGallery" || e?.__typename === "TextElement" || e?.__typename === "Asset"
  ) as any[] || pageCourse?.elements as any[];
  const connectedArticles = (page as any)?.connectedArticles;

  const TopComponent = props.topComponent;
  const firstTextElement = mixedElements?.find(element => element?.__typename === "TextElement");
  const subItemsNavigation = p?.PrimaryNavigation?.items?.find(primaryItem => primaryItem?.subitems?.find(secondaryItem => secondaryItem?.slug === p.slug && true)) || p?.PrimaryNavigation?.items?.find(primaryNav => primaryNav.link[0]?.slug === p.slug && true);
  const isShowItemsNavigation = p?.slug === "quooker-kochend-wasserhahn";
  const externalLinkAddress = getExternalLink(p);
  // @ts-ignore
  const isWithIcon = page?.elements?.find(element => element?.icon && element?.icon[0] && element?.icon[0].file?.length > 1);
  return (
    <>
      <ContentContainer>
        <ContentWrapper pageBrand={props.pageBrand}>
          <Hero topComponent={TopComponent} pageBrand={props.pageBrand}/>
          {externalLinkAddress && (
            <Frame src={externalLinkAddress} />
          )}
          {firstTextElement &&
          <ElementWrapper>
            <RichTextContent isFirst={true}>
              <h1>{firstTextElement?.headline}</h1>
              {firstTextElement?.text && <RichTextGeneral fragment textContent={firstTextElement?.text} />}
              <ImgContent row={firstTextElement?.icon?.length > 2}>
                {firstTextElement?.icon?.map((i, index) =>
                  (
                    <div key={`aai${index}`}>
                      {i.file?.map(item =>
                        (
                          <ImgWrapper key={`i${index}`}>
                            <FullscreenImage
                              img={[item]}
                              subtitle={i.subtitle}
                            >{i.subtitle && <p css={styles.figcaption}>{i.subtitle}</p>}</FullscreenImage>
                          </ImgWrapper>
                        )
                          )}
                    </div>)
                        )}
              </ImgContent>
            </RichTextContent>
          </ElementWrapper>
          }

          {mixedElements &&
            mixedElements.map((e, i) =>
            {
              const textElement = e?.__typename === "TextElement" && e as ITextElement;
              const imageElement = TopComponent && e?.__typename === "Asset" && e as IAsset;
              const universlElement = (e?.__typename === "ContinuingPageGallery") && e as ITextElement;
              const photoGallery = e?.__typename === "PhotoGallery" && e as IPhotoGallery;
              if(firstTextElement === textElement)
              {
                return;
              }
              return (
                <ElementWrapper key={`mixedElements${i}`} >
                  {imageElement &&
                    <div css={css`${SRichTextGeneral}${styles.imageInText}` as any}>
                      <FullscreenImage
                        subtitle={imageElement?.title}
                        img={[imageElement]}
                      />
                    </div>
                  }
                  {textElement && isWithIcon &&
                    <>
                      <RichTextContent>
                        <h1>{textElement?.headline}</h1>
                        {textElement?.text && <RichTextGeneral fragment textContent={textElement?.text} />}
                        <ImageWrapper>
                          {textElement?.icon?.map((i, index) =>
                          {
                            if(i?.src)
                            {
                              return (
                                <FullscreenImage
                                  key={`i${index}`}
                                  img={[i]}
                                  subtitle={i.title}
                                >{i.title && <p css={styles.figcaption}>{i.title}</p>}</FullscreenImage>
                              );
                            }
                            return null;
                          })}
                        </ImageWrapper>
                      </RichTextContent>
                    </>
                  }
                  {textElement && !isWithIcon && <TextElement textElement={textElement}/>}

                  {universlElement &&
                    <UniversaElementWrapper>
                      <UniversalComponentsRenderer items={[universlElement]}>
                        {({ item }) => <ProductItem item={item} />}
                      </UniversalComponentsRenderer>
                      <Spacer bronze={20} silver={30}></Spacer>
                    </UniversaElementWrapper>
                  }

                  {photoGallery &&
                    <UniversaElementWrapper>
                      <PhotoGallery item={photoGallery} />
                      <Spacer bronze={20} silver={30}></Spacer>
                    </UniversaElementWrapper>
                  }
                </ElementWrapper>
              );
            })}
          <Articles connectedArticles={connectedArticles} footnote={page?.footnote} />
          {subItemsNavigation && !isShowItemsNavigation && <SubItemNavigation />}
        </ContentWrapper>
      </ContentContainer>
      {props.newContent && <NewsContent/>}
      <InformationAndService/>
      {props.children}
    </>
  );
};

export default MainContent;
