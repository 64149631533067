import React, { FC } from "react";
import { getIfNewsShouldBeDisplayByDate, Img, IProduct } from "src/common";
import IconAngle from "src/common/components/ProductLandingBanner/Icons/IconAngle";

import RichTextGeneral from "../RichText";
import { ItemDefaultWrapper, ImageWrapper, Description, Price, RichTextGeneralWrapper } from "./ItemDefault.style";


const ItemDefault: FC<{ item: any }> = ({ item }) => 
{
  
  // __typename: "ContinuingPageGalleryItem"
  if(!item) 
  {
    return null;
  }

  const product = (item?.link?.[0]) as IProduct;
  const isProduct = product?.__typename.includes("Product");
  const link = item?.link?.[0] as any;
  const price = link?.ourPrice;
  const slug = link?.slug;
  const externalLink = link?.externalLinkAddress;
  const isContinuingPageGalleryItem = item?.__typename === "ContinuingPageGalleryItem";
  const fixLinkAddress = (linkToFix: string): string => /https:/.test(linkToFix) ? linkToFix : `https:/${linkToFix}`;

  // console.log(item, "es aca");

  if(isProduct && product?.acceptAGB && !getIfNewsShouldBeDisplayByDate({ startDate: product?.startDate, endDate: product?.endDate })) 
  {
    return null;
  }

  return (
    <ItemDefaultWrapper isProduct={isProduct}>
      {isContinuingPageGalleryItem ? (
        <a href={fixLinkAddress(externalLink)} target="_blank" rel="noreferrer" className="external-link">
          <h2 className="link-title">{item.title}</h2>
          <RichTextGeneral textContent={item?.text}/>
        </a>

      ) : (

        <a href={`/${isProduct ? "produkt/" : ""}${slug}`}>
          <h2>{item.title}</h2>
          <ImageWrapper isProduct={isProduct}>
            <Img src={item.thumbnail} />
          </ImageWrapper>
          {isProduct && (
            <Description>
              <div>Jetzt für nur</div>
              <Price>
                {price && <span>{price} €</span>}
                <span>
                  <IconAngle /> zum Angebot
                </span>
              </Price>
            </Description>
          )}
        </a>
      )
      }
    </ItemDefaultWrapper>
  );
};

export default ItemDefault;
