import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const ContentContainer: any = styled.div`
    display: flex;
    flex-direction: column;
    max-width: ${({ theme }) => theme.maxWidth}px;
    width: 100%;
    margin: 160px auto 0;
    @media (${BREAKPOINTS_DM.gold_1024}) {
        flex-direction: row;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      &,
      span {
        color:  color: #FA8800;t;

      }
    }

    h1,
    h2,
    h3 {
        &,
        span {
            font-weight: 300;
        }
    }
    h1 {
        width: fit-content;
        padding: 2px 10px 2px 0p;
        &,
        span {
            font-size: 22px;
            color: #FA8800;
            font-weight: 400;
            letter-spacing: .02em;
        }
    }
    P {
      padding-left: 50px;
      margin: 0 0 24px;
      span {
        color: #000;
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
      }
    }
    a > span {
      color:  #FA8800;
    }
    iframe {
      width: 100%;
      @media (${BREAKPOINTS_DM.gold_1024}) {
        width: 910px;
      }
    }

`;

export const ContentWrapper: any = styled.div<{pageBrand: boolean}>`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${({ pageBrand }) => pageBrand ? "0 18px 0 0" : "0 10px"};
    @media (${BREAKPOINTS_DM.gold_1024}) {
      padding: 0;
    }
`;

export const sideBarWrapper: any = css`
  width: 100%;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    max-width: 224px;
    padding-left: 20px;
  }
`;

export const imageInText: any = css`
  > div:first-of-type {
    height: auto;
    > img {
        height: auto;
    }
    float: left;
    margin: 0 20px 20px 0;
    max-width: 33%;
  }
`;

export const figcaption: any = css`
  text-align: center;
  background-color: rgba(255,255,255,0.5);
  padding: 10px 10px;
`;

export const UniversaElementWrapper: any = styled.div`
  margin-top: 75px;
  max-width: 950px;
  [data-gallery] {
    margin: 0;
    [data-caption] {
      color: #000;
      font-weight: 300;
      line-height: 21px;
      font-size: 14px;
      padding: 14px;
    }
    [data-aos] {
      width: 100%;
      [data-image] {
        width: 100%;
        div {
          width: 100%;
        }
      }
    }
  }
  .slick-prev,
  .slick-next {
    z-index: 9;
    background-color: rgba(255,255,255,.5) !important;
    width: 30px !important;
    height: 30px !important;
    :before {
      opacity: 1;
      display: inline-block;
      content: "" !important;
      background-image: url(/images/sprite-sa3ca3ca63e.png);
      background-repeat: no-repeat;
      background-position: 0 -172px;
      height: 18px;
      width: 12px;
      color: transparent !important;
    }

    :hover {
      background-color: rgba(255,255,255,.5);
    }
  }

  .slick-next {
    right: 0 !important;
  }

  .slick-prev {
    left: 0 !important;
    :before {
      transform: rotate(180deg);
    }
  }

  .slick-slide{
    padding-top: 6px;
    padding-right: 6px;
    &:not(.slick-current) {
      opacity: .5;
    }
  }

  [data-gallery] {

    [data-caption] {
      padding: 14px;
      text-align: center;
      color: #000;
    }
  }
`;

export const ElementWrapper: any = styled.div`
`;

export const RichTextContent: any = styled.div<{isFirst: boolean}>`
margin-top: 30px;
border-top: ${({ isFirst }) => isFirst ? "1px solid #000" : "none"};
border-left: ${({ isFirst }) => isFirst ? "1px solid #000" : "none"};
border-right: ${({ isFirst }) => isFirst ? "1px solid #000" : "none"};
padding-top: 20px;
padding-bottom: 20px;
color: #000;
p {
  padding: ${({ isFirst }) => isFirst ? "0 0 0 50px" : "0"};
}
ul > li > span {
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
}

div > iframe {
  width: 100%;
  height: 394px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: ${({ isFirst }) => isFirst ? "560px" : "700px"};
    height: ${({ isFirst }) => isFirst ? "315px" : "394px"};
  }
}
`;

export const ImageWrapper: any = styled.div`
  display: flex;
  padding: 5px 0;
  align-items: flex-start;
  flex-wrap: wrap;

  > div {
    margin-right: 20px;
    margin-bottom: 20px;

    width: calc(100% / 3 - 20px);

  }
`;

export const ImgWrapper: any = styled.div`
  margin-bottom: 10px;
  div {
    width: fit-content;
  }
  div > div > img {
    width: 100%;
    @media (${BREAKPOINTS_DM.gold_1024}) {
      padding-left: 50px;
      width: 302px;
    }
  }
`;

export const ImgContent: any = styled.div<{row: boolean}>`
  width: 100%;
  ${({ row }) => row && `
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  `}
`;
export const Frame: any = styled.iframe`
  width: 100%;
  height: 100vh;
`;

export const WrapperTitle: any = styled.div<{first: boolean}>`
  margin: 42px 0 10px;
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
`;

export const ContentTextWrapper: any = styled.div<{isShowContent: boolean}>`
${({ isShowContent }) => isShowContent ?
    `
  overflow: hidden;
  height: auto;
  max-height: 2000px;
  transition: max-height 10s ease-out;
  div {
    p {
      padding: 0;
    }
  }
  `
    :
    `
  overflow: hidden;
  height: auto;
  max-height: 0;
  transition: max-height 1s ease;`
}

`;

export const ElementTextWrapper: any = styled.div`
  div.title {
    padding: 10px 0 0 20px;
    display: flex;
    font-size: 15px;
    color: #FA8800;
    align-items: center;
  }
  div.icon-down {
    margin: 0 5px 10px 0;
    line-height: 21px;
  }

  div.title-bottom {
    margin: 21px 0;
    display: flex;
    font-size: 15px;
    color: #000;
    align-items: center;
  }
  div.icon-up {
    margin: 0 0 10px 5px;
    line-height: 21px;
  }

  h3 {
    font-size: 15px;
    color: #FA8800;
    font-weight: 400;
    margin-bottom: 10px;
    line-height: 21px;
    letter-spacing: 0.3px;
  }
  h3.bottom {
    font-size: 14px;
    color: #000;
    font-weight: 300;
    margin-bottom: 10px;
    line-height: 21px;
    letter-spacing: 0.3px;
  }
  img {
    width: 100%;
   height: auto;
   @media (${BREAKPOINTS_DM.silver_768})
   {
       height: auto;
   }
 }
 div > p {
   margin: 0;
   padding: 0;
   span {
     font-weight: 400;
     letter-spacing: 0.3px;
   }
 }
`;

export const ImgTextContent: any = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    img.image {
      margin: 10px 0;
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
      flex-wrap: wrap;
      flex-direction: row;
      div.text {
        width: 70%;
      }
      div.image {
        width: 30%;
        padding: 0 20px 10px 20px;
      }
    }
`;
