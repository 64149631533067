import styled, { css } from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Wrapper: any = styled.div`
  display: none;
  position: relative;
  margin-bottom: 25px;

  > div {
    margin-bottom: 2px;
    a {
      display: block;
      padding-top: 5.25px;
      padding-bottom: 5.25px;
      padding-left: 10.5px;

      background-color: ${props => props.theme.palette.textColor};
      color: ${props => props.theme.palette.white};
      line-height: 21px;

      &:hover,
      &.active {
        color: ${props => props.theme.palette.white};
        background-color: ${props => props.theme.palette.primary};
      }
    }
  }

  @media (${BREAKPOINTS_DM.gold_1024}){
    display: block;
  }
`;
