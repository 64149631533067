import React, { FC } from "react";
import { IContext, Context } from "src/common";

import { Container } from "../Grid";
import { SubNavigationWrapper } from "./SubNavRight.style";

const SubNavRight: FC = () => 
{
  const props = React.useContext<IContext>(Context);
  const navItem = props?.PrimaryNavigation?.items
    ?.find(
      i =>
        !!i?.subitems?.find(s => s?.slug === props.slug) ||
        i?.link?.[0]?.slug === props.slug
    )
    ?.subitems;

  if(!navItem) { return null; }
  return navItem
    && navItem.length >= 1
    && (
      <SubNavigationWrapper>
        <Container>
          <ul>
            {navItem.map((item, i) => 
              (
                <li key={`${i}${item?.slug}`}>
                  <a href={`/${item?.slug}`} className={`${item?.slug === props?.slug ? "active" : ""}`}>
                    {item?.nameInNavigation || ""}
                  </a>
                </li>
              ))}
          </ul>
        </Container>
      </SubNavigationWrapper>
    );
};

export default SubNavRight;
