import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Wrapper: any = styled.div`
  max-width: ${({ theme }) => theme.maxWidth}px;
  width: 100%;
  margin: 20px auto 0;
  
`;

export const NavigationPage: any = styled.nav`
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: nowrap;
    margin: 12px 0px 60px 0px;

    svg.olderNewsSvg {
      transform: rotate(90deg);
      padding: 2px 0px 0px 8px;
      margin-right: 10px;
    }

    svg.latestNewsSvg {
      transform: rotate(270deg);
      padding: 2px 8px 0px 0px;
      margin-left: 10px;
    }

    a.olderNews {
      padding: 0px 10px 6px 4px;
    }

    a.latestNews {
      padding: 0px 6px 6px 10px;
    }

    a {
      background-color: #FA8800;
      color: white;
      text-align: center;
      border-radius: 4px;
      display: flex;

      > p {
        margin-top: 6px;
      }
      &:hover {
        background-color: #000;
        color: #FA8800;
      }
    }
    @media (max-width: 500px)
    {
        a {
          padding: 4px 10px !important;
        }
    }
`;

export const SubItemsWrapper: any = styled.div`
    display: none;
    @media (${BREAKPOINTS_DM.gold_1024}) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
`;

export const SubItem: any = styled.div`
    width: 32%;
    margin: 0 0 20px;
`;

export const SubItemName: any = styled.a<{isActive: boolean}>`
    display: flex;
    background-color: ${({ isActive }) => isActive ? "#FA8800" : "#333333"};;
    padding: 15px;
    span {
      font-size: 14px;
      font-weight: 300;
      line-height: 19.6px;
      color: #fff;
      &:hover {
        color: ${({ isActive }) => isActive ? "#333333" : "#FA8800"};
      }
    }
`;

export const SubItemImg: any = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E6E7E9;
    color: gray;
    height: 150px;
    margin: 0 0 10px;
`;
