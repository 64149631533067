import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const Wrapper: any = styled.ul`
  display: none;
  position: relative;
  left: 0px;
  
  li {
    margin: 0 0 2px;
    border-bottom: 1px solid #fff;
    a {
      text-transform: uppercase;
      // white-space: pre-wrap;
      display: block;
      padding: 5.25px 0px 5.25px 10.5px;
      line-height: 21px;
      font-weight: 300;
      font-size: 14.5px;
    }
    a:hover {
      background-color: #76B729;  
    }
  }

  @media (${BREAKPOINTS_DM.gold_1024}){
    display: block;
  }

  .active {
    background-color: #76B729;
  }
`;
