import React, { FC, Fragment, useContext } from "react";
import {
  Context, getPage, ITextElement, ISidebarText, IAsset, Img, IPhotoGallery, FullscreenImage, ITextElement_Icon, IContinuingPageGallery 
} from "src/common";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";
import UniversalComponentsRenderer from "src/common/BaseComponents/UniversalCompoentRenderer/UniversalComponentsRenderer";
import PhotoGallery from "src/common/components/PhotoGalleryVisualization";

import Articles from "../../common/Articles";
import { Section, Container } from "../../common/Grid";
import Micro from "../../common/Micro/Micro";
import ProductItem from "../../common/ProductItem";
import { RichTextGeneral } from "../../common/RichText";
import { ContentContainer, ContentWrapper, UniversaElementWrapper, AssetContainer, TextContainer, TextWrapper, ImageWrapper } from "./MainContent.style";

const AssetElement: FC<{ element: IAsset }> = ({ element }) => 
{
  if(!element) { return null; }

  return (
    <AssetContainer>
      <Img
        alt={element.title}
        src={[element]}
        placeholderHeight="580px"
        quality={1080} />
    </AssetContainer>
  );
};

const ImageContainerElement: FC<{ element: ITextElement_Icon[] }> = ({ element }) => 
{
  const chunks = element.length < 3
    ? [element]
    : [element.slice(0, 1), element.slice(1)];

  return (
    <>
      {chunks.map((icons, x) => (
        <>
          {icons.map((i, index) => !!i.src &&
            <ImageWrapper key={`icons-wrapper-${x}`}>
              <FullscreenImage
                key={`i-${index}`}
                quality={1020}
                img={[i]}
                subtitle={i.title}
                noWrapper
              >
                {i.title && <p>{i.title}</p>}
              </FullscreenImage>
            </ImageWrapper>
          )}
        </>
      ))}

    </>
  );
};

const TextElement: FC<{ element: ITextElement; index: number; rtl?: boolean }> = ({ element, rtl }) => 
{
  const bulletList = element?.text?.content?.some(item => item.type === "bulletList");

  if(element.icon?.length) 
  {
    return (
      <TextContainer count={element.icon?.length} rtl={rtl}>
        {!!element?.icon?.length && (
          <ImageContainerElement element={element.icon} />
        )}
        <TextWrapper>
          {element.headline && <h1>{element.headline}</h1>}
          {element.text && <RichTextGeneral fragment textContent={element.text} />}
        </TextWrapper>
      </TextContainer>
    );
  }

  return (
    <TextContainer centered bulletList={bulletList}>
      {element.headline && <h1>{element.headline}</h1>}
      {element.text && (
        <RichTextGeneral fragment textContent={element.text} />
      )}
    </TextContainer>
  );
};

const MainContent: FC<any> = (props) =>
{
  const p = useContext(Context);
  const page = getPage(p);

  // @ts-ignore
  const mixedElements = page?.elements?.filter(
    (e) => e.__typename === "Asset" || e.__typename === "ContinuingPageGallery" || e.__typename === "PhotoGallery" || e.__typename === "TextElement"
  ) as any[];
  const connectedArticles = (page as any)?.connectedArticles;

  const TopComponent = props.topComponent;
  // @ts-ignore
  const photoGallery = mixedElements?.find(item => item.__typename === "PhotoGallery") as IPhotoGallery;

  const countByType = {
    1: 0,
    2: 0,
    3: 0,
    4: 0
  };

  return (
    <Section>
      <Container>

        {p?.PageMicro?.externalLinkAddress
          ? (
            <Micro />
          )
          : (
            <>
              <ContentContainer>
                {TopComponent && (<TopComponent />)}

                {mixedElements &&
                  mixedElements.map((e, i) =>
                  {
                    const assetElement = e.__typename === "Asset" && e as IAsset;
                    const textElement = e.__typename === "TextElement" && e as ITextElement;
                    const universlElement = (e.__typename === "ContinuingPageGallery") && e as IContinuingPageGallery;

                    if(textElement && textElement.icon?.length) 
                    {
                      countByType[textElement.icon?.length] += 1;
                    }

                    return (
                      <Fragment key={`mixedElements${i}`}>
                        <ContentWrapper>
                          {assetElement &&
                            <AssetElement element={assetElement} />
                          }
                          {textElement &&
                            <TextElement element={textElement} index={i} rtl={countByType[textElement.icon?.length] < 3}/>
                          }

                          {universlElement &&
                            <Container>
                              <UniversaElementWrapper>
                                <UniversalComponentsRenderer items={[universlElement]}>
                                  {({ item }) => <ProductItem item={item} />}
                                </UniversalComponentsRenderer>
                                <Spacer bronze={20} silver={30}></Spacer>
                              </UniversaElementWrapper>
                            </Container>
                          }
                        </ContentWrapper>
                      </Fragment>
                    );
                  })}
                <ContentWrapper>
                  {connectedArticles && (
                    <Container>
                      <Articles connectedArticles={connectedArticles} footnote={page?.footnote} />
                    </Container>
                  )}
                </ContentWrapper>
              </ContentContainer>
            </>
          )}
      </Container>

      {props.children}

      {photoGallery &&
        <ContentWrapper>
          <Container>
            <UniversaElementWrapper>
              <PhotoGallery item={photoGallery} />
              <Spacer bronze={20} silver={30}></Spacer>
            </UniversaElementWrapper>
          </Container>
        </ContentWrapper>
      }
    </Section>
  );
};

export default MainContent;
