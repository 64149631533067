import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const MicroWrapper: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 1050px;
  iframe {
    width: 100%;
    height: 100vh;
  }
  h1 {
    color: rgb(34, 34, 34);
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 33.6px;
    margin-bottom: 21px;
    width: 100%;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    margin: 42px auto 90px;
  }
`;
