import { css } from "styled-components";

export const wrapper: any = css`
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: ${props => props.theme.palette.bgLight};
    padding: 10px 10px 20px 10px;
`;

export const richText: any = css`
    h6 {
    margin-top: 21px;
    font-weight: 700;
    &:first-of-type {
    margin-top: 0;
    }
`;
