import styled, { css } from "styled-components";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const flexWrapper: any = css`
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 0;
  > div {
    width: 100%;
  }
  /* @media (${BREAKPOINTS_DM.silver_768}) { */
    > div {
      width: calc(50% - 14px);
      margin-right: 28px;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  /* } */
`;

export const ItemWrapper: any = styled.div<{ color: string; primary: string }>`
    padding: 15px 0 37px;
    h2 {
      color: ${({ color }) => (color ? color : "#222")};
      font-size: 16px;
      line-height: 1em;
    font-size: 16px;
    margin-bottom: 2em;
    }
    img{
      padding-bottom: 2rem;
      object-fit: cover;
    }
    }
    h4 {
      font-weight: 300 !important;
      margin-bottom: 25px;
      font-size: 14px;
    }
    h4, p, a {
      color: inherit;
    }
    p {
      font-family: open_sans_light,Helvetica,Arial,sans-serif;
      font-size: 14px;
      font-weight: 300;
      margin-bottom: 22px;
    }
    > a {
      font-family: open_sans_light,Helvetica,Arial,sans-serif;
      font-size: 12px;
      line-height: 2 !important;
      border-radius: 0;
      background: transparent;
      padding-right: 2em;
      padding-left: 2em;
      color: ${({ primary }) => primary || "black"};
      border: 1px ${({ primary }) => primary || "black"} solid;
      opacity: 1;
      display: inline-block;
      &:hover {
        opacity: .7;
      }
    }
`;

export const mainText: any = css<any>`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  font-size: 12px;
`;
