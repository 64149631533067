import styled, { css } from "styled-components";

export const Wrapper: any = styled.div`
  width: 100%;
  max-width: 700px;
  img{
    max-height: 500px;
    object-fit: contain;
  }
  .slick-cloned{
    padding-left: 2px;
  }
  .slick-next, .slick-prev {
    transform: scale(1.5);
    &:before {
      color: white;
    }
  }
  .slick-prev {
    z-index: 1;
    left: 10px;
  }
  .slick-next {
    right: 10px;
  }
`;

export const Figcaption: any = styled.figcaption`
  text-align: center;
  background-color: rgba(255,255,255,0.5);
  padding: 10px 10px;
`;