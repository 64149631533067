import React, { FC, useContext } from "react";
import { Context, DateFormatted, getPage, IPageCourse, ITextElement } from "src/common";
import { ICourse } from "src/common/childTypes";

import { RichTextGeneral } from "../../common/RichText";
import { Wrapper, CourseItemWrapper, CourseContentWrapper, CourseDateWrapper } from "./CourseContent.style";
import CourseForm from "./CourseForm";

const CourseContent: FC<{ course: ICourse; onSetCourse: (ICourse) => void }> = ({ course, onSetCourse }) => {
  const props = useContext(Context);
  const page = getPage(props) as IPageCourse;
  const courses = page?.courses;
  return (
    <Wrapper>
      { course
        ? <CourseForm course={course} onSetCourse={onSetCourse}/>
        : <>
          {courses?.map((item) => {
            const text = item.elements?.find(head => head?.__typename === "TextElement") as ITextElement;
            return (
              <CourseItemWrapper key={item.id}>
                <h3>{text.headline}</h3>
                <div>
                  <CourseContentWrapper>
                    <div data-content>
                      <RichTextGeneral textContent={text.text}>
                        {/* {text.icon &&
                          <img data-image src={text.icon[0]?.file[0]?.src} />
                        } */}
                      </RichTextGeneral>
                    </div>

                  </CourseContentWrapper>
                  <CourseDateWrapper>
                    <h3><DateFormatted date={item.startDate} /></h3>
                    <p>Beginn: <DateFormatted date={item.startDate} format="HH:mm"/> <span>Uhr</span></p>
                    <p>Ende: <DateFormatted date={item.endDate} format="HH:mm" />  <span>Uhr</span></p>
                    <p>Preis p.P.: <span>{item.pricePerParticipantInEuro} €</span></p>
                    <p>Freie Plätze: <span>{item.maxParticipants}</span></p>
                    <p>
                      Anmeldeschluss: <DateFormatted date={item.registrationDeadline} format="MM.DD.YYYY hh:mm" />
                    </p>

                    <a onClick={() => onSetCourse(item)}>Jetzt anmelden!</a>
                  </CourseDateWrapper>
                </div>
              </CourseItemWrapper>
            );
          })}
        </>
      }
    </Wrapper>
  );
};

export default CourseContent;
