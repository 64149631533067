import React, { FC } from "react";
import styled from "styled-components";

export const ItemDefaultWrapper: FC<{ isProduct?: boolean }> = styled.div<{ isProduct?: boolean }>`
  h2 {
    font-size: 18px;
    font-weight: 300;
    line-height: 1;
    margin-top: 20px;
    margin-bottom: 10.5px;
    text-align: left !important;
  }
`;

export const ImageWrapper: FC<{ isProduct?: boolean }> = styled.div<{ isProduct?: boolean }>`
  ${({ isProduct }) => isProduct && `
    height: 100%;
    background: white;

    img {
      object-fit: cover !important;
    }
  `}
`;

export const Description: FC<{ isProduct?: boolean }> = styled.div<{ isProduct?: boolean }>`
  background: ${({ theme }) => theme?.palette?.primary};
  padding: 5px;
  color: #FFF;
  font-size: 14px;
  line-height: 21px;
  > span {
    display: inline-block !important;
    margin-right: 5px;
    font-size: 14px;
    font-weight: 300;
  }
`;

export const Price: FC = styled.span`
  span {
  }
`;
