import styled, { css } from "styled-components";

import colors from "../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const flexWrapper: any = css`
    flex-wrap: wrap;
    width: 100%;
    display: flex;
    margin-right: 0;
    > div {
      width: 100%;
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        > div {
          width: calc(25% - 25px);
          margin-right: 25px;
        }
    }
`;

export const twoColumns_flexWrapper: any = css`
    > div {
      &:first-child {
        background-color: ${colors.gray};
        color: white;
        padding: 25px 10px;
        h3 {
          color: white;
        }
      }
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        display: flex;
        flex-direction: row;
        > div {
          width: calc(50% - 8px);
          &:first-child {
            width: 100%;
            margin-bottom: 30px;
            margin-left: 0 !important;
          }
        }
        > div:nth-child(even) {
          margin-right: 8px;
        }
        > div:nth-child(odd) {
          margin-left: 8px;
          margin-right: 0;
        }
    }
`;

export const ItemWrapper: any = styled.div<{color: string; primary: string}>`
    padding: 15px 0 37px;
    border-top: solid ${({ primary }) => primary ? primary : "#222"} 1px;
    h3 {
      color: ${({ color }) => color ? color : "#222"};
      font-weight: 600 !important;
      font-size: 16px;
    }
    h4 {
      font-weight: 300 !important;
      margin-bottom: 25px;
      font-size: 14px;
    }
    h4, p, a {
      color: inherit;
    }
    p {
      margin-bottom: 22px;
    }
    a {
      color: ${({primary}) => primary || 'black'};
      opacity: .7;
      &:hover {
        opacity: 1;
      }
    }
`;

export const mainText: any = css<any>`
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    font-size: 12px;
`;
