import React, { FC, useContext, useState } from "react";
import { Context, IAsset, getIfNewsShouldBeDisplayByDate, INews } from "src/common";

import { Col } from "../../../BaseComponents/Grid";
import NewsArticleItem from "./NewsArticleItem";
import { Wrapper, NavigationPage, SubItemsWrapper, SubItem, SubItemName, SubItemImg } from "./NewsContent.style";
 
const NewsContent: FC<any> = () => 
{
  const p = useContext(Context);
  const [currentPage, setCurrentPage] = useState<number>(1);

  if(!p.PageNews?.NewsArticles) 
  {
    return null;
  }

  const news = p.PageNews;
  const newsArticles = news?.NewsArticles?.filter((article) =>
    getIfNewsShouldBeDisplayByDate({ startDate: article?.startDate, endDate: article?.endDate })
  ).filter((article) => article !== null) || [];
  
  const newsCustomArticles = news?.NewsArticlesCustom?.filter((article) =>
    getIfNewsShouldBeDisplayByDate({ startDate: article?.startDate, endDate: article?.endDate })
  ).filter((article) => article !== null) || [];

  const articles = [...newsCustomArticles, ...newsArticles];

  const itemsPerPage = 10;

  const amountOfPages = Math.ceil(articles.length / itemsPerPage);
  const articlesToRender: INews[] = articles.slice((currentPage - 1) * itemsPerPage, itemsPerPage);
  const subItemsNavigation = p?.PrimaryNavigation?.items?.find(primaryItem => primaryItem?.subitems?.find(secondaryItem => secondaryItem?.slug === p.slug));
  return (
    <Wrapper>
      <Col md={12}>
        {/* <SubItemsWrapper>
          {subItemsNavigation && subItemsNavigation?.subitems?.map((subItem, ind) => (
            <SubItem key={`key-subitem-${ind}`}>
              <a href={subItem.slug}>
                <SubItemImg>
                  <span>Ohne Bild</span>
                </SubItemImg>
              </a>
              <SubItemName href={subItem.slug} isActive={subItem.slug === p.slug}>
                <span>{subItem.nameInNavigation}</span>
              </SubItemName>
            </SubItem>
          ))}
          {subItemsNavigation && subItemsNavigation?.subitems?.length % 3 !== 0 && (
            <SubItem/>
          )}
        </SubItemsWrapper> */}
        
        {articlesToRender && articlesToRender.map((article, index) => article && (
          <NewsArticleItem
            key={`newsArticleItem${index}`}
            index={index}
            headline={article?.headline}
            text={article?.text}
            images={
              (article?.extras?.filter(e => e?.__typename === "Asset") as
              | IAsset[]
              | undefined) ?? []
            }
          />
        ))}

        <NavigationPage>
          {currentPage < amountOfPages && (
            <a
              className="olderNews"
              onClick={() => setCurrentPage(currentPage + 1)}
              href={"#"}
            >
              <svg
                className="olderNewsSvg"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="#FFF"
              >
                <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
              </svg>
              <p>Ältere Nachrichten</p>
            </a>
          )}
          <a
            className="latestNews"
            onClick={() => setCurrentPage(currentPage - 1)}
            style={currentPage > 1 ? { visibility: "visible" } : { visibility: "hidden" }}
            href={"#"}
          >
            <p>Neuere Nachrichten </p>
            <svg
              className="latestNewsSvg"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="#FFF"
            >
              <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
            </svg>
          </a>
        </NavigationPage>
      </Col>
      
    </Wrapper>
  );
};

export default NewsContent;
