import React, { useState } from "react";
import styled from "styled-components";
import {
  FounderFormWrapper,
  SFounderForm,
  FounderFormContainer,
  FounderFormSuccessMessage,
  FounderFormErrorMessage,
  FounderFormItem,
  FounderFormLabel,
  FounderFormTextAreaWrapper,
  FounderFormTextArea,
  FounderFormCheckboxWrapper,
  FounderFormInput,
  FounderFormCheckbox,
  FounderFormButton,
  FounderLineBreak,
} from "./styles/FounderForm.style";
import { useLineShort } from "../../../../../common/hooks/useLineId";
import { FriendlyCaptcha } from "src/common/components/friendly-capture";

export const FounderForm: React.FC = () => {
  const [name, setName] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [privacy, setPrivacy] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const lineShort = useLineShort();

  const submitForm = (e) => {
    e.preventDefault();

    const fcValue = (document.querySelector(".frc-captcha-solution") as HTMLInputElement)?.value;

    if (!privacy) {
      alert("Bitte akzeptieren Sie die Datenschutzerklärung");
    }

    const formData = {
      name,
      zipcode,
      email,
      message,
      fcValue: !!fcValue ? fcValue : sessionStorage.getItem("fcaptcha"),
      // 'referrer': 'www.perfekte-kuechen.de,' @todo
    };

    let formBody: any = [];
    for (const property in formData) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(formData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }

    formBody.push("line_short=" + lineShort);
    formBody = formBody.join("&");

    fetch(`${process.env.NEXT_PUBLIC_GEDK_API_ENDPOINT}/api/v1/headpage/founderform`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          setError(true);
        } else {
          setSuccess(true);
        }
      });
  };
  return (
    <FounderFormWrapper>
      {success && (
        <FounderFormSuccessMessage>
          Ihre Nachricht wurde erfolgreich versendet, vielen Dank.
        </FounderFormSuccessMessage>
      )}
      {error && (
        <FounderFormErrorMessage>
          Es ist ein Fehler aufgetreten. Bitte prüfen Sie Ihre Eingaben.
        </FounderFormErrorMessage>
      )}
      {!success && (
        <SFounderForm className="gruender-form" onSubmit={submitForm}>
          <FounderFormContainer>
            <FounderFormItem>
              <FounderFormLabel>Name</FounderFormLabel>
              <FounderFormInput
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required={true}
              />
            </FounderFormItem>
            <FounderFormItem>
              <FounderFormLabel>E-Mail</FounderFormLabel>
              <FounderFormInput
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required={true}
              />
            </FounderFormItem>
            <FounderFormItem>
              <FounderFormLabel>PLZ</FounderFormLabel>
              <FounderFormInput
                type="text"
                name="plz"
                value={zipcode}
                onChange={(e) => setZipcode(e.target.value)}
                required={true}
              />
            </FounderFormItem>
            <br />
            <FounderFormTextAreaWrapper>
              <FounderFormLabel>Nachricht</FounderFormLabel>
              <FounderFormTextArea
                name="nachricht"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required={true}
              />
            </FounderFormTextAreaWrapper>
            <FounderFormCheckboxWrapper>
              <FounderFormLabel htmlFor={"privacy"}>
                <FounderFormCheckbox
                  id={"privacy"}
                  type="checkbox"
                  name="privacy"
                  value="1"
                  onChange={(e) => setPrivacy(e.target.checked)}
                  required={true}
                />
                {"  "} Ich akzeptiere die <a href="/imprint-datenschutz#impressum">Datenschutzerklärung</a>
              </FounderFormLabel>
              <FriendlyCaptcha />
            </FounderFormCheckboxWrapper>
            <FounderFormItem>
              <FounderFormButton type={"submit"}>Absenden</FounderFormButton>
            </FounderFormItem>
          </FounderFormContainer>
        </SFounderForm>
      )}
    </FounderFormWrapper>
  );
};
