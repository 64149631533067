import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const Wrapper: any = styled.div`
  padding: 0 90px 0 0;
  h1 {
    margin: 0;
    span {
      font-size: 34px;

    }
  }

  h6 {
    margin-top: 21px;
    font-weight: 700;
    &:first-of-type {
      margin-top: 0;
    }
  }

  p > span > strong {
    font-weight: 300;
    margin-left: -15px;
  }

  li, ul{
    display: list-item;
    list-style: inside;
  }
  ul {
    list-style: disc;
  }
  li {
    span {
      height: 21px;
      font-weight: 300;
      strong {
        font-weight: 300;
      }
    }
    line-height: 21px;
  }
  p.c-empty {
    margin: 0;
    min-height: 0;
  }
  p > a {
    background-color: #85b2c3;
    line-height: 45px;
    color: #fff;
    text-decoration: none;
    text-align: center;
    span {
      color: #fff;
    }
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    padding: 0 0 0 44px;
  }
`;

