import React, { FunctionComponent, Fragment, useContext } from "react";
import { Context, getPage, ITextElement, ISidebarText, IAsset, Img, IPhotoGallery } from "src/common";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";
import UniversalComponentsRenderer from "src/common/BaseComponents/UniversalCompoentRenderer/UniversalComponentsRenderer";
import PhotoGallery from "src/common/components/PhotoGalleryVisualization";
import { css } from "styled-components";

import Articles from "../../common/Articles";
import Footer from "../../common/Footer";
import Micro from "../../common/Micro/Micro";
import ProductItem from "../../common/ProductItem";
import { RichTextGeneral, SRichTextGeneral } from "../../common/RichText";
import Hero from "./Hero";
import * as styles from "./MainContent.style";
import {
  ContentContainer,
  ContentWrapper,
  HeroWrapper,
  UniversaElementWrapper,
  ElementWrapper,
  TextElementWrapper,
  SubNavigationWrapper,
  SubNavigationItem,
  PhotoGalleryWrapper,
  MainContainer
} from "./MainContent.style";

const MainContent: FunctionComponent<any> = (props) =>
{
  const p = useContext(Context);
  const page = getPage(p);

  const sideBarText = page?.extras?.find((e) => e?.__typename === "SidebarText") as ISidebarText;

  // @ts-ignore
  const mixedElements = page?.elements?.filter(
    (e) => e?.__typename === "ContinuingPageGallery" || e?.__typename === "PhotoGallery" || e?.__typename === "TextElement" || e?.__typename === "Asset"
  ) as any[];
  const connectedArticles = (page as any)?.connectedArticles;
  const navItem = p?.PrimaryNavigation?.items?.find((i) => i?.link?.[0]?.slug === p.slug);
  const TopComponent = props.topComponent;
  const wihthPhotoGallery = mixedElements?.find(element => element?.__typename === "PhotoGallery");
  const withUniversalElement = mixedElements?.find(element => element?.__typename === "ContinuingPageGallery");

  return (
    <MainContainer>
      <ContentContainer>
        <ContentWrapper>
          <HeroWrapper topComponent={TopComponent && true}>
            <Hero topComponent={TopComponent}/>
          </HeroWrapper>

          {props?.micro && <Micro />}

          {mixedElements &&
            mixedElements.map((e, i) =>
            {
              const textElement = e?.__typename === "TextElement" && e as ITextElement;
              const imageElement = TopComponent && e?.__typename === "Asset" && e as IAsset;
              const universlElement = (e?.__typename === "ContinuingPageGallery") && e as ITextElement;
              const photoGallery = e?.__typename === "PhotoGallery" && e as IPhotoGallery;
              return (
                <ElementWrapper key={`mixedElements${i}`} >
                  {imageElement &&
                    <div css={css`${SRichTextGeneral}${styles.imageInText}` as any}>
                      <Img
                        alt={imageElement?.title}
                        src={[imageElement]}
                        quality={1080}
                      />
                    </div>
                  }
                  {textElement &&
                    <TextElementWrapper withUniversalElement={withUniversalElement && true}>
                      <h1 className="title">{textElement?.headline}</h1>
                      <div css={css`${SRichTextGeneral}${styles.imageInText}` as any}
                      >
                        {textElement?.icon?.map((i, index) =>
                        {
                          if(i?.src)
                          {
                            return (
                              <Fragment key={`i${index}`}>
                                <Img
                                  quality={1020}
                                  src={[i]}
                                  contain
                                  alt={i.title}
                                >{i.title && <p css={styles.figcaption}>{i.title}</p>}</Img>
                              </Fragment>
                            );
                          }
                          return null;
                        })}
                        {textElement?.text && <RichTextGeneral fragment textContent={textElement?.text} />}
                      </div>
                    </TextElementWrapper>
                  }

                  {universlElement &&
                    <UniversaElementWrapper>
                      <UniversalComponentsRenderer items={[universlElement]}>
                        {({ item }) => <ProductItem item={item} />}
                      </UniversalComponentsRenderer>
                      <Spacer bronze={20} silver={30}></Spacer>
                    </UniversaElementWrapper>
                  }

                  {photoGallery &&
                    <PhotoGalleryWrapper visualization={photoGallery?.visualization}>
                      <PhotoGallery item={photoGallery} columns={photoGallery?.maxCountPerRow} />
                      <Spacer bronze={20} silver={30}></Spacer>
                    </PhotoGalleryWrapper>
                  }
                </ElementWrapper>
              );
            })}
          {navItem && !wihthPhotoGallery && !props.news &&
            (
              <SubNavigationWrapper>
                {
                  navItem.subitems?.map((subNav, index) =>
                    (
                      <>
                        {subNav?.nameInNavigation && (
                          <SubNavigationItem key={`SubNavigationWrapper-${index}`}>
                            <a href={`/${subNav?.slug}`}>{subNav?.nameInNavigation}</a>
                          </SubNavigationItem>
                        )}
                      </>
                    )
                )}
              </SubNavigationWrapper>
            )
          }
          <Articles connectedArticles={connectedArticles} footnote={page?.footnote} />
        </ContentWrapper>
      </ContentContainer>
      {props.children}
      {props.withFooter && <Footer withSocial={!wihthPhotoGallery}/>}
    </MainContainer>
  );
};

export default MainContent;
