import styled, { css } from "styled-components";

export const Wrapper: any = styled.div`
  max-width: 1100px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 40px;
`;

export const NavigationPage: any = styled.nav`
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
  margin: 12px 0px 60px 0px;

  svg.olderNewsSvg {
    transform: rotate(90deg);
    padding: 2px 0px 0px 8px;
    margin-right: 10px;
  }

  svg.latestNewsSvg {
    transform: rotate(270deg);
    padding: 2px 8px 0px 0px;
    margin-left: 10px;
  }

  a.olderNews {
    padding: 0px 10px 6px 4px;
  }

  a.latestNews {
    padding: 0px 6px 6px 10px;
  }

  a {
    background-color: ${({ theme }) => theme.palette.light_blue};
    color: white;
    text-align: center;
    border-radius: 4px;
    display: flex;

    > p {
      margin: 6px 0 0;
      color: #fff;
    }
  }
  @media (max-width: 500px) {
    a {
      padding: 4px 10px !important;
    }
  }
`;

const flexItem: any = css`
  width: calc(50% - 13.5px);
  flex-grow: 0;
  flex-shrink: 1;
  margin-right: 13.5px;
  margin-bottom: 13.5px;
  padding: 27px;
`;
