import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const MicroWrapper: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1400px;
  iframe {
    width: 100%;
    height: 100vh;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: calc(100% - 40px);
    margin: 57.6px auto 90px;
  }
`;
