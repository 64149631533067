import React, { FC, useContext } from "react";
import { IAsset, INews, Context } from "src/common";

import FullscreenImage from "../../../common/FullscreenImage";
import NewSlider from "../../../common/NewSlider";
import { RichText } from "../../../common/RichText";
import { Container } from "../NewsContent.style";
import {
  Wrapper,
  ContentContainer,
  HeadingWrapper,
  NavigationContainer,
  TextWrapper,
  ImageContainer,
  ImageWrapper,
  ContactWrapper,
} from "./NewsArticleItem.style";
export interface IProps {
  headline: string;
  text: any;
  index: number;
  images: IAsset[];
  currentArticlesToRender?: INews[];
  endPage?: number;
  startPage?: number;
  itemWidth?: string;
  date?: string;
}

const NewsArticle: FC<IProps> = ({
  headline,
  text,
  images,
  currentArticlesToRender,
  date,
}) => {
  const props = useContext(Context);
  if (!headline || !text) {
    return null;
  }

  return (
    <Container>
      <Wrapper>
        <ContentContainer>
          <HeadingWrapper marginTop="200px">
            <h3 className="headline">{headline}</h3>
            <p className="date">Meldung vom {date}</p>
          </HeadingWrapper>

          <ImageContainer>
            {images?.map((asset: IAsset, i: number) => (
              <ImageWrapper key={`${i}${asset?.src}`}>
                {asset && (
                  <>
                    <FullscreenImage
                      img={[asset]}
                      subtitle={asset?.description}
                    />
                    {asset?.description && <p>{asset?.description}</p>}
                  </>
                )}
              </ImageWrapper>
            ))}
          </ImageContainer>

          <TextWrapper>
            <RichText textContent={text} />
          </TextWrapper>

          <NavigationContainer>
            <a
              href={`/${props.NewsSlug || props.PageNews?.slug || ""}`}
              title="Zurück"
            >
              Zurück
            </a>
          </NavigationContainer>
        </ContentContainer>
      </Wrapper>
      {currentArticlesToRender && (
        <NewSlider articles={currentArticlesToRender} />
      )}
      <ContactWrapper>
        <div className="text-container">
          <h3 className="text">
            Mit ausreichend Parkplätzen in machbarer Wanderentfernung vom
            Geschäft
          </h3>
        </div>
        <div className="button-container">
          <a className="button" href={`/${props?.PageContact?.slug}`}>
            So kommen Sie zu uns
          </a>
        </div>
      </ContactWrapper>
    </Container>
  );
};

export default NewsArticle;
