import React, { FunctionComponent } from "react";
import Slider, { Settings } from "react-slick";
import SSlickBase from "../../../utils/slick/SSlickBase";
import SSlickTheme from "../../../utils/slick/SSlickTheme";
import * as styles from "./ImgGallery.style";
import Img from "../../../BaseComponents/Img/Img";

export interface aImage {
    src: string;
    subtitle: string;
}
interface IProps {
    images: aImage[];
}

export const ImgGallery: FunctionComponent<IProps> = props => {
    const sliderSettings: Settings = {
        dots: false,
        infinite: true,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
    };
    return (
        <div css={`
            ${SSlickBase}
            ${SSlickTheme}
            ${styles.wrapper}
        `}>
            <Slider {...sliderSettings}>
                {props?.images?.map((image, i) => (
                    <Img css={styles.imgSlide} key={i} quality={1920} src={image.src} alt={image.subtitle || "Küchen Bild"} />
                ))}
            </Slider>
        </div>
    );
};
