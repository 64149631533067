import styled, { css } from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Wrapper: any = styled.div`
  h3 {
    font-size: 15px;
    color: ${props => props.theme.palette.primary};
    font-weight: 700;
    margin-bottom: 10px;
  }

  img {
     width: 100%;
    height: auto;
    @media (${BREAKPOINTS_DM.silver_768})
    {
        height: auto;
    }
  }
`;

export const Footnote: any = styled.div`
    margin: 40px 0 20px 0;
`;

export const ImgAndHeadline: any = css`
    display: flex;
    flex-direction: column;
    @media (${BREAKPOINTS_DM.silver_768})
    {
        flex-direction: row;
        align-items: center;
    }
`;
