import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const Wrapper: any = styled.div<{ isFounderPage: boolean }>`
  width: 100%;
  padding: 10px 10px 10px 30px;
  margin: 50px 0 20px;
  background-color: #585858;

  h6 {
    margin-top: 21px;
    font-weight: 700;
    &:first-of-type {
      margin-top: 0;
    }
  }

  p > span > strong {
    font-weight: 300;
    margin-left: -15px;
  }

  li,
  ul {
    display: list-item;
    list-style: inside;
  }
  ul {
    list-style: disc;
  }
  li {
    span {
      height: 21px;
      font-weight: 300;
      strong {
        font-weight: 300;
      }
    }
    line-height: 21px;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 105%;
  }

  ${({ isFounderPage }) =>
    isFounderPage &&
    css`
      background-color: transparent;

      > div > p {
        font-size: 14px;
        line-height: 25px;

        &:first-child {
          color: #76b729;
          font-weight: 700;
        }
      }
    `}
`;
