import React, { FC, useContext } from "react";
import { Context, getPage, IAsset, Img } from "src/common";

import Gallery from "../../../common/Gallery";
import { Title, HeroWrapper } from "./Hero.style";

const Hero: FC<any> = (props) => 
{
  const p = useContext(Context);
  const page = getPage(p);

  // @ts-ignore
  const imgArray = page?.elements?.filter((e) => e.__typename === "Asset") as IAsset[];
  // @ts-ignore
  const slider = page?.elements?.filter((e) => e.__typename === "Slider") as any[];
  // @ts-ignore
  const title = page?.elements.find(e => e.__typename === "TextElement")?.headline;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const showSlider = slider && (slider.length > 0 || slider?.[0]?.elements.length > 0);
  let imageOrGallery;
  if(imgArray?.[0] || showSlider) 
  {
    if(imgArray.length > 1 || showSlider) 
    {
      if(slider) 
      {
        imageOrGallery = <Gallery imgArray={slider?.[0]?.elements} />;
      }
      else 
      {
        imageOrGallery = <Gallery imgArray={imgArray} />;
      }
    }
    else 
    {
      imageOrGallery = (
        <Img
          alt={imgArray?.[0]?.description}
          src={imgArray}
        />
      );
    }
  }

  const TopComponent = props.topComponent;
  return (
    <>
      {TopComponent ? (
        <div style={{ marginBottom: "20px" }}>
          <TopComponent />
        </div>
      ) : (
        <>
          <HeroWrapper>
            {imageOrGallery && imageOrGallery}
            <Title><h1>{title}</h1></Title>
          </HeroWrapper>
        </>
      )}
    </>
  );
};

export default Hero;
