import React, { FC } from "react";
import { IContext, Context } from "src/common";

import { Wrapper } from "./SubNavRight.style";

const SubNavRight: FC = () => {
  const props = React.useContext<IContext>(Context);
  const navItem = props?.PrimaryNavigation?.items
    ?.find(
      i =>
        !!i?.subitems?.find(s => s?.slug === props.slug) ||
        i?.link?.[0]?.slug === props.slug
    )
    ?.subitems;

  if (!navItem) return null;
  return navItem
    && navItem.length >= 1
    && (
      <Wrapper>
        {navItem.map((item, i) => {
          return (
            <div key={`${i}${item?.slug}`}>
              <a
                href={`/${item?.slug}`}
                className={`${item?.slug === props?.slug ? "active" : ""}`}
              >
                {item?.nameInNavigation || ""}
              </a>
            </div>
          );
        })}
      </Wrapper>
    );
};

export default SubNavRight;
