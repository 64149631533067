import React, { Fragment, FC, useState } from "react";
import { IAsset } from "src/common";

import FullscreenImage from "../../../common/FullscreenImage";
import { RichText } from "../../../common/RichText";
import * as styles from "./NewsArticleItem.style";
import { Wrapper, HeadingWrapper, TitleWrapper } from "./NewsArticleItem.style";

interface IProps 
{
  headline: string;
  text: any;
  index: number;
  images: IAsset[];
}

const NewsArticleItem: FC<IProps> = props => 
{
  const [isActive, setIsActive] = useState(props?.index === 0);
  if(!props.headline || !props?.text) 
  {
    return null;
  }

  return (
    <Wrapper>
      <HeadingWrapper onClick={() => setIsActive(!isActive)}>
        <TitleWrapper>
          {!isActive &&
          <div className="icon-down">
            <i className="fa fa-caret-down"/>
          </div>
          }
          <h3>{props.headline}</h3>
        </TitleWrapper>
        <p>{props.text.content?.[0]?.children?.[0]?.text || ""}</p>
      </HeadingWrapper>

      <div css={`${styles.imgAndText} ${isActive ? styles.isActive : styles.isNotActive}`}>
        <RichText
          css={isActive ? styles.isActive : styles.isNotActive}
          textContent={props?.text}/>
        <div css={styles.imgAndSubtitleWrapper}>
          {props.images?.map((asset: IAsset, i: number) => 
            (
              <Fragment key={`${i}${asset?.src}`}>
                {asset && (
                  <>
                    <FullscreenImage
                      img={[asset]}
                      subtitle={asset.title}
                    />
                    {asset.title && (
                      <p css={styles.subTitle}>
                        {asset.title}
                      </p>
                    )}
                  </>
                )}
              </Fragment>
            ))}
        </div>
        
      </div>

      <div
        css={`${isActive? styles.isActive : styles.isNotActive}`}
        onClick={() => setIsActive(!isActive)}>
        <div style={{ display: "flex" }}>
          <p>SCHLIESSEN</p>
          <div className="icon-up">
            <i className="fa fa-caret-up"/>
          </div>
        </div>
        
      </div>
    </Wrapper>
  );
};

export default NewsArticleItem;
