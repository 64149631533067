import styled, { css } from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Wrapper: any = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    width: 50%;
    padding: 10px;

    h3 {
      font-size: 18px;
      color: #222;
      font-weight: 400;
      margin: 5px 0 10px 0;
    }

    img {
      width: 100%;
      height: auto;
      margin-bottom: 13px;

      @media (${BREAKPOINTS_DM.silver_768})
      {
          height: 248px;
      }
    }
  }
`;

export const Footnote: any = styled.div`
    margin: 40px 0 20px 0;
`;

export const ImgAndHeadline: any = css`
    display: flex;
    flex-direction: column;
    @media (${BREAKPOINTS_DM.silver_768})
    {
        flex-direction: row;
        align-items: center;
    }
`;
