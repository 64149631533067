import React, { FC } from "react";
import { getIfNewsShouldBeDisplayByDate, Img, IProduct } from "src/common";

import { RichTextGeneral } from "../../common/RichText";
import { ItemDefaultWrapper, ImageWrapper, Description, Price, NormalPrice, Button } from "./ItemDefault.style";

const ProductItemT21: FC<{ item: any }> = ({ item }) => 
{
  if(!item) 
  {
    return null;
  }

  const product = (item?.link?.[0]) as IProduct;
  const isProduct = product?.__typename.includes("Product");
  const link = item?.link?.[0] as any;
  const price = link?.ourPrice;
  const slug = link?.slug;
  const text = link?.text;
  if(isProduct && product?.acceptAGB && !getIfNewsShouldBeDisplayByDate({ startDate: product?.startDate, endDate: product?.endDate })) 
  {
    return null;
  }

  return (
    <ItemDefaultWrapper isProduct={isProduct}>
      <ImageWrapper isProduct={isProduct}>
        <Img src={item.thumbnail}/>
      </ImageWrapper>
      {isProduct && (
        <Description>
          <h2 className="title">{item.title}</h2>
          <RichTextGeneral fragment textContent={text}/>
          <NormalPrice>
            <span>Ehemaliger eigener Verkaufspreis</span>
            <span>€</span>
          </NormalPrice>
          <span className="price">Jetzt für nur</span>
          <Price>
            {price && <span className="price">{price} €</span>}
          </Price>
          <Button href={`/${isProduct ? "produkt/" : ""}${slug}`}>
            <span>zum Angebot</span>
          </Button>
        </Description>
      )}
    </ItemDefaultWrapper>
  );
};

export default ProductItemT21;
