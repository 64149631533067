import React, { FC } from "react";
import styled from "styled-components";

export const ItemDefaultWrapper: FC<{ isProduct?: boolean }> = styled.div<{ isProduct?: boolean }>`
  h1 {
    coor: red;
  }
  a > h2 {
    font-size: 24px;
    line-height: 42px;
    margin-bottom: 10.5px;
    font-weight: 300;
    letter-spacing: .02em;
    color: #FA8800;
}
  }
`;

export const ImageWrapper: FC<{ isProduct?: boolean }> = styled.div<{ isProduct?: boolean }>`
  ${({ isProduct }) => isProduct && `
    height: 100%;
    background: white;

    img {
      object-fit: contain !important;
    }
  `}
`;

export const Description: FC<{ isProduct?: boolean }> = styled.div<{ isProduct?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FA8800;
  padding: 10.5px;
  color: #F8F8F8;
  font-size: 15px;
  line-height: 21px;
  div {
    font-weight: 300;
  }
  svg {
    vertical-align: middle;
    height: 15px;
    fill: #F8F8F8;
    width: 15px;
  }
  div.button {
    display: flex;
    align-items: center;
    border: 1px solid #fff;
    font-size: 12px;
    padding: 5px;
    background-color: #FFA02E;
    span {
      padding-left: 5px;
    }
    &:hover {
      span {
        color: #000;
      }
    }
  }
`;

export const Price: FC = styled.div`
  justify-content: space-between;
  span {
    font-weight: 300;
  }
`;
