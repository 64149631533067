import React, { FunctionComponent, Fragment, useContext } from "react";
import { Context, getPage, ITextElement, ISidebarText, IAsset, Img, IPhotoGallery } from "src/common";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";
import UniversalComponentsRenderer from "src/common/BaseComponents/UniversalCompoentRenderer/UniversalComponentsRenderer";
import PhotoGallery from "src/common/components/PhotoGalleryVisualization";
import { css } from "styled-components";

import Articles from "../../common/Articles";
import Micro from "../../common/Micro";
import ProductItem from "../../common/ProductItem";
import { RichTextGeneral, SRichTextGeneral } from "../../common/RichText";
import SubNavRight from "../../common/SubNavRight";
import TextBoxRight from "../../common/TextBoxRight";
import Hero from "./Hero";
import * as styles from "./MainContent.style";
import { ContentContainer, ContentWrapper, UniversaElementWrapper, ElementWrapper, ImgWrapper } from "./MainContent.style";

const MainContent: FunctionComponent<any> = (props) =>
{
  const p = useContext(Context);
  const page = getPage(p);

  const sideBarText = page?.extras?.find((e) => e?.__typename === "SidebarText") as ISidebarText;

  // @ts-ignore
  const mixedElements = page?.elements?.filter(
    (e) => e?.__typename === "ContinuingPageGallery" || e?.__typename === "PhotoGallery" || e?.__typename === "TextElement" || e?.__typename === "Asset"
  ) as any[];
  const connectedArticles = (page as any)?.connectedArticles;
  const navItem = p?.PrimaryNavigation?.items?.find(
    (i) => !!i?.subitems?.find((s) => s?.slug === p.slug) || i?.link?.[0]?.slug === p.slug
  );
  const TopComponent = props.topComponent;
  // @ts-ignore
  const mainHeader = page?.elements?.find(head => head?.__typename === "TextElement") as ITextElement;
  return (
    <>
      <ContentContainer>
        <ContentWrapper pageBrand={props.pageBrand}>
          <Hero topComponent={TopComponent} pageBrand={props.pageBrand}/>
          {props?.micro && <Micro />}
          {mainHeader &&
            <>
              <h1 className="title_main_head"><img src="/images/doubble-arrow--right--positive_t17.png" style={{ margin: "0 5px 5px" }}/>{mainHeader?.headline}</h1>
              <div css={css`${SRichTextGeneral}${styles.imageInText}` as any}
              >
                {mainHeader?.icon?.map((i, index) =>
                {
                  if(i.src)
                  {
                    return (
                      <Fragment key={`i${index}`}>
                        <Img
                          quality={1020}
                          src={[i]}
                          contain
                          alt={i.title}
                        >{i.title && <p css={styles.figcaption}>{i.title}</p>}</Img>
                      </Fragment>
                    );
                  }
                  return null;
                })}
                {mainHeader?.text && <RichTextGeneral fragment textContent={mainHeader?.text} />}
              </div>

            </>
          }

          {mixedElements &&
            mixedElements.map((e, i) =>
            {
              const textElement = e?.__typename === "TextElement" && e as ITextElement;
              const imageElement = TopComponent && e?.__typename === "Asset" && e as IAsset;
              const universlElement = (e?.__typename === "ContinuingPageGallery") && e as ITextElement;
              const photoGallery = e?.__typename === "PhotoGallery" && e as IPhotoGallery;
              if(mainHeader === textElement) { return; }
              return (
                <ElementWrapper key={`mixedElements${i}`} >
                  {imageElement &&
                    <div css={css`${SRichTextGeneral}${styles.imageInText}` as any}>
                      <Img
                        alt={imageElement?.title}
                        src={[imageElement]}
                        quality={1080}
                      />
                    </div>
                  }
                  {textElement &&
                    <>
                      <h1 className="title">{textElement?.headline}</h1>
                      <div css={css`${SRichTextGeneral}${styles.imageInText}` as any}
                      >
                        {textElement?.icon?.map((i, index) =>
                          (
                            <div key={`aai${index}`}>
                              <ImgWrapper key={`i${index}`}>
                                <Img
                                  quality={1020}
                                  src={[i]}
                                  contain
                                  alt={i.title}
                                >{i.title && <p css={styles.figcaption}>{i.title}</p>}</Img>
                              </ImgWrapper>
                            </div>)
                        )}
                        {textElement?.text && <RichTextGeneral fragment textContent={textElement?.text} />}
                      </div>
                    </>
                  }

                  {universlElement &&
                    <UniversaElementWrapper>
                      <UniversalComponentsRenderer items={[universlElement]}>
                        {({ item }) => <ProductItem item={item} />}
                      </UniversalComponentsRenderer>
                      <Spacer bronze={20} silver={30}></Spacer>
                    </UniversaElementWrapper>
                  }

                  {photoGallery &&
                    <UniversaElementWrapper>
                      <PhotoGallery item={photoGallery} />
                      <Spacer bronze={20} silver={30}></Spacer>
                    </UniversaElementWrapper>
                  }
                </ElementWrapper>
              );
            })}
          <Articles connectedArticles={connectedArticles} footnote={page?.footnote} />
        </ContentWrapper>

        {(navItem || sideBarText) && !props?.micro && (
          <div css={styles.sideBarWrapper}>
            <SubNavRight />
            <TextBoxRight text={sideBarText?.text} />
          </div>
        )}
      </ContentContainer>
      {props.children}
    </>
  );
};

export default MainContent;
