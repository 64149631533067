import styled from "styled-components";

export const Wrapper: any = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 10px;

  h6,
  h5
  h4,
  h3,
  p,
  ul {
    margin-bottom: 21px;
  }
  h6 {
    font-weight: 700;
    &:first-of-type {
      margin-top: 0;
    }
  }
  ul {
    margin-left: 21px;
  }
  p {
    color: #f9b000;
    font-size: 14px;
    hyphens: none;
  }
`;

