import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const ContentContainer: any = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.maxWidth}px;
  width: 100%;
  margin: 0px auto;
  padding: 0 10px;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    flex-direction: row;
    padding: 0 60px;
  }
  .c-frame {
    iframe {
      width: 280%;
      height: 364px;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    &,
    span {
      color: #fff !important;
    }
  }

  h1,
  h2,
  h3 {
    &,
    span {
      font-weight: 300;
    }
  }

  h1 {
    padding: 2px 10px 2px 0p;
    &,
    span {
      color: #efefef;
      font-weight: 100;
      font-size: 34px;
      line-height: 38px;
    }
  }
  a > span {
    color: #fff;
  }
  p {
    margin-bottom: 21px;
    span {
      font-size: 15px;
      line-height: 21px;
      font-weight: 300;
      letter-spacing: 0.4px;
    }
  }
  strong {
    color: #fff;
    font-weight: 300;
  }
  span {
    color: #fff;
    font-size: 15px;
    line-height: 21px;
    font-weight: 300;
    letter-spacing: 0.4px;
  }

  p:has(+ ul) {
    margin-bottom: 0;
  }
`;

export const ContentWrapper: any = styled.div<{ pageBrand: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ pageBrand }) => pageBrand && "0 18px 0 0"};

  @media (${BREAKPOINTS_DM.gold_1024}) {
    padding-left: 20px;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 0;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 15px;
    li {
      list-style-type: disc;
      span {
        color: #fff;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.4px;
        font-weight: 300;
      }
    }
  }
`;

export const SideLeftWrapper: any = styled.div`
  width: 100%;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    max-width: 229.25px;
  }
`;

export const ImageInText: any = styled.div<{ column: boolean }>`
  > h3 {
    font-size: 21px;
    line-height: 21px;
    margin: 0;
  }

  ${({ column }) =>
    column
      ? css`
          display: flex;
          flex-direction: column;
        `
      : css`
          display: flex;
          flex-direction: column;
          @media (${BREAKPOINTS_DM.gold_1024}) {
            flex-direction: row;
          }
        `}
`;

export const ImageWRapper: any = styled.div<{
  column: boolean;
  isLink: boolean;
}>`
  ${({ column }) =>
    column
      ? css`
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        `
      : css`
          width: 100%;
          @media (${BREAKPOINTS_DM.gold_1024}) {
            width: 263px;
            padding-right: 20px;
          }
        `}

  img {
    > img {
      height: auto;
    }
    ${({ column }) =>
      column &&
      css`
        padding: 10px 0;
      `};
    max-width: 100%;
    min-height: ${({ isLink }) => (isLink ? "70px" : "143px")};
  }
`;

export const ImagesContainer: any = styled.div``;

export const figcaption: any = css`
  padding: 10.5px;
  font-size: 13px;
  font-weight: 300;
`;

export const UniversaElementWrapper: any = styled.div<{ zoomeffect: boolean }>`
  margin-top: 0px;
  width: 100%;
  /* max-width: 750.75px; */
  padding-left: 25px;
  padding-right: 25px;
  .slick-prev,
  .slick-next {
    z-index: 9;
    background-color: rgba(255, 255, 255, 0.5) !important;
    width: 30px !important;
    height: 30px !important;
    :before {
      opacity: 1;
      display: inline-block;
      content: "" !important;
      background-image: url(/images/sprite-sa3ca3ca63e.png);
      background-repeat: no-repeat;
      background-position: 0 -172px;
      height: 18px;
      width: 12px;
      color: transparent !important;
    }

    :hover {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }

  .slick-next {
    right: 0 !important;
  }

  .slick-prev {
    left: 0 !important;
    :before {
      transform: rotate(180deg);
    }
  }

  .slick-slide {
    img {
      max-height: 443.98px;
    }
    padding-top: 6px;
    &:not(.slick-current) {
      opacity: 0.5;
    }
  }

  [data-gallery-slider-tracker] {
    img {
      padding-right: 5px;
      max-height: 73px;
    }
  }

  [data-caption] {
    color: #fff;
  }
  ${({ zoomeffect }) =>
    zoomeffect &&
    css`
      [data-aos="zoom-in"] {
        width: 100%;
      }

      [data-gallery] {
        div {
          flex-wrap: wrap;
        }
      }
    `}

  div.caption-description {
    background-color: rgba(255, 255, 255, 0.5);
    color: #fff;
    font-size: 15px;
    font-weight: 300;
    line-height: 21px;
    padding: 10.5px;
  }
`;

export const ElementWrapper: any = styled.section<{ isLastElement: boolean }>`
  margin-bottom: 22px;

  ${({ isLastElement }) =>
    isLastElement &&
    css`
      &:last-child {
        padding: 0 30px;
        border: 2px solid white;
        margin-bottom: 40px;
        gap: 30px;

        @media (${BREAKPOINTS_DM.silver_768}) {
          padding: 0;
        }

        > h1 {
          margin: 0;
        }

        > div {
          &:nth-child(1) {
            order: -1;
            padding: 0;
            width: 100%;

            img {
              width: 100%;
            }

            @media (${BREAKPOINTS_DM.silver_768}) {
              width: 35%;
            }
          }

          &:nth-child(2) {
            width: 100%;
            padding: 40px 20px 40px 0;
            border: none;

            > h1 {
              font-size: 21px;
              line-height: 30px;
              letter-spacing: 4px;
              margin: 0;
            }

            > div {
              > div {
                width: 100%;
                > p {
                  margin: 0;
                  font-size: 14px;
                  line-height: 25px;

                  :first-of-type {
                    padding-bottom: 30px;
                  }

                  :not(:first-of-type) {
                    color: #d7e8c9;
                    strong {
                      font-weight: bold;
                      color: #fff;
                    }

                    &:nth-child(even) {
                      margin-bottom: 10px;
                    }

                    br {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: flex;
  }
`;

export const BorderBottom: any = styled.div`
  width: 100%;
  margin: 20px auto 0;
  max-width: 980px;
  border-bottom: 2px dotted #fff;
`;
export const Title: any = styled.h1<{ isFirst: boolean; isLink: boolean }>`
  color: #efefef;
  font-weight: 100;
  letter-spacing: 0.4px;
  ${({ isFirst }) =>
    isFirst
      ? css`
          margin: 0 0 15px;
          font-size: 20px;
          line-height: 20px;
        `
      : css`
          margin: 0 0 21px;
          font-size: 34px;
          line-height: 38px;
        `}
  ${({ isLink }) =>
    isLink &&
    css`
      margin: 0;
    `};
`;

export const ImageContent: any = styled.div<{
  column: boolean;
  isLink: boolean;
}>`
  height: auto;
  ${({ column }) =>
    column
      ? css`
          background: transparent;
          max-width: 31.5%;
        `
      : css`
          background: #706f6f;
          max-width: 100%;
        `}

  ${({ isLink }) =>
    isLink &&
    css`
      background: transparent;
      max-width: 60%;
    `}
  div > div {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const TextWrapper: any = styled.div<{
  column: boolean;
  isLink: boolean;
}>`
  ${({ column }) =>
    column
      ? css`
          width: 100%;
        `
      : css`
          width: 100%;
          @media (${BREAKPOINTS_DM.gold_1024}) {
            width: 65%;
          }
        `};
  ${({ isLink }) =>
    isLink &&
    css`
      width: 100%;
      a > span {
        font-size: 13px;
        &:hover {
          color: #76b729;
        }
      }
    `}
`;

export const MixedWrapper: any = styled.div<{ isLink: boolean }>`
  @media (${BREAKPOINTS_DM.gold_1024}) {
    ${({ isLink }) =>
      isLink &&
      css`
        display: flex;
        flex-direction: row;
      `}
  }
`;

export const LinkWrapper: any = styled.a`
  &:hover {
    > div > div > p > a > span {
      color: #76b729;
    }
  }
`;

export const LeftImageWrapper: any = styled.div`
  width: 100%;
  text-align: center;

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 33%;
  }
`;

export const ImageWrapper: any = styled.img`
  width: 100%;

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 90%;
  }
`;

export const ImageWithTextWrapper: any = styled.div`
  width: 100%;

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 66%;
  }
`;

export const FirstItemWrapper = styled.div<{
  hasImage: boolean;
  isFounderPage;
}>`
  ${({ hasImage, isFounderPage }) =>
    !hasImage &&
    !isFounderPage &&
    css`
      @media (${BREAKPOINTS_DM.silver_768}) {
        width: 67%;
        margin-left: auto;
      }
    `}

  ${({ isFounderPage }) =>
    isFounderPage &&
    css`
      width: 95%;
      padding: 0 30px;

      @media (${BREAKPOINTS_DM.silver_768}) {
        padding-top: 40px;
        padding-bottom: 0;
      }
    `}
`;
