import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

import styled, { css } from "styled-components";

import { theme } from "../../../../constants/themes";

export const Wrapper: any = styled.div`
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 30px;
    p {
        margin: 0 0 20px;
        font-weight: 300;
    }
    div.image {
        width: 100%;
        > div { 
            width: 100%;
            div {
                width: 100%;
                img {
                    margin-bottom: 5px;
                    width: 101%;
                }
            }
        }
    }

    div.text {
        width: 100%;
        padding: 0 20px 20px;
        min-height: 650px;
        div {
            color: #666666;
            iframe {
                display: none;
            }
            p {
                color: #666666;
            }
            .c-empty {
              min-height: 21px;
              width: 100%;
              display: none;
          }   
        }

    }
`;

export const HeadingWrapper: any = styled.div`
    margin: 10px 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    h3.title {
      color: #555;
      font-family: Roboto, sans-serif;
      font-size: 20x;
      font-weight: 400;
      line-height: 28px;
      margin: 20px 0 10px;
      &:hover {
        color: ${theme.palette.default};
      }
    }
    h3.headline {
      color: rgb(68, 68, 68);
      font-family: Roboto, sans-serif;
      font-size: 28px;
      font-weight: 400;
      line-height: 33.6px;
      margin: 5px 0 10px;
      text-align: center
    }
    p.date {
      color: rgb(68, 68, 68);
      font-family: Roboto, sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 21.6px;
      margin: 5px 0 10px;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center;
    }
`;

export const isActive: any = css`
    overflow: hidden;
    height: auto;
    max-height: 2000px;
    transition: max-height 5s ease-out;
    font-family: Roboto, sans-serif;
    background-color: #fff;

    ul {
        padding-left: 35px;
        list-style-position: inside;
        * {
            list-style-position: inside;
        }
        li {
            display: list-item;
            list-style: disc;
        }
    }
    strong ol li {
        font-weight: 700;
    }
    ul,
    ol {
        display: inline-block;
        text-align: left;
        li,
        span {
            text-align: left;
        }
    }
    ol {
        padding-left: 15px;
        list-style-position: inside;
        * {
            list-style-position: inside;
        }
        li {
            display: list-item;
            list-style: decimal;
        }
    }
    a {
        &,
        span {
            color: #666666;
        }
        span {
            &::after {
                content: "";
                display: inline-block;
                background-image: url("/images/sprite-s82c5aa31b4.png");
                background-repeat: no-repeat;
                background-position: -20px -36px;
                width: 15px;
                height: 15px;
                margin-left: 5px;
                transform: translateY(3px);
            }
        }
    }
    h1,
    h2,
    h3,
    h4 {
        &,
        span {
            font-size: 14px;
            color: #666666;
            font-weight: 400;
        }
    }
    h3,
    h4 {
        &,
        span {
            font-weight: 400;
            letter-spacing: 0.02em;
            color: #666666;
            font-size: 1.2rem;
        }
    }
    p {
        font-weight: 300;
        span { 
            font-weight: 300;
            font-size: 15px;
            font-family: sofia_pro_lightregular,Helvetica,Arial,sans-serif;
        }
    }
    p.bottom-button {
        color: #666666;
        font-size: 15px;
        font-weight: 100;
        line-height: 34px;
    }
`;

export const imgAndText: any = css`
    display: flex;
    flex-direction: column;
`;

export const imgAndSubtitleWrapper: any = css`
    @media (${BREAKPOINTS_DM.silver_768}) {
        /* margin-right: 20px; */
    }

    display: flex;
    flex-direction: column;
`;

export const subTitle: any = css`
    &:not(:last-of-type) {
        margin-bottom: -20px;
    }
    background-color: rgb(112, 111, 111);
    padding: 10px;
    font-size: 12px;
    text-align: center;
    margin-bottom: 20px;
`;

export const ContentContainer: any = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    max-width: 1170px;
    margin: 0 auto;
    @media (${BREAKPOINTS_DM.silver_768}) {

    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &,
      span {
        font-family: "Open Sans", Helvetica, Arial, sans-serif;
        color:  #777878;
      }
    }

    h1,
    h2,
    h3 {
        &,
        span {
          color:  #777878;
          font-size: 18px;
          font-weight: 600;
        }
    }

    span {
      font-weight: 300;
    }
    p {
      &,
      spam {
        color:  #777878;
        font-family: "Open Sans", Helvetica, Arial, sans-serif;;
        font-size: 14px;
        font-weight: 300;
        line-height: 26px;
      }
    }
`;

export const NavigationContainer: any = styled.div`
    padding: 0 10px;
    margin: 0 auto 21px;
    a {
      color: rgb(102, 102, 102);
      font-family: Roboto, sans-serif;
      font-size: 12px;
      font-weight: 600;
      line-height: 24px;
      cursor: pointer;
      &:hover {
        color: #ccc;
      }
      &:last-child {
        justify-self: flex-end;
        svg {
          padding-right: 0;
          padding-left: 10px;
        }
      }
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
      padding: 0 30px;
    }
`;

export const TextWrapper: any = styled.div<{ noImages: boolean}>`
  width: 100%;

  .c-frame {
    iframe {
      width: 100%;
      height: 550px;
    }
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    h2 {
      span {
        color: rgb(102, 102, 102);
        font-family: Roboto, sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 16.8px;
        margin-bottom: 10px;
      } 
    }

    p.c-empty {
      display: none;
      margin: 0;
      height: 0!important;
      min-height: 0!important;
    }
  }
`;

export const ImageContainer: any = styled.div`
  width: 100%;
  padding: 10px;
  margin: 0 auto;
  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 0;
  }
`;

export const ImageWrapper: any = styled.div`
  width: 100%;
  margin-bottom: 10.5px;

  > div > div {
    width: 100%;
  }

  div > img {
    width: 100%;
  }

  div > div > div {
    top: 50%;
    p {
      background-color: transparent;
    }
    
    img {
      max-width: 100%;
    }
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    img {
      height: auto !important;
      height: 288px;
    }
  }

  p {
    background-color: ${({ theme }) => theme?.palette?.bg};
    padding: 10.5px;
    font-size: 13px;
  }

  }
  :hover {
    :after {
      opacity: .5;
    }
  }
`;

export const ContactWrapper: any = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 180px;
  background: ${theme.palette.default};
  border-top: 1px solid #fff;
  border-bottom: 2px solid #fff;
  h3.text {
    color: rgb(255, 255, 255);
    font-family: Roboto, sans-serif;
    font-size: 18px:
    font-weight: 400;
    line-height: 21.6px;
  }
  div.text-container {
    padding-left: 50px;
    width: 60%;
  }
  div.button-container {
    display: flex;
    justify-content: center;
    width: 40%;
  }
  a.button {
    border: 2px solid #fff;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 500;
    height: 46px;
    letter-spacing: 1px;
    line-height: 16px;
    padding: 13px 30px;
  }
`;
