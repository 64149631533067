import styled, { css } from "styled-components";

import colors from "../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const ContentContainer: any = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px auto 0px auto;
    @media (${BREAKPOINTS_DM.gold_1024}) {
        flex-direction: row;
    }
`;

export const ContentWrapper: any = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const sideBarWrapper: any = css`
    width: 100%;
    @media (${BREAKPOINTS_DM.gold_1024}) {
        padding-left: 20px;
    }
`;

export const imageInText: any = css`
  
`;

export const imageInText2: any = css`
	width: 100%;
	> div:first-of-type {
		float: left;
		margin: 0px;
	}
	@media (${BREAKPOINTS_DM.silver_768}) {
		width: 90%;
		> div:first-of-type {
				float: left;
				margin: 0px 20px 20px 0;
		}
	}
`;

export const Figcaption: any = styled.div`
  text-align: center;
	background-color: rgba(255,255,255,0.5);
	padding: 10px 0px;
	width:  100%;
`;

export const Figcaption1: any = styled.div`
	text-align: center;
	background-color: rgba(255,255,255,0.5);
	padding: 10px 0px;
	width: 33%;
	@media (${BREAKPOINTS_DM.silver_768}) {
		width:  100%;
		inframe {
			width: 50%;
		}
}
`;

export const Figcaption2: any = styled.div`
  text-align: center;
	background-color: rgba(255,255,255,0.5);
	padding: 10px 0px;
	width:  100%;
	@media (${BREAKPOINTS_DM.silver_768}){
		div {
			width: 345px;
			img {
				width: 345px;
				height: 172px;
				margin: 0 0 15px;
			}
		} 
	}
	@media (${BREAKPOINTS_DM.gold_1024}){
		width: 360px;
		height: 180px;
		margin: 0 0 15px;
	}
	@media (${BREAKPOINTS_DM.platinum_1366}){
		width: 360px;
		height: 180px;
		margin: 0 0 15px;
	}
`;

export const Figcaption2Wrapper: any = styled.div`
	width: 100%;
	@media (${BREAKPOINTS_DM.silver_768}){
		margin: 0 0 0 -60px;
	
	}
	@media (${BREAKPOINTS_DM.gold_1024}){
		margin: 0;
	
	}
	@media (${BREAKPOINTS_DM.platinum_1366}){
	
	}
`;

export const Title1: any = styled.h1`
    font-family: Roboto,sans-serif;
    line-height: 1.4;
    margin-top: 5px;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 18px;
`;

export const Title2: any = styled.h1`
    font-family: Roboto,sans-serif;
    line-height: 1.4;
    margin: 0 0 -115px;
    font-weight: 600;
		font-size: 35px;
		color: #383838;
		@media (${BREAKPOINTS_DM.silver_768}) {
			font-size: 38px;
			margin-top: 45px;
    	margin-bottom: 15px;
		}
`;

export const CallToActionWrapper: any = styled.div`
		margin: 80px 0px 50px;
    height:  auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
		align-items: center;
`;

export const CallToActionButton: any = styled.div`
	font-family: Roboto,sans-serif;
	font-weight: 600;
	padding: 15px 30px;
	font-size: 17px;
	line-height: 1.4;
	letter-spacing: 1px;
	border: 2px solid ${props => props.theme.palette.green};
	background-color: ${props => props.theme.palette.green};
	border-radius: 4px;
	color: ${props => props.theme.palette.white};
	&:hover {
		opacity: 1;
		background-color: ${props => props.theme.palette.light_green};
		border: 2px solid ${props => props.theme.palette.light_green};
	}
`;

export const UniversalElementWrapper: any = styled.div`
		width: 100%;
		padding: 0px 10px;
		
		@media (${BREAKPOINTS_DM.silver_768}) {
			width: 100%;
			margin: 0 auto;
			div {
				margin: 0 auto;
				display: flex;
				flex-wrap: wrap;
				img {
					width: 345px;
					height: 166px;
					padding: 0 10px;
				}
			}
		}
		@media (${BREAKPOINTS_DM.gold_1024}) {
			width: 83%;
			justify-content: flex-start;
			div {
				margin: 0 auto 0 0;
				img {
					width: 293px;
					height: 142px;
					padding: 0 10px;
				}
			} 
		}
		@media (${BREAKPOINTS_DM.platinum_1366}) {
			width: 82%;
			div{
				margin: 0 auto 0 0;
				img {
					width: 360px;
					height: 174px;
				}
			}
		}
`;

export const Separator: any = styled.div`
	margin: 60px 0px;
`;

export const FigcaptionWrapper: any = styled.div`
		display: flex;
		flex-direction: column;
		margin: 50px auto;
		flex-wrap: wrap;
		width: 100%;
		padding: 10px 10px;
	
		@media (${BREAKPOINTS_DM.silver_768}) {
			flex-direction: row;
			padding: 0px 0px 0px 70px;
		}
		@media (${BREAKPOINTS_DM.platinum_1366}) {
			flex-direction: row;
			padding: 0px 0px 0px 125px;
		}

`;

export const TextIconFileWrapper: any = styled.div`
		width: 100%;
		@media (${BREAKPOINTS_DM.silver_768}) {
			width: 30%;
			.c-frame {
				iframe {
					width: 100%;
				}
			}
		}
`;

export const WidthFull: any = styled.div`
		width: 100%
`;

export const WidthFullText: any = styled.div`
		width: 100%;
		ul {
			padding: 0;
			margin-left: 15px;
		}
`;

export const NavBarSubitemsWrapper: any = styled.div<{isArticle: boolean; isArticleWithImage: boolean}>`
		margin: -40px 0 0;
    width: 100%;
    height:  auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
		align-items: center;
		color: red;
		z-index: ${({ isArticle }) => isArticle && 1}
`;
export const NavBarSubitemsContent: any = styled.div`
    width: 100%;
    height: auto;
    background-color: #F5F5F5F5;
    display: flex;
    justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		@media (${BREAKPOINTS_DM.silver_768}) {
			height: 65px;
		}
`;

export const NavBarSubitemsButton: any = styled.a<{ isCurrent: boolean}>`
	display: flex;
	font-family: Roboto,sans-serif;
	font-size: 15px;
	font-weight: 600;
	padding: 5px 0px;
	color: ${({ isCurrent }) => isCurrent ? "#70c13e" : "#666"};
	&:hover {
		color: #70c13e;
	}
`;

export const HorizontalSeparator: any = styled.div`
	margin: 2.5px 10px 0px;
	height: 14px;
	border-right: 1px solid #666;
`;

export const Title: any = styled.h1`
		overflow-wrap: anywhere;
		color: ${colors.white};
		font-size: 35.5px!important;
    font-weight: 800;
    text-shadow: #444 0 1px 1px;
    font-family: Roboto,sans-serif;
    letter-spacing: -1.5px;
		line-height: 1.4;
		@media (${BREAKPOINTS_DM.silver_768}) {
			  font-size: 40.5px!important;
		}
`;

export const TitleContent: any = styled.div`
	display: flex;
	align-items: flex-end;
	overflow-wrap: break-word;
	position: relative;
	height: 350px;
	width: 100%;
	color: ${colors.white};
	bottom: 500px;
	margin: 0 0 -320px;
	@media (${BREAKPOINTS_DM.silver_768}) {
		bottom: 430px;
		margin: 0 0 -340px;
	}
	@media (${BREAKPOINTS_DM.gold_1024}) {
		bottom: 430px;
		margin: 0 0 -330px;
	}
	@media (${BREAKPOINTS_DM.platinum_1366}) {
		bottom: 430px;
		margin: 0 0 -300px;
	}
`;

export const TitleContent1: any = styled.div`
	display: flex;
	align-items: flex-end;
	overflow-wrap: break-word;
	position: relative;
	height: 350px;
	width: 100%;
	color: ${colors.white};
	bottom: 600px;
	margin: 0 0 -420px;
	@media (${BREAKPOINTS_DM.silver_768}) {
		bottom: 530px;
		margin: 0 0 -390px;
	}
`;

export const TextWrapper: any = styled.div`
	margin: 0 -27px 20px;
	div, p, h1,h2,h3,h4 {
		display: block;
		width: 100%
	}
	p > a {
		text-align: center;
		font-size: 10px;
		width: 100%;
		background-color: #e5e5e5;
		border: 2px solid #ccc;
		border-radius: 4px;
		padding: 6px 5px;
		opacity: 1;
		&:hover {
			opacity:1;
			background-color: #d1d1d1;
			border-color: #adadad;
		}
		span {
			width: 100%;
			text-align: center;
			font-weight: 600;
			font-size: 12px;
			letter-spacing: 0px;
			line-height: 1.2;
			&:hover {
				color: #000;
			}
		}
	}

	@media (${BREAKPOINTS_DM.silver_768}) {
		margin: 20px -60px 20px;
		
		p > a {
			padding: 6px 18px;
			span {
				letter-spacing: 1px;
			}
		}
	}
	@media (${BREAKPOINTS_DM.gold_1024}) {
		margin: 20px 0 40px;
	}
	@media (${BREAKPOINTS_DM.platinum_1366}) {
		margin: 0 0 50px;
	}
`;

export const PhotoGalleryWrapper: any = styled.div`
	width: 100%;
	padding: 0px 10px;
	
	@media (${BREAKPOINTS_DM.silver_768}) {
		width: 84%;
		margin: 0 auto;
		div {
			display: flex;
			flex-wrap: wrap;
			img {
				width: 100%;
			}
		}
	}
	@media (${BREAKPOINTS_DM.gold_1024}) {
		width: 83%;
	}
	@media (${BREAKPOINTS_DM.platinum_1366}) {
		width: 82%;
	}
`;

export const TextArticleWrapper: any = styled.div`
		margin: 0 -14px 20px;
	
		p > a {
			text-align: center;
			font-size: 10px;
			width: 100%;
			background-color: #e5e5e5;
			border: 2px solid #ccc;
			border-radius: 4px;
			padding: 6px 5px;
			opacity: 1;
			&:hover {
				opacity:1;
				background-color: #d1d1d1;
				border-color: #adadad;
			}
			span {
				width: 100%;
				text-align: center;
				font-weight: 600;
				font-size: 12px;
				letter-spacing: 0px;
				line-height: 1.2;
				&:hover {
					color: #000;
				}
			}
		}
		p {
			color: #383838!important;
			font-family: Roboto,sans-serif!important;
			line-height: 1.4!important;
			margin: 5px 0 5px -5px!important;
			font-weight: 600!important;
			font-size: 20px!important;
		 }
	

		@media (${BREAKPOINTS_DM.silver_768}) {
			margin: 20px -35px 20px;
			
			p > a {
				padding: 6px 18px;
				span {
					letter-spacing: 1px;
				}
			}
		}
		@media (${BREAKPOINTS_DM.gold_1024}) {
			margin: 20px -43px 40px;
		}
		@media (${BREAKPOINTS_DM.platinum_1366}) {
			width: 108%;
			margin: 0 0 50px -40px;
		}
`;
