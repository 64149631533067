import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const ContentContainer: any = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;

  @media (${BREAKPOINTS_DM.silver_768}) {
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &,
    span {
      color: ${({ theme }) => theme?.palette?.primary};
    }
    margin-bottom: 20px;
  }

  h1,
  h2,
  h3 {
    &,
    span {
      font-size: 24px;
      font-weight: 700;
    }
  }

  p:not(.c-empty) {
    font-weight: 300;
    margin-bottom: 20px;
  }

  ul {
    list-style-type: circle;
    margin: 0 0 20px 20px;

    li {
      line-height: 21px;
      font-weight: 300;
    }
  }
  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    color: #6b6b6b;
  }
`;

export const HeadTextWrapper: any = styled.div`
  margin-top: 20px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    margin-top: 0px;
  }
  a:first-child {
    &,
    span {
      color: #f6931e;
      font-size: 2rem;
      font-weight: 300;
      text-shadow: 1px 1px 5px rgb(255 255 255 / 10%);
      :hover {
        color: ${({ theme }) => theme?.palette?.textColor};
      }
    }
  }
`;

export const ContentWrapper: any = styled.div`
  display: flex;

  @media (${BREAKPOINTS_DM.silver_768}) {
  }
`;

export const SideBarWrapper: any = styled.div`
  width: 100%;

  @media (${BREAKPOINTS_DM.silver_768}) {
    max-width: 25%;
    padding-right: 21px;
  }
`;

export const ElementsWrapper: any = styled.div<{ showSideBar: boolean }>`
  width: 100%;

  @media (${BREAKPOINTS_DM.silver_768}) {
    max-width: ${({ showSideBar }) => (showSideBar ? "75%" : "100%")};
  }
`;

export const TextElementContainer: any = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (${BREAKPOINTS_DM.silver_768}) {
    margin-right: -21px;
  }
`;

export const TextElementWrapper: any = styled.section`
  font-size: 13px;
  h3 {
    font-size: 15px;
    margin-bottom: 10px;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: calc(100% / 3 - 22px);
    margin-right: 22px;
  }

  &:last-child {
    width: auto;

    > div {
      display: flex;
      gap: 30px;
      justify-content: space-between;
      flex-direction: column;

      @media (${BREAKPOINTS_DM.silver_768}) {
        flex-direction: row;
      }

      > div {
        @media (${BREAKPOINTS_DM.silver_768}) {
          flex-basis: 50%;
        }

        &:nth-child(2) {
          > p {
            color: #f9b000;
            font-size: 14px;
            &:first-child {
              margin: 0;
            }
          }

          table {
            tr {
              display: flex;
              flex-direction: column;
              gap: 5px;
              margin-bottom: 10px;

              > td {
                font-size: 14px;
                color: #777;

                > p {
                  margin: 0;
                }

                &:first-child {
                  color: #f9b000;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const RichTextWrapper: any = styled.div``;

export const ImagesContainer: any = styled.div``;

export const ImageInText: any = styled.div`
  > div {
    height: auto;
    > img {
      height: auto;
    }
    margin: 0 20px 20px 0;
    width: 100%;
  }
`;

export const ImageWrapper: any = styled.div`
  margin-bottom: 21px;
  p {
    background-color: ${({ theme }) => theme?.palette?.bgLight};
    padding: 10.5px;
    font-size: 13px;
  }
`;
export const UniversaElementWrapper: any = styled.div`
  margin-bottom: 21px;
  width: 100%;

  [data-gallery-slider] {
    width: 100%;
    border: 5px solid #6b6b6b;
    height: 300px;
    [data-image] {
      img {
        height: 290px;
        object-fit: cover;
      }
    }

    @media (${BREAKPOINTS_DM.silver_768}) {
      height: 440px;
      [data-image] {
        img {
          height: 430px;
        }
      }
    }

    .slick-prev,
    .slick-next {
      z-index: 9;
      background-color: rgba(255, 255, 255, 0.5) !important;
      width: 30px !important;
      height: 30px !important;

      :before {
        opacity: 1;
        display: inline-block;
        content: "" !important;
        background-image: url(/images/sprite-s29f1189ba8.png);
        background-repeat: no-repeat;
        background-position: -24px -30px;
        height: 18px;
        width: 12px;
        color: transparent !important;
      }

      :hover {
        background-color: rgba(255, 255, 255, 0.8);
        :before {
          background-position: -36px -30px;
        }
      }
    }

    .slick-next {
      right: 0 !important;
    }

    .slick-prev {
      left: 0 !important;
      :before {
        transform: rotate(180deg);
      }
    }
  }

  [data-gallery-slider-tracker] {
    width: 100%;

    .slick-slide {
      margin-top: 6px;
      &:not(:first-child) {
        padding-left: 6px;
      }
      :not(.slick-current) {
        opacity: 0.5;
      }
    }

    [data-thumnail-image] {
      img {
        height: 50px;

        @media (${BREAKPOINTS_DM.silver_768}) {
          height: 70px;
        }
        object-fit: cover;
      }
    }
  }
`;

export const Title: any = styled.div`
  margin: 60px 0 0;
  h1 {
    margin: 50px 0 10.5px;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 48.16%;
    h1 {
      margin: 0 0 10.5px;
    }
  }
`;

export const TopComponentWrapper: any = styled.div`
  .ps-offer {
    background-color: #f9b000;
    color: #fff;
    border-radius: 0;
  }
  .ps-button {
    border: 1px solid;
    border-radius: 0px;
    width: fit-content;
    height: fit-content;
    margin: 10px 0;
    padding: 0 10px;
    line-height: 45px;
  }
  .ps-prev,
  .ps-next {
    background-color: #6b6b6b;
    border-color: #6b6b6b;
    color: #fff;
    &:hover {
      background-color: #f9b000;
      border-color: #f9b000;
    }
  }
  [type="submit"] {
    width: 100%;
    background-color: #6b6b6b;
    border-color: #6b6b6b;
    color: #fff;
    &:hover {
      background-color: #f9b000;
      border-color: #f9b000;
    }
  }

  .ps-image {
    div > img {
      height: 100%;
      font-family: "object-fit: contain;";
      object-fit: cover;
      width: 100%;
    }
  }
`;
