import React, { FC } from "react";
import { getIfNewsShouldBeDisplayByDate, Img, IProduct } from "src/common";

import { ItemDefaultWrapper, ImageWrapper, Description, Price, Text } from "./ItemDefault.style";

const ItemDefault: FC<{ item: any }> = ({ item }) => 
{
  if(!item) 
  {
    return null;
  }

  const product = (item?.link?.[0]) as IProduct;
  const isProduct = product?.__typename.includes("Product");
  const link = item?.link?.[0] as any;
  const price = link?.ourPrice;
  const slug = link?.slug;

  if(isProduct && product?.acceptAGB && !getIfNewsShouldBeDisplayByDate({ startDate: product?.startDate, endDate: product?.endDate })) 
  {
    return null;
  }
  return (
    <ItemDefaultWrapper isProduct={isProduct}>
      <a href={`/${isProduct ? "produkt/" : ""}${slug}`}>
        <ImageWrapper isProduct={isProduct}>
          <Img src={item.thumbnail} />
        </ImageWrapper>
        <h2>{item.title}</h2>
        <p>Kurzbeschreibung</p>
        
        <Text>
          <span>Ehemaliger eigener Verkaufspreis</span>
          <span>10.000 €</span>
        </Text>

        {isProduct && (
          <Description>
            <span>Jetzt für nur</span>
            {price &&
            <Price>
              <span className="price">{price}</span>
              <span className="coin"> €</span>
            </Price>
            }
          </Description>
        )}
        <div className="button">
          <button><i className="fa fa-chevron-right" /> zum Angebot</button>
        </div>
      </a>
    </ItemDefaultWrapper>
  );
};

export default ItemDefault;
