import styled, { css } from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const FounderFormWrapper = styled.div``;

export const SFounderForm = styled.form`
  position: relative;
  margin: 0 auto;
  padding: 5px;
  max-width: none;
  margin-bottom: 2rem;
  width: 100%;

  &:after {
    content: "";
    display: table;
    clear: both;
  }
`;

export const FounderFormContainer = styled.div`
  margin: 0 60px;
  padding-inline: 0;
  margin-left: -15px;
  margin-right: -15px;
`;

export const FounderFormSuccessMessage = styled.p`
  font-size: 15px;
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
`;

export const FounderFormErrorMessage = styled.p`
  font-size: 15px;
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
`;

export const FounderFormItem = styled.div`
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
  width: 100%;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    width: 33.33333333%;
  }
`;

export const FounderFormLabel = styled.label`
  display: block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  a {
    color: #736654;
  }
`;

export const FounderFormInput = styled.input`
  width: 100%;
  height: 40px;
  font-size: 14px;
  background-color: white;
  border: 2px solid #736654;
  padding-left: 5px;
  padding: 1rem;
  &:focus {
    border: 2px solid #212afbff;
  }
`;

export const FounderFormTextAreaWrapper = styled.div`
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
  width: 100%;
`;

export const FounderFormTextArea = styled.textarea`
  width: 100%;
  height: 100px;
  font-size: 14px;
  border: 2px solid #736654;
  background-color: white;
  padding: 1rem;
  &:focus {
    border: 2px solid #212afbff;
  }
`;

export const FounderFormCheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 15px;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;

    .frc {
        &-captcha {
            overflow: hidden;
            display: none;
            margin-bottom: 15px;
            margin-top: 0;
            padding: 5px 10px;
            width: 100%;
            background-color: #ffffff;

            &[data-attached] {
                display: block !important;
            }
        }

        &-container {
            display: flex;
            align-items: center;
        }

        &-icon {
            color: #505050;
            fill: #505050;
            stroke: #505050;
            margin: 0;
            margin-right: 10px;
        }

        &-content {
            button {
                color: #505050;
                border-color: #505050;
            }
        }

        &-banner {
            &,
            a {
                &,
                b {
                    color: #505050;
                }
            }
        }
    }
`;

export const FounderFormCheckbox = styled.input``;

export const FounderFormButton = styled.button`
  padding: 1rem;
  border: 2px solid #333333;
  background: white;
  color: #1d1d1b;
  outline: 0;
  &:hover {
    background: #333333;
    color: white;
  }
`;

export const FounderLineBreak = styled.div``;
