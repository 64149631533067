import React, { Component } from "react";
import Slider, { Settings } from "react-slick";
import { IAsset } from "src/common";
import { BREAKPOINTS } from "src/common/constants/mediaquerys";

import SSlickBase from "../../../utils/slick/SSlickBase";
import SSlickTheme from "../../../utils/slick/SSlickTheme";
import { Wrapper, Figcaption } from "./GallerySlider.style";

interface IProps 
{
  imgArray: (IAsset | null)[] | null;
  overrideMainSliderSettings?: Settings;
  overrideNavSliderSettings?: Settings;
}

interface IState 
{
  nav1: any;
  nav2: any;
}

class GallerySlider extends Component<IProps, IState> 
{
  private slider1: any;
  private slider2: any;

  public constructor(props) 
  {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  public componentDidMount(): void 
  {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  public render(): React.ReactElement 
  {
    if(!this.props?.imgArray || this.props.imgArray.length <= 1) 
    {
      return null;
    }

    const imgArray = this.props?.imgArray;
    if(!imgArray?.[0]) 
    {
      return null;
    }

    let sliderSettings: Settings = {
      asNavFor: this.state.nav2,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      adaptiveHeight: true
    };

    let slider2Settings: Settings = {
      asNavFor: this.state.nav1,
      slidesToShow: this.props.imgArray?.length < 5 ? this.props.imgArray?.length : 5,
      slidesToScroll: 1,
      arrows: false,
      focusOnSelect: true,
      draggable: true,
      responsive: [
        {
          breakpoint: BREAKPOINTS.SILVER,
          settings: {
            slidesToShow: 3
          }
        }
      ]
    };

    if(this.props.overrideMainSliderSettings) 
    {
      sliderSettings = { ...sliderSettings, ...this.props.overrideMainSliderSettings };
    }

    if(this.props.overrideNavSliderSettings) 
    {
      slider2Settings = { ...slider2Settings, ...this.props.overrideNavSliderSettings };
    }

    return (
      <Wrapper css={`${SSlickBase}${SSlickTheme}`}>
        <div style={{ padding: "0 4px" }}>
          <Slider ref={slider => (this.slider1 = slider)} {...sliderSettings}>
            {imgArray.map((asset, i) => (
              <div key={i}>
                <figure>
                  <img
                    style={{ height: "auto", width: "100%" }}
                    src={asset?.src}
                    alt={asset.description}
                  />
                  {asset.description && <Figcaption> {asset.description}</Figcaption>}
                </figure>
              </div>
            ))}
          </Slider>
        </div>
        <Slider ref={slider => (this.slider2 = slider)} {...slider2Settings}>
          {imgArray.map((asset, i) => (
            <div key={i}>
              <div style={{ padding: "5px 4px" }}>
                <img
                  style={{
                    height: 80,
                    width: "100%",
                    objectFit: "cover"
                  }}
                  src={asset?.src}
                />
                {/* alt={asset.subtitle} */}
              </div>
            </div>
          ))}
        </Slider>
      </Wrapper>
    );
  }
}

export default GallerySlider;
