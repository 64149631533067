import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const ContentContainer: any = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (${BREAKPOINTS_DM.silver_768}) {
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &,
    span {
    }
    margin-bottom: 36px;
  }

  h1,
  h2,
  h3 {
    &,
    span {
      text-transform: uppercase;
      font-weight: 100;
      font-size: 24px;
    }
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 34px;
    margin-bottom: 1.4rem;
  }
  h3 {
    font-size: 25px;
    margin-bottom: 1.4rem;
  }
  h4 {
    font-size: 13px;
    margin-bottom: 1.4rem;
    font-weight: 700;
  }

  p,
  span {
    &,
    span {
      font-weight: 300;
      font-size: 15px !important;
    }
  }

  p:not(.c-empty) {
    font-weight: 300;
    margin-bottom: 20px;
  }

  ul {
    margin: 0 0 20px 2px;
    li {
      list-style-type: disc;
      line-height: 21px;
      &,
      span {
        font-weight: 300;
        font-size: 15px;
      }
    }
  }

  p > a > span {
    color: #7d7261;
    font-size: 30px;
    font-weight: 300;
    line-height: 42px;
    text-shadow: 1px 1px 5px rgb(255 255 255 / 10%);
  }
`;

export const HeroWrapper: any = styled.div`
  margin-bottom: 21px;
`;

export const ContentWrapper: any = styled.div`
  display: flex;
  margin-bottom: 21px;

  @media (${BREAKPOINTS_DM.silver_768}) {
  }
`;

export const SideBarWrapper: any = styled.div`
  width: 100%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    max-width: 25%;
    padding-right: 21px;
  }
`;

export const ElementsWrapper: any = styled.div<{
  isFounderPage: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  section {
    table {
      tr {
        td {
          width: 50%;
        }
      }
    }
  }
  section.text.withicon.withtext{
    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
      > div {
        flex-direction: row;
        > div {
          &:first-child {
            flex-basis: 50%;
          }
          &:last-child {
            flex-basis: 50%;
          }
        }
      }
    }
  }

  section.last.text.withicon.withtext {
    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
      > div {
        flex-direction: row;

        > div {
          &:first-child {
            flex-basis: 50%;
          }
        }
      }
    }
  }

  > section {
    > h1 {
      text-align: center;
      font-size: 30px;
      line-height: 32px;
      color: #cbbb89;
      border-bottom: 1px solid var(--color-primary);
      padding-bottom: 25px;
      margin-bottom: 10px;
    }
    &:not(:last-child) {
      > div {
        border-bottom: 1px solid var(--color-primary);
      }
    }
    &:last-child {
      > div {
        > div {
          &:first-child {
            div {
              ${({ isFounderPage }) =>
                !isFounderPage &&
                css`
                  border: none;
                `}
            }
          }
        }
      }
    }
  }
`;

export const ImagesContainer: any = styled.div``;

export const imageInText: any = css`
  > div {
    height: auto;
    > img {
      height: auto;
    }
    margin: 0 20px 20px 0;
    width: 100%;
  }
`;

export const ImageInText: any = styled.a`
  display: flex;
  flex-direction: column;
  h1.link-title {
    color: #968a78;
    font-size: 34px;
    letter-spacing: 0.68px;
    line-height: 34px;
    margin: 0 0 15px;
  }
  > div {
    height: auto;
    div {
      margin-bottom: 0;
      div {
        img {
          width: 150px;
          height: 57px;
        }
      }
    }
    width: 100%;
  }
  p {
    color: #968a78;
    line-height: 21px;
    margin-bottom: 21px;
    span {
      font-size: 13px;
      font-weight: 300;
    }
    &:hover {
      color: #000;
    }
  }
`;

export const ImageWrapper: any = styled.div`
  padding: 20px;
  border: 1px solid var(--color-primary);

  p {
    background-color: ${({ theme }) => theme?.palette?.bgLight};
    padding: 10.5px;
    font-size: 13px;
  }
`;
export const UniversaElementWrapper: any = styled.div`
  margin-bottom: 21px;
  width: 100%;

  [data-gallery-slider] {
    width: 100%;
    border: 5px solid #6b6b6b;
    height: 300px;
    [data-image] {
      img {
        height: 290px;
        object-fit: cover;
      }
    }

    @media (${BREAKPOINTS_DM.silver_768}) {
      height: 440px;
      [data-image] {
        img {
          height: 430px;
        }
      }
    }

    .slick-prev,
    .slick-next {
      z-index: 9;
      background-color: rgba(255, 255, 255, 0.5) !important;
      width: 30px !important;
      height: 30px !important;

      :before {
        opacity: 1;
        display: inline-block;
        content: "" !important;
        background-image: url(/images/sprite-s29f1189ba8.png);
        background-repeat: no-repeat;
        background-position: -24px -30px;
        height: 18px;
        width: 12px;
        color: transparent !important;
      }

      :hover {
        background-color: rgba(255, 255, 255, 0.8);
        :before {
          background-position: -36px -30px;
        }
      }
    }

    .slick-next {
      right: 0 !important;
    }

    .slick-prev {
      left: 0 !important;
      :before {
        transform: rotate(180deg);
      }
    }
  }

  [data-gallery-slider-tracker] {
    width: 100%;

    .slick-slide {
      margin-top: 6px;
      &:not(:first-child) {
        padding-left: 6px;
      }
      :not(.slick-current) {
        opacity: 0.5;
      }
    }

    [data-thumnail-image] {
      img {
        height: 50px;

        @media (${BREAKPOINTS_DM.silver_768}) {
          height: 70px;
        }
        object-fit: cover;
      }
    }
  }
`;

export const Title: any = styled.div`
  margin: 60px 0 0;
  h1 {
    margin: 50px 0 10.5px;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 48.16%;
    h1 {
      margin: 0 0 10.5px;
    }
  }
`;

export const BoxedContent: any = styled.div`
  border-bottom: 1px solid var(--color-primary);
  margin-bottom: 30px;

  h1 {
    text-align: center;
    margin: 0;
    padding: 10px 0 30px 0;
    font-weight: 300;
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 3.9px;
  }
`;

export const ImgElementWrapper: any = styled.div`
  margin: 27px 0 15px;
`;

export const RichTextWrapper: any = styled.div<{ isFounderPage: boolean }>`
  ${({ isFounderPage }) =>
    isFounderPage &&
    css`
      @media (${BREAKPOINTS_DM.silver_768}) {
        width: 50% !important;
      }
    `}

  padding: 0 20px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 0;
  }

  table {
    tr {
      td {
        color: #736654;
        font-family: "HelveticaLTW01", Arial, sans-serif;
        display: block;
        width: 100%;

        @media (${BREAKPOINTS_DM.silver_768}) {
          display: table-cell;
          width: auto;
          padding-left: 30px;
        }

        h4 {
          font-weight: 500;
          padding: 0;
          margin: 0;
          font-size: 14px;
          line-height: 20px;
        }

        h3 {
          font-weight: 400;
          padding: 7px 0px;
          margin: 0;
          font-size: 25px;
          line-height: 28px;
        }

        p {
          padding-bottom: 10px;
        }
      }
    }
  }
`;

export const TextElementWrapper = styled.div<{
  isLastChild: boolean;
  isFounderPage: boolean;
}>`
  ${({ isLastChild, isFounderPage }) =>
    isLastChild &&
    !isFounderPage &&
    css`
      &:last-child {
        padding: 20px;
        border: 1px solid var(--color-primary);
      }
    `}
`;
