import React, { FunctionComponent, Fragment, useContext } from "react";
import {
  Context,
  getPage,
  ITextElement,
  ISidebarText,
  IAsset,
  Img,
  IPhotoGallery,
} from "src/common";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";
import UniversalComponentsRenderer from "src/common/BaseComponents/UniversalCompoentRenderer/UniversalComponentsRenderer";
import PhotoGallery from "src/common/components/PhotoGalleryVisualization";

import Articles from "../../common/Articles";
import BrandBannerVertical from "../../common/BrandBannerVertical";
import FullscreenImage from "../../common/FullscreenImage";
import Micro from "../../common/Micro";
import ProductItem from "../../common/ProductItem";
import { RichTextGeneral } from "../../common/RichText";
import SubNavLeft from "../../common/SubNavLeft";
import TextBoxRight from "../../common/TextBoxRight";
import Hero from "./Hero";
import CourseContent from "../CourseContent";
import * as styles from "./MainContent.style";
import {
  ContentContainer,
  ContentWrapper,
  UniversaElementWrapper,
  ElementWrapper,
  BorderBottom,
  ImageInText,
  Title,
  SideLeftWrapper,
  ImageWRapper,
  ImageContent,
  TextWrapper,
  MixedWrapper,
  LinkWrapper,
  ImagesContainer,
  LeftImageWrapper,
  ImageWrapper,
  ImageWithTextWrapper,
  FirstItemWrapper,
} from "./MainContent.style";
import { FounderForm } from "../../common/FounderForm/FounderForm";
import { css } from "styled-components";

const TextElement: FunctionComponent<any> = ({
  isLinkElement,
  firstTextElement,
  textElement,
}) => {
  const linkElement = textElement?.text?.content
    ?.find((contentElement) =>
      contentElement?.children?.find((child) => child?.url)
    )
    ?.children?.find((link) => link?.url);
  return (
    <>
      {linkElement ? (
        <LinkWrapper href={linkElement?.url}>
          {!isLinkElement && (
            <Title isFirst={firstTextElement === textElement}>
              {textElement?.headline}
            </Title>
          )}
          <ImageInText column={textElement?.icon?.length > 1 || isLinkElement}>
            {textElement?.icon?.length > 0 &&
              textElement?.icon?.find((i) => i?.file?.[0]?.src) && (
                <ImageWRapper
                  column={textElement?.icon?.length > 1}
                  isLink={isLinkElement}
                >
                  {textElement?.icon?.map((i, index) => {
                    if (i.file?.[0]?.src) {
                      return (
                        <ImageContent
                          key={`i${index}`}
                          column={
                            textElement?.icon?.length > 1 || isLinkElement
                          }
                          isLink={isLinkElement}
                        >
                          <Img
                            quality={1020}
                            src={i.file}
                            contain
                            alt={i.subtitle}
                          />
                          {i.subtitle && (
                            <p css={styles.figcaption}>{i.subtitle}</p>
                          )}
                        </ImageContent>
                      );
                    }
                    return null;
                  })}
                </ImageWRapper>
              )}
            {isLinkElement && (
              <Title
                isFirst={firstTextElement === textElement}
                isLink={isLinkElement}
              >
                {textElement?.headline}
              </Title>
            )}
            {textElement?.text && (
              <TextWrapper
                column={textElement?.icon?.length > 1}
                isLink={isLinkElement}
              >
                <RichTextGeneral fragment textContent={textElement?.text} />
              </TextWrapper>
            )}
          </ImageInText>
        </LinkWrapper>
      ) : (
        <>
          {!isLinkElement && (
            <Title isFirst={firstTextElement === textElement}>
              {textElement?.headline}
            </Title>
          )}
          <ImageInText column={textElement?.icon?.length > 1 || isLinkElement}>
            {textElement?.icon?.length > 0 &&
              textElement?.icon?.find((i) => i?.file?.[0]?.src) && (
                <ImageWRapper
                  column={textElement?.icon?.length > 1}
                  isLink={isLinkElement}
                >
                  {textElement?.icon?.map((i, index) => {
                    if (i?.file?.length > 0) {
                      return (
                        <ImagesContainer key={`i${index}`}>
                          {i.file?.map((img, ind) => {
                            if (img.src) {
                              return (
                                <ImageContent
                                  key={`i${ind}`}
                                  column={
                                    textElement?.icon?.length > 1 ||
                                    isLinkElement
                                  }
                                  isLink={isLinkElement}
                                >
                                  <FullscreenImage
                                    img={[img]}
                                    subtitle={img.description}
                                  />
                                  {i.subtitle && (
                                    <p css={styles.figcaption}>{i.subtitle}</p>
                                  )}
                                </ImageContent>
                              );
                            }
                            return null;
                          })}
                        </ImagesContainer>
                      );
                    }
                    return null;
                  })}
                </ImageWRapper>
              )}
            {isLinkElement && (
              <Title
                isFirst={firstTextElement === textElement}
                isLink={isLinkElement}
              >
                {textElement?.headline}
              </Title>
            )}
            {textElement?.text && (
              <TextWrapper
                column={textElement?.icon?.length > 1 || !textElement?.icon}
                isLink={isLinkElement}
              >
                <RichTextGeneral fragment textContent={textElement?.text} />
              </TextWrapper>
            )}
          </ImageInText>
        </>
      )}
    </>
  );
};

const MainContent: FunctionComponent<any> = (props) => {
  const p = useContext(Context);
  const page = getPage(p);

  const sideBarText = page?.extras?.find(
    (e) => e?.__typename === "SidebarText"
  ) as ISidebarText;

  // @ts-ignore
  const mixedElements = page?.elements?.filter(
    (e) =>
      e?.__typename === "ContinuingPageGallery" ||
      e?.__typename === "PhotoGallery" ||
      e?.__typename === "TextElement" ||
      e?.__typename === "Asset"
  ) as any[];
  const connectedArticles = (page as any)?.connectedArticles;
  const navItem = p?.PrimaryNavigation?.items?.find(
    (i) =>
      !!i?.subitems?.find((s) => s?.slug === p.slug) ||
      i?.link?.[0]?.slug === p.slug
  );

  const TopComponent = props.topComponent;
  const firstTextElement = mixedElements?.find(
    (element) => element?.__typename === "TextElement"
  );

  const isLinkElement =
    mixedElements?.find((mixElement) =>
      mixElement?.text?.content?.find((contentElement) =>
        contentElement?.children?.find((child) => child?.url)
      )
    ) && true;

  const isFounderPage = page?.slug == "gruender";

  return (
    <>
      {p?.PageMicro?.externalLinkAddress && <Micro />}
      <Hero topComponent={TopComponent} pageBrand={props.pageBrand} />
      <ContentContainer>
        {navItem && sideBarText && (
          <SideLeftWrapper>
            <SubNavLeft />
            <TextBoxRight text={sideBarText?.text} isFounderPage={isFounderPage}/>
          </SideLeftWrapper>
        )}
        <ContentWrapper pageBrand={props.pageBrand}>
          {props.pageBrand && <BrandBannerVertical />}

          {firstTextElement && (
            <FirstItemWrapper hasImage={firstTextElement?.icon?.length > 0} isFounderPage={isFounderPage}>
              <Title isFirst={firstTextElement && true}>
                {firstTextElement?.headline}
              </Title>
              <ImageInText column={firstTextElement && true}>
                {firstTextElement?.icon?.map((i, index) => {
                  if (i.file?.[0]?.src) {
                    return (
                      <Fragment key={`i${index}`}>
                        <Img quality={1020} src={[i]} contain alt={i.title}>
                          {i.title && <p css={styles.figcaption}>{i.title}</p>}
                        </Img>
                      </Fragment>
                    );
                  }
                  return null;
                })}
                {firstTextElement?.text && (
                  <div
                  css={css`
                  p {
                    font-size: 14px !important;
                    line-height: 26px !important;
                  }
                  `}
                  >

                  <RichTextGeneral
                    fragment
                    
                    textContent={firstTextElement?.text}
                    />
                    </div>
                )}
              </ImageInText>
              {isFounderPage && <FounderForm />}
            </FirstItemWrapper>
          )}

          <MixedWrapper isLink={isLinkElement}>
            {mixedElements &&
              mixedElements.map((e, i) => {
                const textElement =
                  e?.__typename === "TextElement" && (e as ITextElement);
                const imageElement =
                  TopComponent && e?.__typename === "Asset" && (e as IAsset);
                const universlElement =
                  e?.__typename === "ContinuingPageGallery" &&
                  (e as ITextElement);
                if (firstTextElement === textElement) {
                  return;
                }

                const isLastElement =
                  textElement?.text?.content.length > 0 &&
                  textElement?.icon?.length > 0;

                return (
                  <ElementWrapper
                    key={`mixedElements${i}`}
                    id={`abschnitt${i+1}`}
                    isLink={isLinkElement}
                    isLastElement={isLastElement}
                  >
                    {imageElement && (
                      <ImageInText>
                        <Img
                          alt={imageElement?.title}
                          src={[imageElement]}
                          quality={200}
                        />
                      </ImageInText>
                    )}
                    {textElement && (
                      <>
                        <LeftImageWrapper>
                          <ImageWrapper
                            src={textElement.icon[0]?.src || ""}
                          ></ImageWrapper>
                        </LeftImageWrapper>
                        <ImageWithTextWrapper>
                          <TextElement
                            isLinkElement={isLinkElement}
                            firstTextElement={firstTextElement}
                            textElement={textElement}
                          />
                        </ImageWithTextWrapper>
                      </>
                    )}

                    {universlElement && (
                      <UniversaElementWrapper>
                        <UniversalComponentsRenderer
                          items={[universlElement]}
                        ></UniversalComponentsRenderer>
                        <Spacer bronze={20} silver={30}></Spacer>
                      </UniversaElementWrapper>
                    )}
                  </ElementWrapper>
                );
              })}
          </MixedWrapper>

          <Articles
            connectedArticles={connectedArticles}
            footnote={page?.footnote}
          />
          <CourseContent
            course={props.course}
            onSetCourse={props.onSetCourse}
          />
        </ContentWrapper>
      </ContentContainer>

      {mixedElements &&
        mixedElements.map((e, _) => {
          const photoGallery =
            e?.__typename === "PhotoGallery" && (e as IPhotoGallery);
          if (photoGallery) {
            return (
              <UniversaElementWrapper
                zoomeffect={photoGallery.visualization === "zoomeffect"}
              >
                <PhotoGallery item={photoGallery} />
                <Spacer bronze={20} silver={30}></Spacer>
              </UniversaElementWrapper>
            );
          }
        })}
      {!props.news && <BorderBottom />}
      {props.children}
    </>
  );
};

export default MainContent;
