import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const Wrapper: any = styled.div<{isUniversalElement: boolean}>`
  background-color: ${({ isUniversalElement }) => isUniversalElement ? "#fff" : "rgb(230, 124, 25)"};
  width: 100%;
`;

export const ContentContainer: any = styled.div<{withoutHero: boolean}>`
    
    display: flex;
    flex-direction: column;
    width: 1170;
    margin: 0px auto;
    padding: 0 10px;
    
    .c-frame {
      iframe {
        max-width: 100%;
        width: 100%;
        height: 300px;
      }
    }

    h2,
    h3 {
        &,
        span {
            font-weight: 300;
        }
    }

    h1 {
        padding: 2px 10px 2px 0p; 
        &,
        span {
            font-weight: 700;
        }
    }
    a > span {
    }
    p {
      margin-bottom: 21px;
      display: flex;
      flex-direction: column;
      span {
        font-size: 15px;
        line-height: 21px;
        font-weight: 300;
        letter-spacing: 0.4px;
      }
    } 
    strong {
      font-weight: 300;
    }
    span {
      font-size: 15px;
      line-height: 21px;
      font-weight: 300;
      letter-spacing: 0.4px;
    }
    @media (${BREAKPOINTS_DM.gold_1024}) {
      flex-direction: row;
      padding: ${({ withoutHero }) => withoutHero ? "300px 0" : "0"};
      .c-frame {
        iframe {
          padding-left: 10px;
          width: 62%;
          height: 394px;
        }
      }
  }
   
`;

export const ContentWrapper: any = styled.div<{pageBrand: boolean; isSideRight: boolean}>`
    display: flex;
    flex-direction: column;
    width: 100%;
   
    ul {
      display: block;
      list-style-type: disc;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 15px;
      li {
        list-style-type: disc;
        span {
          color:  #85b2c3;
          font-size: 15px;
          line-height: 21px;
          font-weight: 300;
          letter-spacing: 0.4px;
        }
      }
    }
    @media (${BREAKPOINTS_DM.gold_1024}) {
      padding: ${({ pageBrand }) => pageBrand && "0 18px 0 0"};
    }
`;

export const ImageInText: any = styled.div<{ column: boolean; maxWidth: boolean; isUniversalElement: boolean; coursePage: boolean; planner: boolean }>`
  width: 100%;
  margin: auto;
  div.side {
    width: ${({ column }) => column ? "100%" : "100%"};
    padding: 0 15px;
  }
  p {
    ${({ maxWidth }) => maxWidth ? "color: rgb(51, 51, 51)" : "color: #fff"};
    ${({ coursePage }) => coursePage && "color: #666"};
    ${({ planner }) => planner && "color: #666"};
    display: flex;
    flex-direction: column;
    margin: 10px 0 10px;
    span {
      ${({ maxWidth }) => maxWidth && "color: rgb(51, 51, 51)"};
      ${({ isUniversalElement }) => isUniversalElement && "color: #666666"};
      ${({ maxWidth, isUniversalElement }) => !maxWidth && !isUniversalElement && "color: #fff"}
      font-size: ${({ maxWidth }) => maxWidth ? "12px" : "15px"};
      line-height: 24px;
      font-weight: 300;
      letter-spacing: 0.4px;
    }
  } 
  ul {
    color: #fff;
    list-style-type: disc;
    padding-left: 40px;
    margin: 20px 0 30px;
    li {
      list-style-type: disc;
      span {
        color: inherit;
        font-size: 15px;
        font-weight: 300;
        line-height: 24px;
      }
    }
  }
  ${({ planner }) => planner && `
    h1 {
      margin: 10px 0 0 0;
      a {
        background-color: #e5e5e5;
        border: 2px solid rgb(204, 204, 204);
        padding: 10px 16px;
        span {
          font-family: Roboto, sans-serif;
          font-size: 13px;
          font-weight: 400;
          height: 46px;
          letter-spacing: 1px;
          line-height: 22px;
        }
        &:hover {
          background-color: #e0e0e0;
          border: 2px solid #adadad;;
        }
      }
      span {
        color: rgb(68, 68, 68);
        font-family: Roboto, sans-serif;
        font-size: 30px;
        font-weight: 300;
        line-height: 42px;
        margin: 10px 0 5px;
      }
    }
  `};
  
  ${({ column }) => column ?
    `
      display: flex;
      flex-direction: column;
    ` :
    `
    display: flex;
    flex-direction: column;
    @media (${BREAKPOINTS_DM.gold_1024}) {
      flex-direction: row;
    }
  `}
  @media (${BREAKPOINTS_DM.silver_768}){
    max-width: ${({ maxWidth }) => maxWidth ? "730px" : "1170px"};
  }
`;

export const ImageWRapper: any =styled.div<{wrap: boolean}>`
  ${({ wrap }) => wrap && `
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 50%;
      padding: 15px
    }
  `}
  width: 100%;
  margin: 0px 0 30px;
  img {
    > img {
        height: auto;
    }
    min-height: "143px";
  }
`;

export const ImageContent: any = styled.div`
  height: auto;
`;

export const figcaption: any = css`
  padding: 10.5px;
  font-size: 13px;
  font-weight: 300;
`;

export const UniversaElementWrapper: any = styled.div`
  margin: 0px auto;
  width: 100%;
  max-width: 1170px;
  padding: 00px 15px 0px;
  .slick-prev,
  .slick-next {
    z-index: 9;
    background-color: rgba(255,255,255,.5) !important;
    width: 30px !important;
    height: 30px !important;
    :before {
      opacity: 1;
      display: inline-block;
      content: "" !important;
      background-image: url(/images/sprite-sa3ca3ca63e.png);
      background-repeat: no-repeat;
      background-position: 0 -172px;
      height: 18px;
      width: 12px;
      color: transparent !important;
    }

    :hover {
      background-color: rgba(255,255,255,.5);
    }
  }

  .slick-next {
    right: 0 !important;
  }

  .slick-prev {
    left: 0 !important;
    :before {
      transform: rotate(180deg);
    }
  }

  .slick-slide{
    img {
      max-height: 443.98px;
    }
    padding-top: 6px;
    &:not(.slick-current) {
      opacity: .5;
    }
  }

  [data-gallery-slider-tracker] {
    img {
      padding-right: 5px;
      max-height: 73px;
    }
  }
  [data-image] {
    img {
      height: 300px;
    }
  }

  [data-fullscreen-image] {
    width: 100%;
  }

  [data-caption] {
    align-items: center;
    color: rgb(255, 255, 255);
    display: flex;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: 400;
    justify-content: center;
    line-height: 24px;
    padding: 15px;
  }
  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 50px 15px;
  }
  .button-label {
    background-color: #222;
    border: 1px solid #222;
    border-radius: 2px;
    color: #aaa;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    height: 54px;
    letter-spacing: 1px;
    line-height: 16px;
    padding: 17px 25px;
    width: fit-content;
    &:hover {
      color: #fff;
    }
  }
`;

export const ElementWrapper: any = styled.div`
  margin-bottom: 60px;
  height: auto;
`;

export const Title: any = styled.div<{isFirst: boolean}>`
  width: 1170px;
  color: #fff;
  font-weight: 100;
  letter-spacing: 0.4px;
  ${({ isFirst }) => isFirst ? `
    display: none;
    font-weight: 700;
    margin: 0 0 15px;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: .02em;
  ` : 
    `
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    padding: 0 15px;
    margin: 20px auto 30px;
  `}
`;

export const TextWrapper: any = styled.div<{withImg: boolean; universlElement: boolean }>`
  width: 100%;
  h2 {
    color: #fff;
    font-family: Roboto,sans-serif;
    font-size: 30px;
    line-height: 1.4;
    margin: 5px 0 10px;
    font-weight: 300;
    strong {
      font-weight: 500;
    }
  }
  h1 {
    margin: 0 0 7px 0;
    &,
    span {
        font-weight: 700;
        font-size: 34px;
        
    }
  }
  p {
    ${({ universlElement }) => universlElement && `
      color: #666;
      width: 66%;
      margin: auto;
      text-align: center;
      font-size: 15px;
      font-weight: 300;
      `};
    margin-bottom: 10px;
    a {
      span {
        &:hover {
          color: #fff;
        }
      }
    }
  }

  table {
    width: 100%;
    th {
      text-align: left;
    }
    tr {
      td {
        width: 33.3%;
      }
    }
  }
`;

export const MixedWrapper: any = styled.div`
  @media (${BREAKPOINTS_DM.silver_768}) {
    min-height: 110px;
  }
`; 

export const LinkWrapper: any = styled.a`
  &:hover {
    > div > div > p > a > span {
    color: #76B729;
    }
  }
`;

export const FirstTextElementWrapper: any = styled.div<{news: boolean; withoutHero: boolean}>`
  min-height: 40px;
  padding: 20px 15px 0;
  max-width: 1170px;
  margin: 0 auto;
  ${({ news }) => news && "padding: 300px 30px 21px"};
  ${({ withoutHero }) => withoutHero && "padding: 300px 30px 21px"};
`;

export const NavBarSubitemsWrapper: any = styled.div`
    width: 100%;
    height:  auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
		align-items: center;
		color: red;
`;
export const NavBarSubitemsContent: any = styled.div`
    width: 100%;
    height: auto;
    background-color: #F5F5F5F5;
    display: flex;
    justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		@media (${BREAKPOINTS_DM.silver_768}) {
			height: 65px;
		}
`;

export const NavBarSubitemsButton: any = styled.a<{ isCurrent: boolean}>`
	display: flex;
	font-family: Roboto,sans-serif;
	font-size: 15px;
	font-weight: 600;
	padding: 5px 0px;
	color: ${({ isCurrent }) => isCurrent ? "#e67c19" : "#666"};
	&:hover {
		color: #e67c19;
	}
`;

export const HorizontalSeparator: any = styled.div`
	margin: 2.5px 10px 0px;
	height: 14px;
	border-right: 1px solid #666;
`;
