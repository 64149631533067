import React, { FunctionComponent, Fragment, useContext, useRef, useEffect, useState } from "react";
import { Context, getPage, ITextElement, ISidebarText, IAsset, Img, IPhotoGallery } from "src/common";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";
import UniversalComponentsRenderer from "src/common/BaseComponents/UniversalCompoentRenderer/UniversalComponentsRenderer";
import PhotoGallery from "src/common/components/PhotoGalleryVisualization";

import Articles from "../../common/Articles";
import Micro from "../../common/Micro";
import { RichTextGeneral } from "../../common/RichText";
import SubNavLeft from "../../common/SubNavLeft";
import TextBoxRight from "../../common/TextBoxRight";
import NewsContent from "../NewsContent";
import Hero from "./Hero";
import * as styles from "./MainContent.style";
import {
    ContentContainer,
    ContentWrapper,
    HeroWrapper,
    UniversaElementWrapper,
    ElementWrapper,
    BorderBottom,
    ImageInText,
    Title,
    SideLeftWrapper,
    ImageWRapper,
    ImageContent,
    TextWrapper,
    MixedWrapper,
    FirstTextElementWrapper,
    TextBoxRightWrapper,
} from "./MainContent.style";
import { FounderForm } from "../../common/FounderForm/FounderForm";
import TeaserBanner from "../../common/TeaserBanner";

const TextElement: FunctionComponent<any> = ({ firstTextElement, textElement }) => {
    const validIcons = textElement?.icon?.filter((i) => !!i?.src) ?? [];

    return (
        <>
            {textElement.headline && <Title isFirst={firstTextElement === textElement}>{textElement.headline}</Title>}
            <ImageInText column={validIcons?.length > 1}>
                {validIcons?.length > 0 && (
                    <ImageWRapper>
                        {validIcons?.map((i, index) => {
                            if (i.src) {
                                return (
                                    <ImageContent key={`i${index}`}>
                                        <Img quality={1020} src={i.src} contain alt={i.title} />
                                        {/* {i.subtitle && <p css={styles.figcaption}>{i.subtitle}</p>} */}
                                    </ImageContent>
                                );
                            }
                            return null;
                        })}
                    </ImageWRapper>
                )}
                {textElement?.text && (
                    <TextWrapper withImg={validIcons?.length}>
                        <RichTextGeneral fragment textContent={textElement?.text} />
                    </TextWrapper>
                )}
            </ImageInText>
        </>
    );
};

const MainContent: FunctionComponent<any> = (props) => {
    const p = useContext(Context);
    const page = getPage(p);
    const sideBarText = page?.extras?.find((e) => e?.__typename === "SidebarText") as ISidebarText;

    // @ts-ignore
    const mixedElements = page?.elements?.filter(
        (e) =>
            e?.__typename === "ContinuingPageGallery" ||
            e?.__typename === "PhotoGallery" ||
            e?.__typename === "TextElement" // || e?.__typename === "Asset"
    ) as any[];
    const connectedArticles = (page as any)?.connectedArticles;
    const navItem = p?.PrimaryNavigation?.items?.find(
        (i) => !!i?.subitems?.find((s) => s?.slug === p.slug) || i?.link?.[0]?.slug === p.slug
    );
    const TopComponent = props.topComponent;
    const firstTextElement = mixedElements?.find((element) => element?.__typename === "TextElement");
    const isWithoutHero = mixedElements?.length <= 1 && !p?.PageNews;

    const isSideRight =
        mixedElements
            ?.find((element) => element?.__typename === "ContinuingPageGallery")
            ?.items.find((item) => item?.link.find((link) => link?.__typename !== "ExternalLink")) && true;

    const isFounderPage = page?.slug == "Gründer";

    // calculate height of header paragraph
    const [headerParagraphHeight, setHeaderParagraphHeight] = useState<number>(null);
    const headerParagraphRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (typeof window !== "undefined" && headerParagraphRef?.current) {
            const updateHeight = () => {
                const elementHeight = headerParagraphRef.current?.offsetHeight;
                setHeaderParagraphHeight(elementHeight + 20);
            };
            // Update once initially
            updateHeight();

            // Update on window resize
            window.addEventListener("resize", updateHeight);

            // Cleanup to avoid memory leaks
            return () => window.removeEventListener("resize", updateHeight);
        }
    }, []);

    return (
        <>
            <HeroWrapper isFirstText={firstTextElement.headline} headerParagraphHeight={headerParagraphHeight}>
                <Hero topComponent={TopComponent} isSideRight={isSideRight} pageBrand={props.pageBrand} />
                {firstTextElement && (
                    <FirstTextElementWrapper news={props.news} withoutHero={isWithoutHero}>
                        <Title isFirst={firstTextElement && true}>{firstTextElement?.headline}</Title>
                        <ImageInText column={firstTextElement && true} ref={headerParagraphRef}>
                            {firstTextElement?.icon?.map((i, index) => {
                                if (i.file?.[0]?.src) {
                                    return (
                                        <Fragment key={`i${index}`}>
                                            <Img quality={1020} src={i.file} contain alt={i.subtitle}>
                                                {i.subtitle && <p css={styles.figcaption}>{i.subtitle}</p>}
                                            </Img>
                                        </Fragment>
                                    );
                                }
                                return null;
                            })}
                            {firstTextElement?.text && (
                                <RichTextGeneral fragment textContent={firstTextElement?.text} />
                            )}
                        </ImageInText>
                    </FirstTextElementWrapper>
                )}
            </HeroWrapper>
            {p?.PageMicro?.externalLinkAddress && <Micro />}

            <ContentContainer withoutHero={isWithoutHero && !firstTextElement}>
                {sideBarText && !isSideRight && !props.news && (
                    <SideLeftWrapper isSideRight={isSideRight}>
                        <SubNavLeft />
                    </SideLeftWrapper>
                )}
                <ContentWrapper pageBrand={props.pageBrand} isSideRight={isSideRight}>
                    <MixedWrapper isFounderPage={isFounderPage}>
                        {mixedElements &&
                            mixedElements.map((e, i) => {
                                const textElement = e?.__typename === "TextElement" && (e as ITextElement);
                                const imageElement = TopComponent && e?.__typename === "Asset" && (e as IAsset);
                                const universlElement =
                                    e?.__typename === "ContinuingPageGallery" && (e as ITextElement);
                                const photoGallery = e?.__typename === "PhotoGallery" && (e as IPhotoGallery);

                                if (firstTextElement === textElement) {
                                    return;
                                }

                                const isLastElement =
                                    textElement?.text?.content?.length > 0 && textElement?.icon?.length > 0;

                                return (
                                    <ElementWrapper
                                        key={`mixedElements${i}`}
                                        id={`abschnitt${i + 1}`}
                                        isFounderPage={isFounderPage}
                                        isLastElement={isLastElement}
                                        hasTextElement={textElement?.text?.content?.length > 0}
                                    >
                                        {imageElement && (
                                            <ImageInText>
                                                <Img alt={imageElement?.title} src={[imageElement]} quality={1080} />
                                            </ImageInText>
                                        )}
                                        {textElement && (
                                            <TextElement
                                                firstTextElement={firstTextElement}
                                                textElement={textElement}
                                            />
                                        )}

                                        {isFounderPage && i === 2 && <FounderForm />}

                                        {universlElement && (
                                            <UniversaElementWrapper>
                                                <UniversalComponentsRenderer items={[universlElement]} />
                                            </UniversaElementWrapper>
                                        )}

                                        {photoGallery && (
                                            <UniversaElementWrapper>
                                                <PhotoGallery item={photoGallery} />
                                                <Spacer bronze={20} silver={30}></Spacer>
                                            </UniversaElementWrapper>
                                        )}
                                    </ElementWrapper>
                                );
                            })}
                        {sideBarText?.text && (
                            <TextBoxRightWrapper>
                                <TextBoxRight text={sideBarText?.text} />
                            </TextBoxRightWrapper>
                        )}
                    </MixedWrapper>
                    {props.news && <NewsContent />}
                    <Articles connectedArticles={connectedArticles} footnote={page?.footnote} />
                    {!isFounderPage && <TeaserBanner />}
                </ContentWrapper>
                {(navItem || sideBarText) && isSideRight && (
                    <SideLeftWrapper isSideRight={isSideRight}>
                        <SubNavLeft />
                    </SideLeftWrapper>
                )}
            </ContentContainer>
            {!props.news && <BorderBottom />}
            {props.children}
        </>
    );
};

export default MainContent;
