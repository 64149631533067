import styled from "styled-components";

export const BrandBannerVerticalWrapper = styled.div<any>`
    margin-bottom: 20px;
    @keyframes up-one {
      from {
            transform: translateY(0);
        }
        to {
            transform: translateY(-100%);
        }
    }
    @keyframes up-two {
      from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(0%);
        }
    }
    position: relative;
    .container {
        width: 100%;
        max-width: 712px;
        height: 344px;
        margin: 0;
        overflow: hidden;
        background: white;
        position: relative;
        box-sizing: border-box;
        background-color: transparent;
    }
    .marquee {
        &:nth-child(1) {
          transform: translateY(0%);
          animation: up-one ${(props: any) => (props.amount ?? 1) > 20 ? (props.amount ?? 1) / 20 * 20 : 55}s linear infinite;
        };
        &:nth-child(2) {
          transform: translateY(100%);
          animation: up-one ${(props: any) => (props.amount ?? 1) > 20 ? (props.amount ?? 1) / 20 * 20 : 55}s linear infinite;
        };
        width: 100%;
        top: 0;
        position: relative;
        box-sizing: border-box;
        margin: 0 auto;
        color: #ffffff;
        position: absolute;
        will-change: transform;
    }
    a {
        height: 44px;
        opacity: 0.6;
        position: relative;
        display: inline-block;
        img {
            height: 31px;
            margin: 0 1.5em 0.5em 0;
            width: auto;
        }
    }
`;

export const ShadowOverlay = styled.div`
    position: absolute;
    top: 0;
    opacity: 0.2;
    width: 100%;
    z-index: 1;
    height: 9px;
    transform: rotate(180deg);
    background: url(/images/gradient.png) repeat-x;
    &:last-child{
        bottom: 0;
        transform: rotate(0deg);
        top: calc(100% - 9px);
    }
`;
