import React, { FC, useContext, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import {
  Context,
  IAsset,
  getIfNewsShouldBeDisplayByDate,
  INews,
} from "src/common";

import NewsArticleItem from "./NewsArticleItem";
import NewsArticle from "./NewsArticleItem/NewsArticle";
import { Wrapper, WrapperNews } from "./NewsContent.style";
import { useRouter } from "next/router";

const monthsMapper = {
  "01": "Januar",
  "02": "Februar",
  "03": "März",
  "04": "April",
  "05": "Mai",
  "06": "Juni",
  "07": "Juli",
  "08": "August",
  "09": "September",
  10: "Oktober",
  11: "November",
  12: "Dezember",
};

const formatDate: any = (date) => {
  if (!date) {
    return;
  }
  const splitDate = date.match(/^(\d{4})-(\d{2})-(\d{2})/);
  return `${splitDate[3]}. ${monthsMapper[splitDate[2]]} ${splitDate[1]}`;
};

const NewsContent: FC<any> = () => {
  const p = useContext(Context);
  const router = useRouter();
  const articleIdFromRoute = router?.query?.slug[1];
  const [currentPage] = useState<number>(1);

  if (!p.PageNews?.NewsArticles) {
    return null;
  }

  const news = p.PageNews;
  const newsArticles =
    news?.NewsArticles?.filter((article) =>
      getIfNewsShouldBeDisplayByDate({
        startDate: article?.startDate,
        endDate: article?.endDate,
      })
    ).filter((article) => article !== null) || [];

  const newsCustomArticles =
    news?.NewsArticlesCustom?.filter((article) =>
      getIfNewsShouldBeDisplayByDate({
        startDate: article?.startDate,
        endDate: article?.endDate,
      })
    ).filter((article) => article !== null) || [];

  const articles = [...newsCustomArticles, ...newsArticles];

  const currentArticleIndex = articles.findIndex(
    (article) => article?.id === articleIdFromRoute
  );

  const currentArticle =
    articles && currentArticleIndex !== null && articles[currentArticleIndex];

  const itemsPerPage = 10;

  const articlesToRender: INews[] = articles.slice(
    (currentPage - 1) * itemsPerPage,
    itemsPerPage
  );
  return (
    <Wrapper currentArticle={currentArticle && true}>
      {currentArticle ? (
        <NewsArticle
          key={`newsArticle${currentArticleIndex}`}
          index={currentArticleIndex}
          headline={currentArticle?.headline}
          text={currentArticle?.text}
          date={formatDate(currentArticle.startDate)}
          images={
            (currentArticle?.extras?.filter(
              (e) => e?.__typename === "Asset"
            ) as IAsset[] | undefined) ?? []
          }
          currentArticlesToRender={articles}
          endPage={currentArticleIndex + 4}
          startPage={currentArticleIndex + 1}
        />
      ) : (
        <WrapperNews>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          >
            <Masonry>
              {articlesToRender &&
                articlesToRender.map(
                  (article, index) =>
                    article && (
                      <NewsArticleItem
                        id={`${article?.id}`}
                        key={`newsArticleItem${index}`}
                        index={index}
                        headline={article?.headline}
                        date={formatDate(article?.startDate)}
                        text={article?.text}
                        images={
                          (article?.extras?.filter(
                            (e) => e.__typename === "Asset"
                          ) as IAsset[] | undefined) ?? []
                        }
                      />
                    )
                )}
            </Masonry>
          </ResponsiveMasonry>
        </WrapperNews>
      )}
    </Wrapper>
  );
};

export default NewsContent;
