import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const Wrapper: any = styled.div`
  div.title {
    padding: 10px 0 0 20px;
    display: flex;
    font-size: 15px;
    color: #FA8800;
    align-items: center;
  }
  div.icon-down {
    margin: 0 5px 10px 0;
    line-height: 21px;
  }
  
  div.title-bottom {
    margin: 21px 0;
    display: flex;
    font-size: 15px;
    color: #000;
    align-items: center;
  }
  div.icon-up {
    margin: 0 0 10px 5px;
    line-height: 21px;
  }
 
  h3 {
    font-size: 15px;
    color: #FA8800;
    font-weight: 400;
    margin-bottom: 10px;
    line-height: 21px;
    letter-spacing: 0.3px;
  }
  h3.bottom {
    font-size: 14px;
    color: #000;
    font-weight: 300;
    margin-bottom: 10px;
    line-height: 21px;
    letter-spacing: 0.3px;
  }
  strong {
    color: #000;
  }
  a > span {
    color: #FA8800;
  }
  a {
    text-decoration-color: #FA8800;
  }
  img {
     width: 100%;
    height: auto;
    @media (${BREAKPOINTS_DM.silver_768})
    {
        height: auto;
    }
  }
  div > p {
    margin: 0;
    padding: 0;
    span {
      font-weight: 400;
      letter-spacing: 0.3px;
    }
  }
`;

export const WrapperItem: any = styled.div<{first: boolean}>`
 
`;

export const WrapperTitle: any = styled.div<{first: boolean}>`
margin: 42px 0 10px;
border-top: 1px solid #000;
border-left: 1px solid #000;
border-right: 1px solid #000;
`;
export const Footnote: any = styled.div`
    margin: 40px 0 20px 0;
`;

export const ImgAndHeadline: any = css`
    display: flex;
    flex-direction: column;
    @media (${BREAKPOINTS_DM.silver_768})
    {
        flex-direction: row;
        align-items: center;
    }
`;

export const ImgContent: any = styled.div`
    diplay: flex;
    flex-direction: column;
    width: 100%;
    img {
      margin: 10px 0;
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
      flex-wrap: wrap;
      flex-direction: row;
      img {
        width: 30%;
        margin: 10px 20px 10px 0;
      }
    }
`;
export const ContentWrapper: any = styled.div<{isShowContent: boolean}>`
${({ isShowContent }) => isShowContent ? 
    `
  overflow: hidden;
  height: auto;
  max-height: 2000px;
  transition: max-height 10s ease-out;`
    :
    ` 
  overflow: hidden;
  height: auto;
  max-height: 0;
  transition: max-height 1s ease;`
}
`;
