import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const Wrapper: any = styled.div`
  max-width: ${({ theme }) => theme.maxWidth}px;
  width: 100%;
  margin: 20px auto 0;
  
`;

export const SubItemsWrapper: any = styled.div`
    display: none;
    @media (${BREAKPOINTS_DM.gold_1024}) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
`;

export const SubItem: any = styled.div`
    width: 32%;
    margin: 0 0 20px;
`;

export const SubItemName: any = styled.a<{isActive: boolean}>`
    display: flex;
    background-color: ${({ isActive }) => isActive ? "#FA8800" : "#333333"};;
    padding: 15px;
    span.subitem_name {
      font-size: 14px;
      font-weight: 300;
      line-height: 19.6px;
      color: #fff;
      &:hover {
        color: ${({ isActive }) => isActive ? "#333333" : "#FA8800"};
      }
    }
`;

export const SubItemImg: any = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E6E7E9;
    color: gray;
    height: 150px;
    margin: 0 0 10px;
`;
