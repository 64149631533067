import styled from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
export const CourseFormWrapper: any = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth}px;
  padding-bottom: 60px;
  h3 {
    margin-bottom: 34px;
    font-size: 18px;
    font-weight: 600;
  }

  h2 {
    color: #fff;
    font-family: sofia_pro_extralightregular, Helvetica, Arial, sans-serif;
    font-size: 34px;
    font-weight: 100;
    letter-spacing: 0.68px;
    line-height: 38px;
    margin-bottom: 21px;
    span {
      color: #fff;
      font-family: sofia_pro_boldregular, Helvetica, Arial, sans-serif;
      font-size: 34px;
      font-weight: 600;
      letter-spacing: 0.68px;
      line-height: 38px;
    }
  }
  form {
    margin-top: 20px;
    .submit-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    [type=submit] {
      background-color: #76b729;
      padding: .5rem;
      font-size: 15px;
      font-weight: 100;
      line-height: 30px;
      letter-spacing: 1px;
      border-radius: 0;
      color: white;
      width: 49%;
      &:hover {
        background-color: #706f6f;;
      }
    }

    [type=button] {
      background-color: #706f6f;;
      padding: .5rem;
      font-size: 15px;
      font-weight: 100;
      line-height: 30px;
      letter-spacing: 1px;
      border-radius: 0;
      color: white;
      width: 49%;
     
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
      .submit-wrapper {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
`;

export const CourseItemWrapper: any = styled.div`
  color: #fff!important;
  > div {
    display: flex;
  }

  > a {
    display: block;
    background-color: #80C7DC;
    color: #efefef;
    border: 1px solid #80C7DC;
    line-height: 3;
    padding: 0 10px;
    margin-top: 20px;
  }

`;

export const CourseContentWrapper: any = styled.div`
  width: 66.7%;

  p {
    margin-bottom: 34px;
    &, span {
      line-height: 34px;
    }
  }

  a {
    color: #80C7DC;
  }

`;

export const InputWrapper: any = styled.div<{ sm?: number; md?: number}>`
  font-weight: normal;
  margin-bottom: 21px;
  color: #fff;
  font-family: sofia_pro_extralightregular, Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 300;
  line-height: 21px;

  ${({ sm }) => sm && `
    width: calc(100% / 12 * ${sm} - 10px);
  `}

  label {
    color: #fff;
    cursor: pointer;
    font-family: sofia_pro_extralightregular, Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 300;
    line-height: 21px;
  }

  input:not([type=checkbox]) {
    display: block;
    border-top-style: inherit;
    border-top-width: 0;
    border: 1px solid #706f6f;;
    padding: 2px 5px;
    width: 100%;
  }

  select {
    display: inline-block;
    font-size: 100%;
    margin: 0;
  }

  > div {
    display: flex;
    justify-content: space-between;
  }

  a {
    color: ${({ theme }) => theme.palette.primary};
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    ${({ md }) => md && `
      width: calc(100% / 12 * ${md} - 10px);
    `}
  }

`;

export const CourseDateWrapper: any = styled.div`
  width: 25%;
  margin: auto 0 30px auto;
  
  h3 {
    &, span {
      font-size: 22px;
      color: #fff;
    }
    margin: 0;
  }
  span {
    margin: 0;
    line-height: 1.5;
  }
  p { 
    color: #fff!important;
    span {
      color: #fff;
    }
  }
`;
