import styled, { css } from "styled-components";

export const MicroWrapper: any = styled.div`
  padding: 300px 30px 0; 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  iframe { 
    width: 100%; 
    height: 100vh;
    max-width: 1100px;
  }
`;
export const Frame: any = styled.iframe`
  width: 100%; 
  max-width: 1010px;
  padding-right: 15px;
  padding-left: 15px;
  height: 80vh;
`;
