import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const Wrapper: any = styled.div`
  margin: -50px 0 50px;
  h3 {
    font-size: 20px;
    color: #383838;
    font-family: Roboto,sans-serif;
    line-height: 1.4;
    margin: 5px 0 5px -5px;
    font-weight: 600;
  };
  p {
    width: 130%;
    margin-left: -37px;
    span {
      width: 100%;
    }
  }

  ul {
    width: 140%;
    margin: 0 0 0 -63px;
  }
  
  .c-frame > iframe {
    width: 350px;
    margin: 0 0 0 -50px;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
      h3 {
        margin-left: -15px;
      }
      .c-frame > iframe {
        width: 205px;
      }
  }

  @media (${BREAKPOINTS_DM.gold_1024}) {
    h3 {
      margin-left: -7px;
    }
    .c-frame > iframe {
      width: 270px;
    }
  }

  @media (${BREAKPOINTS_DM.platinum_1366}) {
    h3 {
      margin-left: -1px;
    }
    .c-frame > iframe {
      width: 355px;
    }
  }
`;

export const Footnote: any = styled.div`
    margin: 40px 0 20px 0;
`;

export const ContentWrapper: any = styled.div`
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  width: 90%; 
`;

export const ItemWrapper: any = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 10px;
  margin: 30px 0 0;
  
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 33.3%;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 10px 20px;
  }
  @media (${BREAKPOINTS_DM.platinum_1366}) {
    padding: 10px 40px;
  }
`;

export const ImgWrapper: any = styled.div`
  margin: 20px 0 0;
  img {
    @media (${BREAKPOINTS_DM.silver_768}) {
      width: 205px;
      margin-left: -15px;
    }
    @media (${BREAKPOINTS_DM.gold_1024}) {
      width: 270px;
      margin-left: -8px;
    }
    @media (${BREAKPOINTS_DM.platinum_1366}) {
      width: 355px;
      margin-left: 0;
    }
  }
`;
