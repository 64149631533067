import React, { FunctionComponent, useContext } from "react";
import { ITextElement, Context, IPage, IAsset, Img, ISlider } from "src/common";
import { css } from "styled-components";
import { richToTeaser } from "../../../../../common/utils/text/teaser";
import * as styles from "./TeaserGallerySmall.style";
import { ItemWrapper } from "./TeaserGallerySmall.style";

interface IProps 
{
  items: IPage[];
}

export const TeaserGallerySmall: FunctionComponent<IProps> = ({ items }) => 
{
  const p = useContext(Context);
  const palette = p?.Settings;  

  if(!items || items.length === 0) 
  {
    return null;
  }
  return (
    <div css={`${styles.flexWrapper}`}>
      {items.map((_item, i) => 
      {
        const item: any = _item;

        if (item?.__typename === "ExternalLink") {
          return <ItemWrapper
            key={i}
            color={palette.themeTextColor}
            primary={palette.themeColorPrimary}
          >
            {item?.externalLinkName && <h2>{item?.externalLinkName}</h2>}
            {item?.externalLinkAddress && (
              <a
                target="_blank"
                href={
                  "https://" +
                  `${item?.externalLinkAddress}`
                    .replace("http:", "https:")
                    .replace("https://", "")
                }
                css={styles.mainText}
                style={{color:  palette.themeColorPrimary ? palette.themeColorPrimary : "#222"}}
              >
                {"Mehr anzeigen"}{" "}
              </a>
            )}
          </ItemWrapper>;
        }

        if(item?.slug == p.slug) 
        {
          return null;
        }
        const textElement = item?.elements?.find((e) => e?.__typename === "TextElement") as ITextElement;
        const asset = item?.elements?.find((e) => e?.__typename === "Asset") as IAsset;
        const slider = item?.elements?.find((e) => e?.__typename === "Slider") as ISlider;

        const image =  asset?.src ? asset : slider?.elements.find((e) => e?.__typename === "Asset" && e?.src) as IAsset;

        let headline = textElement?.headline;
        if (!headline || headline.trim() === ""){
          headline = item?.nameInNavigation;
        }
        const teaser = textElement?.text && richToTeaser(textElement?.text);
        if(!(item?.slug)) 
        {
          return null;
        }

        return (
          <ItemWrapper key={i} color={palette.themeTextColor} primary={palette.themeColorPrimary}>
            {headline && <h2>{headline}</h2>}
            {image && <Img src={image?.src+"?h=272"} quality={484} alt={image?.description} />}
            {teaser && <p css={styles.mainText}>{teaser}</p>}
            {item?.slug && (
              <a href={item?.slug} css={css`${styles.mainText}`} style={{color:  palette.themeColorPrimary ? palette.themeColorPrimary : "#222"}}>
          Mehr anzeigen
              </a>
            )}
          </ItemWrapper>
        );
      })}
    </div>
  );
};
