import React, { FunctionComponent, Fragment, useContext } from "react";
import {
  Context,
  getPage,
  ITextElement,
  ISidebarText,
  IAsset,
  Img,
  IPhotoGallery,
} from "src/common";
import UniversalComponentsRenderer from "src/common/BaseComponents/UniversalCompoentRenderer/UniversalComponentsRenderer";
import PhotoGallery from "src/common/components/PhotoGalleryVisualization";

import Articles from "../../common/Articles";
import { Container, Divider, Section } from "../../common/Grid";
import Hero from "../../common/Hero";
import Micro from "../../common/Micro/Micro";
import { ProductItemT24 } from "../../common/ProductItem";
import { RichTextGeneral } from "../../common/RichText";
import SubNavRight from "../../common/SubNavRight";
import TextBoxRight from "../../common/TextBoxRight";
import {
  ContentContainer,
  HeadTextWrapper,
  ContentWrapper,
  SideBarWrapper,
  TextElementContainer,
  TextElementWrapper,
  ElementsWrapper,
  UniversaElementWrapper,
  ImagesContainer,
  ImageWrapper,
  ImageInText,
  TopComponentWrapper,
  RichTextWrapper,
} from "./MainContent.style";
import FounderContent from "../FounderContent/FounderContent";
import LinkBannerTeaser from "../../common/LinkBannerTeaser/LinkBannerTeaser";

interface IMainContentProps {
  showSideBar?: boolean;
  topComponent?: any;
}

const MainContent: FunctionComponent<IMainContentProps> = (props) => {
  const p = useContext(Context);
  const page = getPage(p);

  const sideBarText = page?.extras?.find(
    (e) => e?.__typename === "SidebarText"
  ) as ISidebarText;

  // @ts-ignore
  const mixedElements = page?.elements?.filter(
    (e) =>
      e?.__typename === "ContinuingPageGallery" ||
      e?.__typename === "PhotoGallery" ||
      e?.__typename === "Asset"
  ) as any[];
  const connectedArticles = (page as any)?.connectedArticles;
  const navItem = p?.PrimaryNavigation?.items?.find(
    (i) =>
      !!i?.subitems?.find((s) => s?.slug === p.slug) ||
      i?.link?.[0]?.slug === p.slug
  );
  const TopComponent = props.topComponent;

  // @ts-ignore
  const [headText, ...textElements] = (page?.elements?.filter(
      (e) => e?.__typename === "TextElement"
    ) as ITextElement[]) || [];

  // return FounderContent if slug is gruender
  if (p.slug === "gruender") {
    return <FounderContent />;
  }

  return (
    <Container>
      <Hero page={page} />
      <Section>
        <ContentContainer>
          {p?.PageMicro?.externalLinkAddress ? (
            <Micro />
          ) : (
            <>
              {TopComponent && (
                <TopComponentWrapper>
                  <TopComponent />
                </TopComponentWrapper>
              )}
              {headText && (
                <HeadTextWrapper>
                  <h1>{headText.headline}</h1>
                  <RichTextGeneral fragment textContent={headText?.text} />
                </HeadTextWrapper>
              )}

              <ContentWrapper>
                {(navItem || sideBarText) && props.showSideBar && (
                  <SideBarWrapper>
                    <SubNavRight />
                    <TextBoxRight text={sideBarText?.text} />
                  </SideBarWrapper>
                )}
                <ElementsWrapper showSideBar={props.showSideBar}>
                  {mixedElements &&
                    mixedElements.map((e, i) => {
                      const textElement =
                        e?.__typename === "TextElement" && (e as ITextElement);
                      const imageElement =
                        TopComponent &&
                        e?.__typename === "Asset" &&
                        (e as IAsset);
                      const universlElement =
                        e?.__typename === "ContinuingPageGallery" &&
                        (e as ITextElement);
                      const photoGallery =
                        e?.__typename === "PhotoGallery" &&
                        (e as IPhotoGallery);

                      if (textElement === headText) {
                        return null;
                      }

                      return (
                        <Fragment key={`mixedElements${i}`}>
                          {imageElement && (
                            <ImageInText>
                              <Img
                                alt={imageElement?.title}
                                src={[imageElement]}
                                quality={1080}
                              />
                            </ImageInText>
                          )}

                          {universlElement && (
                            <UniversaElementWrapper>
                              <UniversalComponentsRenderer
                                items={[universlElement]}
                              />
                            </UniversaElementWrapper>
                          )}

                          {photoGallery && (
                            <UniversaElementWrapper>
                              <PhotoGallery item={photoGallery} />
                            </UniversaElementWrapper>
                          )}
                        </Fragment>
                      );
                    })}
                  <Divider />
                  {textElements && (
                    <TextElementContainer>
                      {textElements?.map((textElement, key) => (
                        <TextElementWrapper
                          id={`abschnitt${key + 1}`}
                          key={`text-${key}`}
                        >
                          <h3>{textElement?.headline}</h3>
                          <div>
                            {!!textElement?.icon?.length && (
                              <ImagesContainer>
                                {textElement?.icon?.map((i, index) => (
                                  <ImageWrapper key={`i${index}`}>
                                    <Img
                                      quality={1020}
                                      src={i.src}
                                      contain
                                      alt={i.title}
                                    >
                                      {i.description && <p>{i.description}</p>}
                                    </Img>
                                  </ImageWrapper>
                                ))}
                              </ImagesContainer>
                            )}
                            {textElement?.text && (
                              <RichTextWrapper>
                                <RichTextGeneral
                                  fragment
                                  textContent={textElement?.text}
                                />
                              </RichTextWrapper>
                            )}
                          </div>
                        </TextElementWrapper>
                      ))}
                    </TextElementContainer>
                  )}

                  {props.children}
                  <Articles
                    connectedArticles={connectedArticles}
                    footnote={page?.footnote}
                  />
                </ElementsWrapper>
              </ContentWrapper>
              {p?.slug === "material" && <LinkBannerTeaser />}
            </>
          )}
          <Divider />
        </ContentContainer>
      </Section>
    </Container>
  );
};

export default MainContent;
