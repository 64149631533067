import React, { FC, useContext, useState } from "react";
import { Context, IAsset } from "src/common";

import FullscreenImage from "../../../common/FullscreenImage";
import RichText from "../../../common/RichText";
import * as styles from "./NewsArticleItem.style";
import { Wrapper, HeadingWrapper } from "./NewsArticleItem.style";

interface IProps {
  id: string;
  headline: string;
  text: any;
  index: number;
  images: IAsset[];
  date: string;
}

const NewsArticleItem: FC<IProps> = ({
  headline,
  text,
  index,
  images,
  date,
  id,
}) => {
  const p = useContext(Context);
  const [isActive, setIsActive] = useState(index === 0);
  if (!headline || !text) {
    return null;
  }
  return (
    <a href={`/${p.NewsSlug || p.PageNews?.slug}/${id}`} title={`Zum Artikel`}>
      <Wrapper>
        <div
          css={`
            ${styles.imgAndText} ${styles.isActive}
          `}
        >
          <div css={styles.imgAndSubtitleWrapper}>
            {images?.length && (
              <div className="image">
                {images?.[0] && (
                  <>
                    <FullscreenImage
                      img={[
                        {
                          ...images?.[0],
                          src: images?.[0].src + "?w=600&h=400",
                        },
                      ]}
                      subtitle={images?.[0]?.description}
                    />
                  </>
                )}
              </div>
            )}
          </div>
          <div className="text">
            <HeadingWrapper onClick={() => setIsActive(!isActive)}>
              <h3 className="title">{headline}</h3>
              <p>{date}</p>
            </HeadingWrapper>
            <RichText textContent={text} />
          </div>
        </div>
      </Wrapper>
    </a>
  );
};

export default NewsArticleItem;
