import { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const wrapper: any = css`
    width: 100%;
    margin-bottom: 25px;
    display: none;
    li {
        background-color: ${props => props.theme.palette.bgLight};
        border-radius: 10px;
        margin-bottom: 3px;
        a {
          display: block;
          padding: 10px;
          color: black;
        }
        a:hover {
          color: ${props => props.theme.palette.red};
        }
    }
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        display: block;
    }
`;

export const active: any = css`
    color: ${props => props.theme.palette.red} !important;
`;
