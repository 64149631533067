import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const ContentContainer: any = styled.div<{topComponent: boolean}>`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${({ topComponent }) => !topComponent && "max-width: 720px;"}
    margin: ${({ topComponent }) => topComponent ? "0 auto" : "50px auto"};
    padding: ${({ topComponent }) => topComponent ? "0" : "0 10px"};
    .ps-offer {
      color: #222222!important;
    }
    @media (${BREAKPOINTS_DM.silver_768}) {

    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &,
      span {
        color:  ${({ theme }) => theme?.palette?.primary};
      }
    }

    h1,
    h2,
    h3 {
        &,
        span {
            font-weight: 400;
        }
    }
    h1 {
        &,
        span {
            font-weight: 400;
            font-size: 1.6rem;
        }
    }
    span {
      font-weight: 400;
    }
    p {
      font-weight: 400;
      &,
      * {
        color:  ${({ theme }) => theme?.palette?.textColor};
      }
    }
    > div > div > div {
      max-width: 100%;
    }
`;

export const ContentWrapper: any = styled.div<{topComponent: boolean}>`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-left: ${({ topComponent }) => topComponent ? "0" : "-15px"};
  margin-right: ${({ topComponent }) => topComponent ? "0" : "-15px"};
  [data-gallery] {
    > div > div {
      margin: 0;
    }
    [data-caption] {
      width: 0;
    }
    [data-image] {
      width: 100%;
      margin: 5px;
    }
  }

  [data-gallery=mosaicimage] {
    [data-caption] {
      width: 100%;
    }
    [data-image] {
      width: 100%;
      margin: 5px;
      > div > div > img {
        height: 300px;
      }
    }
  }
`;

export const sideBarWrapper: any = css`
  width: 100%;

  @media (${BREAKPOINTS_DM.gold_1024}) {
    max-width: 224px;
    padding-left: 20px;
  }
`;

export const TextElementWrapper: any = styled.div<{ column?: boolean; news: boolean }>`
  padding-bottom: 30px;

  ${({ column }) => column && `
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
  `}

  > div:first-of-type {
    height: auto;
    > img {
        height: auto;
    }

    ${({ column }) => !column
    ? `
      float: left;
      margin: 0 20px 20px 0;
      max-width: 33%;
    `
    : `
      margin-bottom: 30px;
    `}
  }

  a {
    display: inline-block;
    padding: 6px 30px;
    border-radius: 17px;
    line-height: 1;
    border-width: 2px;
    color: #333;
    border: 2px solid #ccc;
    background-color: #e5e5e5;

    &,
    * {
      font-weight: 600;
      font-size: 11px;
      letter-spacing: .3px;
    }
    :hover {
      background-color: #e0e0e0;
      border-color: #adadad;
    }
  }

  h1 {
    &,
    * {
      font-size: 24px;
      color: #222 !important;
    }
  }

  em {
    font-style: ${({ news }) => news && "normal!important"};
  }
`;

export const figcaption: any = css`
  text-align: center;
  background-color: rgba(255,255,255,0.5);
  padding: 10px 10px;
`;

export const UniversaElementWrapper: any = styled.div`
  margin-top: 15px;
  width: 100%;

  [data-gallery] {
    img {
      min-height: 80px;
    }
  }

`;

export const Title: any = styled.div`
  margin: 60px 0 0;
  h1 {
    margin: 50px 0 10.5px;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 48.16%;
    h1 {
      margin: 0 0 10.5px;
    }
  }

`;

export const Frame: any = styled.iframe`
  width: 100%;
  height: 100vh;
`;
