import styled, { css } from "styled-components";

export const MicroWrapper: any = styled.div`
  margin-top: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  iframe {
    width: 100%;
    height: 100vh;
    max-width: 980px;
  }
`;
