import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

import { SRichTextGeneral } from "../../common/RichText";

export const ContentContainer: any = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    width: 100%;
    margin: 0 auto;
    padding: 100px 10px 0;
    color: #5E5E5D;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &,
      span {
        color:  #F5E5E5D !important;
      }
    }

    h1,
    h2,
    h3 {
        &,
        span {
            font-weight: 400;
        }
    }
    h1 {
        &,
        span {
            font-weight: 400;
            font-size: 22px;
        }
    }
    span {
      font-weight: 400;
      word-break: break-word;

    }
    p {
      margin-bottom: 20px;
      span {
      font-weight: 300;
      font-size 16px;
      line-height: 30px;
      }
    }
   
    a > span {
      color: #5E5E5D;
      font-size: 12px;
      line-height: 30px;
      font-weight: 700;
    }
    strong {
      color: #5E5E5D;
    }
    
    ul {
      list-style: disc;
      margin-left: 15px;
    }
    li{
      list-style: initial;
      line-height: 30px;
      display: list-item;
      margin-bottom: 20px;
      span {
        font-weight: 300;
        font-size 16px;
        line-height: 30px;
        }
    }
    @media (${BREAKPOINTS_DM.gold_1024}) {
      padding: 100px 100px;
    }
    @media (${BREAKPOINTS_DM.platinum_1366}) {
      max-width: 1220px;
      padding: 100px 0;
    }
`;

export const ContentWrapper: any = styled.div`
  padding: 50px 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    padding: 100px 0;
  }
  @media (${BREAKPOINTS_DM.diamond_1920}) {
  }
`;

export const sideBarWrapper: any = css`
  width: 100%;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    max-width: 224px;
    padding-left: 20px;
  }
`;

export const sideNavWrapper: any = css`
  width: 100%;
`;

export const imageInText: any = css`
  > div:first-of-type {
    height: auto;
    > img {
      height: auto;
    }
    float: left;
    margin: 0 20px 20px 0;
    max-width: 33%;
  }
`;

export const figcaption: any = css`
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 10px 10px;
`;

export const UniversaElementWrapper: any = styled.div`
  margin-top: 15px;
`;

export const Title: any = styled.div`
  margin: 60px 0 0;
  h1 {
    margin: 50px 0 10.5px;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 48.16%;
    h1 {
      margin: 0 0 10.5px;
    }
  }
`;

export const Wrapper: any = styled.div`
  width: 100%;
  background: transparent url("/images/bg_paper.png") repeat -100px 0;
  background-size: 60%;
`;

export const WrapperImage: any = styled.div`
  width: 100%;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    margin: auto;
    width: 33%;
  }
  p {
    color: #fff;
    background-color: #bcbcbc;
    line-height: 29.3333px;
    font-weight: 300;
  }
`;

export const WrapperImageFull: any = styled.div<{ row: boolean }>`
  width: 100%;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    ${({ row }) =>
      row
        ? `
    width: 33.3%;
    margin-bottom: 15px;
    div > img {
      width: 393.33px;
      height: 230px;
    }
    `
        : `
      padding-right: 20px;
    `};
  }
  p {
    color: #fff;
    background-color: #bcbcbc;
    line-height: 29.3333px;
    font-weight: 300;
  }
`;
export const WrapperImageInText: any = styled.div<{ column: boolean }>`
  display: flex;
  flex-direction: column;
  height: auto;

  width: 100%;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    ${({ column }) =>
      column
        ? `
      flex-direction: column-reverse;
      `
        : `
      margin: 0 20px 20px 0;
      flex-direction: row;
      `}
  }
`;

export const MainHead: any = styled.div`
  width: 100%;
  display: flex;
  span.main_head {
    font-family: franklin_gothic_fs_medregular, Helvetica, Arial, sans-serif;
    padding: 0 0 25px 50px;
    color: #f19024;
    font-size: 40px;
    font-weight: 600;
    line-height: 46px;
    margin-bottom: 21px;
  }
  img {
    width: 39px;
    height: 65px;
  }
`;

export const TextElementWrapper: any = styled.div`
  width: 100%;
  h1 {
    &,
    span {
      font-family: franklin_gothic_fs_bookRg, Helvetica, Arial, sans-serif;
      font-weight: 700;
      font-size: 26px;
      color: #f19024;
      line-height: 36px;
    }
  }
`;

export const ImageContent: any = styled.div<{ column: boolean; row: boolean }>`
  width: 100%;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    width: 35%;
    ${({ column }) => column && "margin: 0 auto; width: 35%;"};
    ${({ row }) =>
      row &&
      `
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
     
    `};
  }
`;
export const TextContent: any = styled.div<{ full: boolean }>`
  width: 100%;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    width: ${({ full }) => (full ? "100%" : "65%")};
  }
  a {
    color: #f19024;
    text-decoration: none;
    /* color: var(--color-primary); */
  }
`;

export const Frame: any = styled.iframe`
  width: 100%;
  height: 100vh;
`;
