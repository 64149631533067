import React, { FC, useContext } from "react";
import { Context, getPage, IAsset } from "src/common";

import Gallery from "../../common/Gallery";

const Hero: FC<any> = (props) => 
{
  const p = useContext(Context);
  const page = getPage(p);

  // @ts-ignore
  const imgArray = page?.elements?.filter((e) => e?.__typename === "Asset") as IAsset[];
  // @ts-ignore
  const slider = page?.elements?.filter((e) => e?.__typename === "Slider") as any[];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const showSlider = slider && (slider.length > 0 || slider?.[0]?.elements.length > 0);
  let imageOrGallery = null;
  if(imgArray?.[0] || showSlider) 
  {
    if(imgArray.length > 1 || showSlider) 
    {
      if(slider) 
      {
        imageOrGallery = <Gallery imgArray={slider?.[0]?.elements} />;
      }

    }
  }

  const TopComponent = props.topComponent;

  return (
    <>
      {TopComponent ? (
        <TopComponent />
      ) : (
        <>
          {imageOrGallery && (
            <div style={{ marginBottom: "20px" }}>
              {imageOrGallery}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Hero;
