import React, { FC } from "react";
import styled from "styled-components";

export const ItemDefaultWrapper: FC<{ isProduct?: boolean }> = styled.div<{ isProduct?: boolean }>`
  h2 {
    font-size: 21px;
    line-height: 1;
    margin-bottom: 10.5px;
  }

  a {
    display: block;

    :hover {
      > div > span {
        display: block !important;
      }
    }
  }

`;

export const ImageWrapper: FC<{ isProduct?: boolean }> = styled.div<{ isProduct?: boolean }>`
  position: relative;
  ${({ isProduct }) => isProduct && `
    height: 100%;
    background: white;

    img {
      object-fit: contain !important;
    }

    span {
      display: none !important;
      position: absolute;
      left: calc(50% - 75px);
      top: calc(50% - 12px);
      width: 150px;
      text-align: center;
      background-color: #fff;
      border: 2px solid #fff;
      font-weight: 600 !important;
      font-size: 11px;
      letter-spacing: .3px;
      padding: 6px 30px;
      border-radius: 17px;
      line-height: 11px;

      :hover {
        background-color: #adadad;
        border: 2px solid #adadad;
        color: #fff;
      }
    }

    :hover {
      span {
        display: block !important;
      }
    }
  `}
`;

export const Description: FC<{ isProduct?: boolean }> = styled.div<{ isProduct?: boolean }>`
  background: ${({ theme }) => theme?.palette?.productBg};
  padding: 10.5px 0;
  color: #F8F8F8;
  font-size: 15px;
  line-height: 21px;

  svg {
    vertical-align: middle;
    height: 15px;
    fill: #F8F8F8;
    width: 15px;
  }

  > div {
    font-size: 15px;
    font-weight: 600;
    margin: 0 0 10px 0;
  }
`;

export const Price: FC = styled.div`
  display: flex;
  justify-content: space-between;
  > span {
    font-weight: 700;
  }
`;