import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const Wrapper: any = styled.div`
  width: 100%;
  max-width: 950px;
  h1, h3 {
    color: #777878;
    font-size: 32px;
    font-weight: 400;
    line-height: 36px;
    margin-bottom: 21px;
  }
  strong {
    color: #777878;
  }
  a > span {
    color: #777878;
  }
  a {
    text-decoration-color: #777878;
  }
  img {
     width: 100%;
    height: auto;
    @media (${BREAKPOINTS_DM.silver_768})
    {
        height: auto;
        margin: 0 auto;
    }
  }
  @media (${BREAKPOINTS_DM.silver_768})
  {
      margin: 57.5px auto 90px;
      padding: 0 30px;

  }
`;

export const Footnote: any = styled.div`
    margin: 40px 0 20px 0;
`;

export const ImgAndHeadline: any = css`
    display: flex;
    flex-direction: column;
    @media (${BREAKPOINTS_DM.silver_768})
    {
        flex-direction: row;
        align-items: center;
    }
`;

export const ImgContent: any = styled.div`
    diplay: flex;
    flex-direction: column;
    width: 100%;
    img {
      margin: 10px 0;
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
      flex-wrap: wrap;
      flex-direction: row;
      img {
        width: 30%;
        margin: 10px 20px 10px 0;
      }
    }
`;
