import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const Wrapper: any = styled.div`
  display: none;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 21px 0;
  }
  
`;

export const CardWrapper: any = styled.div`
  width: 48.9%;
  background-color: rgb(51, 51, 51);
  height: fit-content;
  h1.title {
    display: flex;
    align-items: center;
    color: #FA8800;
    font-size: 19.6px;
    font-weight: 400;
    line-height: 27.44px;
    padding: 10px;
    margin: 0;
  }
  div.icon {
    display: flex;
    align-items: center;
    color: #FA8800;
    font-size: 19.6px;
    font-weight: 400;
    line-height: 27.44px;
    padding: 0 10px 0 0;
  }
  div.spacer {
    height: 140px;
  } 
`;

export const LinksWrapper: any = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-left: 21px;

`;

export const Link: any = styled.a`
  color: #fff;
  font-size: 12px;
  line-height: 29.9999px;
  font-weight: 300;
  &:hover {
    color: #FA8800;
  }
`;
