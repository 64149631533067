import * as React from "react";
import { FunctionComponent } from "react";
import RichText from "../RichText/RichText";
import SRichTextGeneral from "../RichText/Styles/SRichTextGeneral";
import * as styles from "./TextBoxRight.style";
import { css } from "styled-components";
interface IProps
{
    text?: string | null | undefined;
}

export const TextBoxRight: FunctionComponent<IProps> = props =>
{
    if(!props?.text)
    {
        return null;
    }

    return <div css={styles.wrapper}>
        <RichText css={(css`${SRichTextGeneral};${styles.richText}` as any)} textContent={props.text}/>
    </div>;
};
