import * as React from "react";
import { FunctionComponent } from "react";
import * as styles from "./SubNavRight.style";
import { IContext, Context, IExternalLink } from "src/common";

export const SubNavRight: FunctionComponent = () => {
  const props = React.useContext<IContext>(Context);
  const navItem = props?.PrimaryNavigation?.items
    ?.find(
      (i) =>
        !!i?.subitems?.find((s) => s?.slug === props.slug) ||
        i?.link?.[0]?.slug === props.slug
    )
    ?.subitems?.map((item, i) => {
      if ((item as unknown as IExternalLink).__typename === "ExternalLink") {
        return (
          <li key={i + "externalLink"}>
            <a
              target={"_blank"}
              href={`https://${`${
                (item as unknown as IExternalLink)?.externalLinkAddress
              }`
                .replace("https://", "")
                .replace("http://", "")}`}
            >
              {(item as unknown as IExternalLink)?.externalLinkName ?? ""}
            </a>
          </li>
        );
      }
      return (
        <li key={`${i}${item?.slug}`}>
          <a
            href={`/${item?.slug}`}
            css={`
              ${item?.slug === props?.slug ? styles.active : null}
            `}
          >
            {item?.nameInNavigation || ""}
          </a>
        </li>
      );
    });

  return navItem && navItem.length >= 1 ? (
    <div css={styles.wrapper}>{navItem}</div>
  ) : null;
};
