import React, { FC } from "react";
import { IContext, Context } from "src/common";
import { css } from "styled-components";
import { richToTeaser } from "../../../../../common/utils/text/teaser";

import {
  Wrapper,
  SubNavigationCard,
  SubNavName,
  SubNavHeadLine,
  TextWrapper,
  Button,
} from "./SubNavigation.style";

const SubNavigation: FC = () => {
  const props = React.useContext<IContext>(Context);
  const navItem = props?.PrimaryNavigation?.items
    ?.find(
      (i) =>
        !!i?.subitems?.find((s) => s?.slug === props.slug) ||
        i?.link?.[0]?.slug === props.slug
    )
    ?.subitems?.filter((item) => item);

  if (!navItem) {
    return null;
  }
  return (
    navItem &&
    navItem.length >= 1 && (
      <Wrapper>
        {navItem.map((item, i) => {
          const text =
            item?.elements && item?.elements[1]?.__typename === "TextElement"
              ? item?.elements[1]
              : null;
          if (item?.slug === props.slug) {
            return;
          }
          const teaser = richToTeaser(text?.text);
          return (
            <SubNavigationCard key={`${i}${item?.slug}`}>
              <a href={`/${item?.slug}`}>
                <SubNavName>{item?.nameInNavigation || ""}</SubNavName>
                <SubNavHeadLine>{text?.headline}</SubNavHeadLine>
                <TextWrapper>
                  {teaser && teaser != "" && (
                    <p>
                      {teaser.length > 82
                        ? teaser?.substring(0, 82) + "..."
                        : teaser}
                    </p>
                  )}
                </TextWrapper>
                <div
                  css={css`
                    height: 50px;
                    width: 50px;
                  `}
                ></div>
              </a>
              <a href={`/${item?.slug}`}>
                <div
                  css={css`
                    height: 50px;
                    width: 50px;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                  `}
                >
                  <Button>
                    <div>
                      <i className="fas fa-chevron-right" />
                    </div>
                  </Button>
                </div>
              </a>
            </SubNavigationCard>
          );
        })}
      </Wrapper>
    )
  );
};

export default SubNavigation;
