import React, { FunctionComponent, Fragment, useContext, useState } from "react";
import { Context, getPage, ITextElement, IAsset, Img, IPhotoGallery, IContinuingPageGallery } from "src/common";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";
import UniversalComponentsRenderer from "src/common/BaseComponents/UniversalCompoentRenderer/UniversalComponentsRenderer";

import Articles from "../../common/Articles";
import Micro from "../../common/Micro";
import PlanningDateModaL from "../../common/PlanningDateModaL";
import ProductItem from "../../common/ProductItem";
import { RichTextGeneral } from "../../common/RichText";
import NewsContent from "../NewsContent";
import Hero from "./Hero";
import * as styles from "./MainContent.style";
import {
    ContentContainer,
    ContentWrapper,
    UniversaElementWrapper,
    ElementWrapper,
    ImageInText,
    Title,
    ImageWRapper,
    ImageContent,
    TextWrapper,
    MixedWrapper,
    FirstTextElementWrapper,
    Wrapper,
    NavBarSubitemsWrapper,
    NavBarSubitemsContent,
    NavBarSubitemsButton,
    HorizontalSeparator,
    UniversalElementContent,
} from "./MainContent.style";
import PhotoGallery2024 from "src/common/components/PhotoGallery2024/PhotoGallery2024";

const fixColumns: (visualization: string) => number = (visualization) => {
    switch (visualization) {
        case "fadeeffect":
            return 2;
        case "flipeffect":
            return 2;
        case "zoomeffect":
            return 2;
        default:
            return 3;
    }
};

const fixDataGallery: (visualization: string) => boolean = (visualization) => {
    switch (visualization) {
        case "gallerywithimagedescriptionundermainimages":
            return true;
        case "standard":
            return true;
        case "slideshowwithoutthumbnails":
            return true;
    }
};

const TextElement: FunctionComponent<any> = ({ firstTextElement, textElement, columnCenter }) => {
    const subTitle = textElement?.text?.content.length > 1 && {
        content: [textElement?.text?.content[0]],
    };
    const text =
        textElement?.text?.content.length > 1
            ? {
                  content: textElement?.text?.content.slice(1, textElement?.text?.content.length),
              }
            : textElement?.text;
    const xText = { ...text, type: "doc" };
    const xSubTitle = { ...subTitle, type: "doc" };

    return (
        <>
            {<Title isFirst={firstTextElement === textElement}>{textElement?.headline}</Title>}
            <ImageInText reverse={textElement?.icon?.length > 1}>
                {subTitle && (
                    <div>
                        {textElement?.text && (
                            <TextWrapper withImg={textElement?.icon?.length} subTitle={true}>
                                <RichTextGeneral fragment textContent={xSubTitle} />
                            </TextWrapper>
                        )}
                    </div>
                )}
                <div>
                    {textElement?.icon?.length > 0 && (
                        <ImageWRapper center={textElement?.icon?.length < 2 && columnCenter}>
                            {textElement?.icon?.map((i, index) => {
                                if (i.src) {
                                    return (
                                        <ImageContent key={`i${index}`}>
                                            <Img quality={1020} src={[i]} contain alt={i.title} />
                                            {i.subtitle && <p css={styles.figcaption}>{i.title}</p>}
                                        </ImageContent>
                                    );
                                }
                                return null;
                            })}
                        </ImageWRapper>
                    )}
                </div>
                <div>
                    {textElement?.text && (
                        <TextWrapper withImg={textElement?.icon?.length}>
                            <RichTextGeneral fragment textContent={xText} />
                        </TextWrapper>
                    )}
                </div>
            </ImageInText>
        </>
    );
};

const MainContent: FunctionComponent<any> = (props) => {
    const p = useContext(Context);
    const page = getPage(p);
    const [isShowModal, toogleShowModal] = useState(false);

    // @ts-ignore
    const mixedElements = page?.elements?.filter(
        (e) =>
            e.__typename?.includes("ContinuingPageGallery") ||
            e.__typename === "PhotoGallery" ||
            e.__typename === "TextElement" ||
            e.__typename === "Asset" ||
            e.__typename === "CallToAction"
    ) as any[];
    const connectedArticles = (page as any)?.connectedArticles;

    const navBarSubitems = p.PrimaryNavigation?.items
        ?.map((item) => item.subitems?.length > 0 && item.link?.[0]?.slug === p.slug && item)
        .filter((item) => item);

    const navBarSubitemsSecondary = p.PrimaryNavigation?.items
        ?.map((item) => item.subitems?.length > 0 && item.subitems)
        .filter((item) => item)
        .map((item) => item.find((element) => element?.slug === /* "finanzierung"*/ p?.slug) && item)
        .find((item) => item);

    const TopComponent = props.topComponent;
    const firstTextElement = mixedElements?.find((element) => element.__typename === "TextElement");
    const isWithoutHero = mixedElements?.length <= 1 && !p?.PageNews;
    const isUniversalElement = mixedElements?.find((element) => element.__typename === "ContinuingPageGallery") && true;
    const columnCenter = mixedElements
        ?.filter((item) => item?.__typename === "TextElement")
        .find((element) => element?.icon?.length > 1);
    const actionButton = mixedElements?.find((element) => element.__typename === "CallToAction");

    return (
        <Wrapper isUniversalElement={isUniversalElement || TopComponent || props.guestBook}>
            {p?.PageMicro?.externalLinkAddress && <Micro />}
            <Hero topComponent={TopComponent} pageBrand={props.pageBrand} />
            {isShowModal && <PlanningDateModaL onSetShowModal={toogleShowModal} />}

            {(navBarSubitems?.length > 0 || navBarSubitemsSecondary?.length > 0) && (
                <NavBarSubitemsWrapper>
                    <NavBarSubitemsContent>
                        {navBarSubitems[0]?.subitems?.length > 0 &&
                            navBarSubitems[0].subitems.map((subitem, i) => {
                                const _subx = subitem as any;
                                if (_subx.__typename === "ExternalLink") {
                                    return (
                                        <NavBarSubitemsButton
                                            href={
                                                "https://" +
                                                `${_subx?.externalLinkAddress}`
                                                    .replace("http:", "https:")
                                                    .replace("https://", "")
                                            }
                                            target="_blank"
                                            key={i}
                                        >
                                            {_subx?.externalLinkName ?? ""}
                                            {i !== navBarSubitems[0].subitems.length - 1 && <HorizontalSeparator />}
                                        </NavBarSubitemsButton>
                                    );
                                }
                                return (
                                    <NavBarSubitemsButton href={`/${subitem?.slug}`} key={i}>
                                        {subitem?.nameInNavigation}
                                        {i !== navBarSubitems[0].subitems.length - 1 && <HorizontalSeparator />}
                                    </NavBarSubitemsButton>
                                );
                            })}

                        {navBarSubitemsSecondary?.length > 0 &&
                            navBarSubitemsSecondary.map((subitem, i) => (
                                <NavBarSubitemsButton
                                    href={`/${subitem?.slug}`}
                                    key={i}
                                    isCurrent={subitem?.slug === p.slug}
                                >
                                    {subitem?.nameInNavigation}
                                    {i !== navBarSubitemsSecondary.length - 1 && <HorizontalSeparator />}
                                </NavBarSubitemsButton>
                            ))}
                    </NavBarSubitemsContent>
                </NavBarSubitemsWrapper>
            )}

            {firstTextElement && !props.news && (
                <FirstTextElementWrapper withoutHero={isWithoutHero}>
                    <ImageInText
                        column={firstTextElement && true}
                        isUniversalElement={isUniversalElement}
                        firstTextElement={firstTextElement && true}
                    >
                        {!props.guestBook &&
                            firstTextElement?.icon?.map((i, index) => {
                                if (i.src) {
                                    return (
                                        <Fragment key={`i${index}`}>
                                            <Img quality={1020} src={[i]} contain alt={i.title}>
                                                {i.title && <p css={styles.figcaption}>{i.title}</p>}
                                            </Img>
                                        </Fragment>
                                    );
                                }
                                return null;
                            })}
                        {firstTextElement?.text && (
                            <TextWrapper withImg={firstTextElement?.icon?.length}>
                                <RichTextGeneral fragment textContent={firstTextElement?.text} />
                            </TextWrapper>
                        )}
                    </ImageInText>
                </FirstTextElementWrapper>
            )}
            <ContentContainer>
                <ContentWrapper pageBrand={props.pageBrand}>
                    {!props.news && (
                        <MixedWrapper>
                            {mixedElements &&
                                mixedElements.map((e, i) => {
                                    const textElement = e.__typename === "TextElement" && (e as ITextElement);
                                    const imageElement = TopComponent && e.__typename === "Asset" && (e as IAsset);
                                    const universlElement =
                                        e.__typename?.includes("ContinuingPageGallery") &&
                                        (e as IContinuingPageGallery);
                                    const photoGallery = e.__typename === "PhotoGallery" && (e as IPhotoGallery);
                                    const isFirstElement = firstTextElement === textElement;

                                    if (isFirstElement && i == 0) {
                                        return null;
                                    }

                                    if (isFirstElement && i !== 0) {
                                        return <div style={{ marginTop: "-41px" }} key={`mixedElements${i}`} />;
                                    }
                                    return (
                                        <ElementWrapper
                                            key={`mixedElements${i}`}
                                            withOutBorder={props.guestBook || (actionButton && true)}
                                            guesBook={props.guestBook}
                                        >
                                            {imageElement && (
                                                <ImageInText>
                                                    <Img
                                                        alt={imageElement?.title}
                                                        src={[imageElement]}
                                                        quality={1080}
                                                    />
                                                </ImageInText>
                                            )}
                                            {textElement && (
                                                <TextElement
                                                    firstTextElement={firstTextElement}
                                                    textElement={textElement}
                                                    columnCenter={columnCenter}
                                                />
                                            )}

                                            {universlElement && (
                                                <UniversaElementWrapper universlElement={universlElement}>
                                                    {universlElement.__typename?.includes("ContinuingPageGallery") ? (
                                                        <>
                                                            <UniversalComponentsRenderer
                                                                shouldRenderNewPhotoGallery
                                                                items={[universlElement].map((ue: any) => ({
                                                                    ...ue,
                                                                    __typename:
                                                                        ue.__typename == "ContinuingPageGalleryRoot"
                                                                            ? "ContinuingPageGallery"
                                                                            : ue.__typename,
                                                                }))}
                                                            ></UniversalComponentsRenderer>
                                                            <Spacer bronze={20} silver={30}></Spacer>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <UniversalComponentsRenderer items={[universlElement]}>
                                                                {({ item }) => <ProductItem item={item} />}
                                                            </UniversalComponentsRenderer>
                                                            <Spacer bronze={20} silver={30}></Spacer>
                                                        </>
                                                    )}
                                                </UniversaElementWrapper>
                                            )}

                                            {photoGallery && (
                                                <UniversalElementContent>
                                                    <UniversaElementWrapper
                                                        fixDataGallery={fixDataGallery(photoGallery?.visualization)}
                                                    >
                                                        <PhotoGallery2024
                                                            item={photoGallery}
                                                            columns={fixColumns(photoGallery?.visualization)}
                                                        />
                                                    </UniversaElementWrapper>
                                                    {actionButton && (
                                                        <div className="button-wrapper">
                                                            <div
                                                                className="button"
                                                                onClick={() => toogleShowModal(!isShowModal)}
                                                            >
                                                                {actionButton.ButtonLabel}
                                                            </div>
                                                        </div>
                                                    )}
                                                </UniversalElementContent>
                                            )}
                                        </ElementWrapper>
                                    );
                                })}
                        </MixedWrapper>
                    )}
                    {props.news && <NewsContent />}

                    <Articles connectedArticles={connectedArticles} footnote={page?.footnote} />
                </ContentWrapper>
            </ContentContainer>
            {props.children}
        </Wrapper>
    );
};

export default MainContent;
