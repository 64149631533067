import { BREAKPOINTS, BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

import { Container, ContainerLarge } from "../../common/Grid";

export const ContentContainer: any = styled.div`
    width: 100%;

    @media (${BREAKPOINTS_DM.silver_768}) {
      ${Container},
      ${ContainerLarge} {
        margin-bottom: 60px;

        div:first-child:last-child {
          flex: 1;
        }
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &,
      span {
        color:  ${({ theme }) => theme?.palette?.textColorHeadings} !important;
      }
    }

    h1,
    h2,
    h3 {
        &,
        span {
        }
    }

    p, li {
      &, span {
      }
    }

    table {
      tr{
        td {
          padding: 10.5px;
          width: auto !important;

          p{
            margin-bottom: 10px;

            span {
            }
          }
        }
      }
    }

    [data-heading] {
      a {
        &, span {
          font-size: 24px;
          font-weight: 300;
        }
      }
    }
`;

export const HeroContainer = styled.div`
  margin-top: 7px;
  margin-bottom: 42px;

  p {
    font-weight: 300;
    text-align: center;
  }
`;

export const ContentWrapper: any = styled.div<{ opaque?: boolean }>`
  width: 100%;

  ${({ opaque }) => opaque && "background-color: #f3f3f3;"}
`;

export const sideBarWrapper: any = css`
  width: 100%;

  @media (${BREAKPOINTS_DM.gold_1024}) {
    max-width: 224px;
    padding-left: 20px;
  }
`;

export const UniversaElementWrapper: any = styled.div`
  margin-top: 15px;
  width: 100%;

  [data-gallery] {
    > div {
      [data-aos] {
        display: flex;
        [data-caption] {
          display: none;
        }
      }

      :nth-child(odd) {
        [data-aos] {
          justify-content: flex-end;
        }
      }
    }
  }

   @media (${BREAKPOINTS_DM.silver_768}) {
    [data-gallery-slider] {
      [data-image] {
        img {
          height: 641px;
          object-fit: cover;
        }
      }


    }
    [data-thumnail-image] {
      height: 120px;
      padding-right: 7px;
      > div > img {
        opacity: .5;
        object-fit: cover;
      }
    }

    .slick-current {
      [data-thumnail-image] {
        > div > img {
          opacity: 1;
        }
      }
    }
  }
`;

export const AssetContainer: any = styled.div`
  margin-bottom: 20px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    > div > img {
      height: 580px;
    }
  }
`;

export const TextWrapper: any = styled.div`
  @media (${BREAKPOINTS_DM.silver_768}) {
  }
`;

export const ImageWrapper: any = styled.div<{ count?: number }>`
  position: relative;

  img {
    height: auto;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: flex;
    justify-content: space-between;

    > div {
      margin-top: 0;
      flex: 1;
      img {
        min-height: 386px;
      }
    }
  }

  p {
    background-color: ${({ theme }) => theme?.palette?.bgLight};
    padding: 10.5px;
    font-size: 13px;
  }

  :hover {
    :after {
      opacity: .5;
    }
  }
`;

export const TextContainer: any = styled.div<{ centered?: boolean; count?: number; rtl?: boolean; bulletList?: boolean }>`
  text-align: ${({ centered }) => centered ? "center" : "left"};

  h1, h2 {
    text-transform: uppercase;
    font-weight: 300;
    font-size: ${({ centered }) => centered ? "24px" : "21px"};
    text-align: ${({ centered }) => centered ? "center" : "left"};
    letter-spacing: .02em;
    line-height: 21px;
    margin-bottom: 14px;
  }

  p {
    ${({ centered }) => !centered && "margin-bottom: 14px"};

    a:first-child {
      color: #3c3c3b;
      line-height: 42px;
      font-size: 28px;
    }
  }

  ul, ol, p {
    margin-bottom: 21px;
  }

  ul, ol {
    li {
      line-height: 21px;
      > p {
        margin: 0;
      }
    }
  }
  @media (max-width: ${BREAKPOINTS.SILVER}px) {
    
    .c-frame {
      max-width: 100%;

      iframe {
        width: 100%;
      }
    }
  }


  ${({ count, rtl }) => {
    switch (count) {
      case 1:
        return `
            padding-top: 38px;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto 340px;

            ${TextWrapper} {
              grid-column: 1;
              grid-row: 1;
            }

            ${ImageWrapper} {
              grid-column: 1;
              grid-row: 2;
            }
          `;
      case 2:
        return `
            padding-top: 38px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: auto;
            column-gap: 21px;

            ${TextWrapper} {
              grid-column: ${rtl ? "1" : "3"};
              grid-row: 1;
            }

            grid-auto-flow: row;
          `;

      case 3:
      case 4:
        return `
            padding-top: 38px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: auto 340px 158px;
            column-gap: 21px;

            ${TextWrapper} {
              grid-column: 1 / span 3;
              grid-row: 1;
            }

            ${ImageWrapper} {
              :first-child {
                grid-column: 1 / span 3;
                grid-row: 2;
                margin-bottom: 28px;
              }
            }
            grid-auto-flow: row;
          `;
    }
  }}

  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-wrap: wrap;
    justify-content: ${({ centered }) => centered ? "center" : "space-between"};
    ${({ centered, bulletList }) => centered && bulletList && `
      width: 33.33%;
      margin: auto;
    `}
    h1, h2 {
      text-transform: uppercase;
      font-weight: 300;
      font-size: ${({ centered }) => centered ? "24px" : "21px"};
      text-align: ${({ centered }) => centered ? "center" : "left"};
      letter-spacing: .02em;
      line-height: 1.75;
      margin-bottom: 1.5rem;
    }

    ${({ count, rtl }) => {
      switch (count) {
        case 1:
          return `
            padding: 28px 0;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: 480px;
            column-gap: 21px;

            ${TextWrapper} {
              grid-column: ${rtl ? "1" : "3"};
              grid-row: 1;
            }

            ${ImageWrapper} {
              grid-column: ${rtl ? "2 / span 2" : "1 / span 2"};
              grid-row: 1;
            }
          `;
        case 2:
          return `
            padding: 28px 0;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: auto;
            column-gap: 21px;

            ${TextWrapper} {
              grid-column: ${rtl ? "1" : "3"};
              grid-row: 1;
            }

            ${ImageWrapper} {
              img {
                height: 386px;
              }
            }
            grid-auto-flow: row;
          `;

        case 3:
        case 4:
          return `
            padding: 28px 0;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: 480px 386px;
            column-gap: 21px;
            row-gap: 28px;

            ${TextWrapper} {
              grid-column: ${rtl ? "3" : "1"};
              grid-row: 1;
            }

            ${ImageWrapper} {
              :first-child {
                grid-column: ${rtl ? "1 / span 2" : "2 / span 2"};
                grid-row: 1;

                // Override mobile
                margin: 0;
              }
            }
            grid-auto-flow: row;
          `;
      }
    }}
  }
`;
