import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";
import styled, { css } from "styled-components";

import { Col } from "../../../BaseComponents/Grid";
import colors from "../../../constants/colors";

interface IContacProps {
  wfull?: boolean;
}

export const H2: any = styled.div`
  color: ${colors.black};
  font-family: Roboto,sans-serif;
  line-height: 1.4;
  font-weight: 600;
  letter-spacing: .02em;
  font-size: 18px;
  margin: 0px 0px 10px;
`;

export const H1: any = styled.div<IContacProps>`
  color: ${colors.dark_gray};
  font-family: Roboto,sans-serif;
  line-height: 1.4;
  font-weight: 600;
  letter-spacing: .02em;
  font-size: ${({wfull}) => wfull ? "20px" : "35px"};
  margin: ${({wfull}) => wfull ? "0px 15px 30px" : "0px 15px 0px;"};
`;

export const Separator: any = styled.hr`
  color: ${colors.graylight};
  margin: 50px 0px 50px;
  width:100%;
`;

export const FormWrapper: any = styled.div<IContacProps>`
margin: 60px 0px 0px;
  * {
    line-height: 140%;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
      margin: 90px 20px 40px;
  }

  @media (${BREAKPOINTS_DM.platinum_1366}) {
    margin: 60px 135px 40px;
  }

  @media (${BREAKPOINTS_DM.platinum_1366}) {
    margin: 60px 135px 40px;
  }

  strong {
    font-weight: 600;
  }
  div > p {
    font-family: Roboto,sans-serif;
    font-size: 15px;
    display: flex;
  }
`;

export const CompanyInfo: any = styled.div`
  margin: 0px 0px 0px 15px;

  [data-tel] {
    margin-top: 21px;
  }
`;

export const OfficeInfo: any = styled.div`
  margin: 0 15px;
  p {
    margin-left: -35px;
  }
  div > table {
    margin-left: -45px;
  }
  tr{
    border: none;
    td{
      border: none;
    }
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    p {
      margin-left: -25px;
    }
    div > table {
      margin-left: -35px;
    }
  }

  @media (${BREAKPOINTS_DM.platinum_1366}) {
    p {
      margin-left: -40px;
    }
    div > table {
      margin-left: -50px;
    }
  }
`;

export const directionsDescription: any = css`
  h6 {
      color: ${props => props.theme.palette.red};
      font-size: 19px;
  }
`;

export const contact: any = css`
  margin-top: 20px;
`;

export const SidebarText: any = styled.div`
  background-color: rgba(255,255,255,0.2);
  margin-top: 30px;
  padding: 10px 10px 15px;
  border-radius: 8px;
  max-width: 250px;
  br:first-child {
    margin-top: 0;
  }
  div > p:last-child {
    margin-bottom: 0;
  }
  strong p {
    margin-bottom: 0;
  }
`;

export const ContactForm: any = styled.div<IContacProps>`
  padding: 0px 10px;
  width: 100%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    margin: ${({ wfull }) => wfull ? "0px" : "0px 100px"};
  }
`;

export const inputsWrapper: any = css`
  width: 100%;
  input, textarea {
    width: 100%;
    display: block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  }
  input {
    height: 34px;
    padding: 6px 12px;
    border-radius: 4px 0px 0px 4px;
  }
  label {
    font-family: Roboto,sans-serif;
    font-size: 15px;
    font-weight: 600;
  }
  ${Col} {
    margin-bottom: 21px;
  }
`;

export const SubmitWrapper: any = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    width: 100%;
    display: flex;
    margin: 15px 10px 20px;

  }

  button {
    border: 2px solid ${colors.green}!important;
    color: ${colors.green};
    margin: 0px auto;
    display: block;
    padding: 4px 25px;
    width: fit-content;
    line-height: 1.2;
    background-color: #EFEFEF;
    border-radius: 4px;
    &:hover {
      background-color: ${colors.green};
      color: ${colors.white}
    }
  }
`;

export const datenschutzAkzeptierenWrapper: any = css`
  display: flex;
  max-width: 50%;
`;

export const datenschutzAkzeptieren: any = css`
  margin-left: 5px;
  font-family: Roboto,sans-serif;
  font-size: 15px;
  a {
    color: ${colors.green};
  }
`;

export const Centered: any = styled.div`
  width: 100%;
  display: flex:
  align-text: center;
  justify-content: center;
`;

export const TextGreen: any = styled.div`
  font-family: Roboto,sans-serif;
  color: ${colors.light_green};
  font-size: 15px;
  margin-left: 6px;
`;

export const Button: any = styled.div`
  margin: 10px 0px;
  width: fit-content;
  border: 2px solid ${colors.light_green};
  color: ${colors.light_green};
  border-radius: 4px;
  &: hover {
    background: ${colors.light_green};
    color: ${colors.white}
    }
  }
`;

export const ButtonText: any = styled.div`
  font-family: Roboto,sans-serif;
  font-size: 12px;
  padding: 4px 18px;
`;

export const CheckWrapper: any = styled.div`
  display: flex;
  align-items: center;
`;

export const sidebarExpandIconContainer: any = css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    height: auto;
    opacity: .9;
    background-color: #EFEFEF;
    border-radius: 0px 4px 4px 0px;
    border: 1px solid #ccc;
    &:hover {
      opacity: 1;
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
      width: 50px;
    };
`;

export const sidebarExpandIcon: any = css`
    height: 17px;
    opacity: .9;
    &:hover {
      opacity: 1;
    }
    margin-rigth: 10px;
`;

export const sidebar: any = css`
    display: flex;
`;

export const Container: any = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
  }
`;
