import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const MainContainer: any = styled.div`
  width: 100%;
  display: block;
`;

export const ContentContainer: any = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px auto;
    @media (${BREAKPOINTS_DM.gold_1024}) {
        flex-direction: row;
        padding: 0;
    }
    .c-frame {
      iframe {
        width: 280%;
        height: 364px;
      }
    }

    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      &,
      span {
        color:  #777878;

      }
    }

    h1,
    h2,
    h3 {
        &,
        span {
            font-weight: 300;
        }
    }

    h1 {
        width: fit-content;
        padding: 2px 10px 2px 0p;
        &,
        span {
            color: #777878;
            font-weight: 400;
            font-size: 32px;
            line-height: 36px;
        }
    }
    h2 > a > span {
      color:  #94c03e;
      font-size: 24px;
      font-weight: 300;
      line-height: 26px;
      &:hover {
        color: #404040;
      }
    }
    a > span {
      color: #777878;
    }
    ul {
      margin-left: 18px;
      li {
      list-style-position: outside;
      line-height: 26px;
    }
  }
`;

export const HeroWrapper: any = styled.div<{topComponent: boolean}>`
  position: relative;
  @media (${BREAKPOINTS_DM.silver_768}) {
    height: ${({ topComponent }) => topComponent ? "auto" : "600px"};
    > div > div {
      img {
        height: 600px;
      }
    }
  }
`;

export const ContentWrapper: any = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const sideBarWrapper: any = css`
  width: 100%;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    max-width: 224px;
    padding-left: 20px;
  }
`;

export const imageInText: any = css`
  > div:first-of-type {
    height: auto;
    > img {
        height: auto;
    }
    float: left;
    margin: 0 20px 20px 0;
    max-width: 33%;
  }
  margin: 0 10px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    margin: 0 30px;
  }
`;

export const figcaption: any = css`
  text-align: center;
  padding: 10px 10px;
`;

export const UniversaElementWrapper: any = styled.div`
  margin: 80px auto 100px;
  padding: 0 30px;
  max-width: 1400px;
`;

export const PhotoGalleryWrapper: any = styled.div<{visualization: string}>`
  margin: 80px auto 100px;
  max-width: 1400px;
  padding: 0 36px;

  [data-gallery] {
    width: 100%;
    justify-content: center;

    [data-aos] {
      [data-caption] {
        display: none;
      }
    }

    [data-image] {
      width: 100%;
      div {
        width: 100%;
        img {
          ${({ visualization }) => 
  {
    switch (visualization) 
    {
      case "mosaicimage":
        return `
          height: 300px;
          width: 340px;
          max-width: 100%;
        `;
    }
  }}
        }
      }
    }
  }
`;

export const ElementWrapper: any = styled.div`
`;

export const TextElementWrapper: any = styled.div<{withUniversalElement: boolean}>`
  margin: 0 auto 60px;
  @media (${BREAKPOINTS_DM.silver_768}) {

    max-width: 950px;
    margin: 0 auto 60px;
  }
  h1.title {
    margin: 50px 30px 33px;
    ${({ withUniversalElement }) => withUniversalElement && `
      color: #000;
      font-size: 32px;
    `}
  }
`;

export const SubNavigationWrapper: any = styled.div`
  width: 100%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    margin: auto;
    max-width: 1400px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 30px;
  }
`;

export const SubNavigationItem: any = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(221, 223, 223);
  width: 90%;
  height: 182px;
  margin: 10px auto;
  a {
    font-size: 24px;
    color: #777878;
    font-weight: 300;
    line-height: 31.5px;
    &:hover {
      color: #94c03e;
    }
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 30.5%;
    margin: 0 0 30px 30px;
  }
`;
