import styled, { css } from "styled-components";

export const Wrapper: any = styled.div`
  width: 100%;
  img{
    max-height: 500px;
    object-fit: contain;
  }
  .slick-cloned{
    padding-left: 2px;
  }
  .slick-prev,
  .slick-next {
    z-index: 9;
    background-color: rgba(255,255,255,.5) !important;
    width: 30px !important;
    height: 30px !important;
    :before {
      opacity: 1;
      display: inline-block;
      content: "" !important;
      background-image: url(/images/sprite-sa3ca3ca63e.png);
      background-repeat: no-repeat;
      background-position: 0 -172px;
      height: 18px;
      width: 12px;
      color: transparent !important;
    }

    :hover {
      background-color: rgba(255,255,255,.5);
    }
  }

  .slick-next {
    right: 0 !important;
  }

  .slick-prev {
    left: 0 !important;
    :before {
      transform: rotate(180deg);
    }
  }

  .slick-slide{
    padding-top: 6px;
    padding-right: 6px;
    &:not(.slick-current) {
      opacity: .5;
    }
  }

`;

export const Figcaption: any = styled.figcaption`
  text-align: center;
  background-color: rgba(255,255,255,0.5);
  padding: 10px 10px;
`;