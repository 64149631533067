import React, { useContext, Fragment, FC } from "react";
import { IAsset, Context, getPage, getCurrentNavItem, IPageBrand, FullscreenImage } from "src/common";
import UniversalComponentsRenderer from "src/common/BaseComponents/UniversalCompoentRenderer/UniversalComponentsRenderer";
import { css } from "styled-components";
import { richToPlainText } from "../../../../../common/utils/text/plaintext";
import ImageWithAspectRatio from "../../../BaseComponents/ImageWithAspectRatio/ImageWithAspectRatio";
import Img from "../../../BaseComponents/Img/Img";
import RichText from "../../../BaseComponents/RichText/RichText";
import { ImgGallery } from "../../common/imgGallery/ImgGallery";
import LandingImage from "../../common/landingImage/LandingImage";
import { TeaserGallery } from "../../common/teaserGallery/TeaserGallery";
import Micro from "../Micro";
import { TeaserGalleryBig } from "../TeaserGalleryBig/TeaserGalleryBig";
import { TeaserGallerySmall } from "../TeaserGallerySmall/TeaserGallery";
import * as styles from "./Slug.style";
import {
    PageContentWrapper,
    Wrapper,
    TwoColumnLayout,
    FlexWrapper,
    Heading,
    InlineImage,
    Article,
    ArticleImageWrapper,
    UniversalComponentsRendererWrapper,
    TopComponentWrapper,
    CenteredContent,
    ImagesWrapper,
    HeadingSec,
} from "./Slug.style";

const fixedSelectItems = [
    { key: "default", value: "Default" },
    { key: "twocol", value: "Zweispaltig (nur T3)" },
    { key: "fixedonecol", value: "Einspaltig mit fixiertem Bild (nur T3)" },
    {
        key: "subnavsmall",
        value: "Übersicht mit Subnavigalerie klein (nur T3)",
    },
    { key: "subnavbig", value: "Übersicht mit Subnavigalerie groß (nur T3)" },
    { key: "default", value: "Default" },
    { key: "twocol", value: "Zweispaltig (nur T3)" },
    { key: "fixedonecol", value: "Einspaltig mit fixiertem Bild (nur T3)" },
    {
        key: "subnavsmallfixedheader",
        value: "Übersicht mit Subnavigationsgalerie klein mit fixiertem Bild (nur T3)",
    },
    {
        key: "subnavbig",
        value: "Übersicht mit Subnavigationsgalerie groß (nur T3)",
    },
    { key: "fixedtwocol", value: "Zweispaltig mit fixiertem Bild (nur T3)" },
    { key: "subnavhidden", value: "Einspaltig ohne Subnavigationsgalerie" },
];
const MainContent: FC<any> = (props) => {
    const pageProps = useContext(Context);
    const palette: any = pageProps?.Settings;
    const pageContent = getPage(pageProps);
    const layout = fixedSelectItems.find(
        (item) => item.key === (pageContent as any)?.layout || item.value === (pageContent as any)?.layout
    )?.key;

    // console.log(
    //   ` pageContent?.imageFixedBackground`,
    //   pageContent?.imageFixedBackground
    // );
    // console.log(` pageContent?.twoColumnLayout`, pageContent?.twoColumnLayout);
    // console.log(` layout`, layout);
    switch (true) {
        // for this 4 options we route back to the diffrent old flag combinations
        case layout === "twocol":
            pageContent.imageFixedBackground = false;
            pageContent.twoColumnLayout = true;
            break;
        case layout === "fixedonecol":
            pageContent.imageFixedBackground = true;
            pageContent.twoColumnLayout = false;
            break;
        case layout === "fixedtwocol":
            pageContent.imageFixedBackground = true;
            pageContent.twoColumnLayout = true;
            break;
        case layout === "subnavsmallfixedheader":
            pageContent.imageFixedBackground = true;
            break;
        case layout === null || layout === undefined:
            // if layout is null we do nothing and keep the existing values for imageFixedBackground and twoColumnLayout
            // to support old data in this fields => non breaking change
            // and we do nothing
            break;
        default:
            // for the 4 new cases "default", "subnavhidden", "subnavsmall" and "subnavbig"  we just disable the old options
            pageContent.imageFixedBackground = false;
            pageContent.twoColumnLayout = false;
    }

    const TopComponent = props.topComponent;

    // @ts-ignore
    const images = pageContent?.elements?.filter((item) => item?.__typename === "Asset") as IAsset[];

    const currentNavItem = getCurrentNavItem(pageProps?.PrimaryNavigation?.items, pageProps?.slug);

    let elements: any[] = pageContent?.elements ?? [];

    let firstTextElement: any = undefined;

    if (pageContent?.imageFixedBackground && images?.[0]?.src) {
        // we remove the first text element
        elements = elements
            .filter((o, i) => {
                if (o.__typename === "TextElement" && elements.findIndex((o) => o.__typename === "TextElement") == i) {
                    firstTextElement = o;
                    return false;
                }
                return true;
            })
            .filter((o, i) => {
                if (o.__typename === "Asset" && elements.findIndex((o) => o.__typename === "Asset") == i) {
                    return false;
                }
                return true;
            });
    }

    const PageBody = () => (
        <>
            {elements.map((element: any, i: number) => {
                switch (element.__typename) {
                    case "Asset":
                        const image = element;
                        return (
                            <Fragment key={`${image?.src}${i}`}>
                                <Img
                                    css={styles.mainImageNotFixed}
                                    quality={1920}
                                    // numbers from old template
                                    // /php/image.php?b_id=61063&module=1&i_id=61063_page_10318.png&compression=MED&width=1200&format=JPG&height=581
                                    src={image.src ? image.src + "?w=1200&h=581" : undefined}
                                    alt={image.title ?? ""}
                                />
                                <div data-caption>{image.description}</div>
                            </Fragment>
                        );
                    case "TextElement":
                        const e = element;
                        return (
                            <div key={`${e.id}${i}`}>
                                {[e].map((e, i) => {
                                    const text = e.text && `${e.text}`.trim() != "" ? e.text : null;
                                    if (e.icon.length > 0 && text) {
                                        return (
                                            <Fragment key={`text${i}`}>
                                                {i == 0 ? (
                                                    <Heading>{e.headline}</Heading>
                                                ) : (
                                                    <HeadingSec>{e.headline}</HeadingSec>
                                                )}
                                                <InlineImage>
                                                    <ImagesWrapper withCaption={!!e.icon?.[0]?.description}>
                                                        {e.icon.map((img, ind) => (
                                                            <Fragment key={`${img.src}${ind}`}>
                                                                <FullscreenImage
                                                                    subtitle={img?.description}
                                                                    img={[{ src: img?.src, __typename: "Asset" }]}
                                                                />
                                                                {img?.description && <p>{img.description}</p>}
                                                            </Fragment>
                                                        ))}
                                                    </ImagesWrapper>
                                                    <RichText
                                                        textContent={text}
                                                        overwriteStyle={styles.mainText}
                                                    ></RichText>
                                                </InlineImage>
                                            </Fragment>
                                        );
                                    }

                                    if (e.icon?.[0]?.src && !text) {
                                        return (
                                            <Fragment key={`text${i}`}>
                                                {i == 0 ? (
                                                    <Heading>{e.headline}</Heading>
                                                ) : (
                                                    <HeadingSec>{e.headline}</HeadingSec>
                                                )}
                                                <InlineImage
                                                    background={palette.themeBackgroundColourIsland}
                                                    color={palette.themeTextColourIsland}
                                                >
                                                    {e.icon?.[0]?.src && (
                                                        <FullscreenImage
                                                            quality={1920}
                                                            subtitle={e.icon?.[0]?.description}
                                                            img={[{ src: e.icon?.[0]?.src, __typename: "Asset" }]}
                                                        >
                                                            {" "}
                                                            {e.icon?.[0]?.subtitle && <p>{e.icon?.[0]?.subtitle}</p>}
                                                        </FullscreenImage>
                                                    )}
                                                    <RichText
                                                        textContent={text}
                                                        overwriteStyle={styles.mainText}
                                                    ></RichText>
                                                </InlineImage>
                                            </Fragment>
                                        );
                                    }
                                    if (!text && e.headline) {
                                        return (
                                            <RichText
                                                textContent={{ doc: [] }}
                                                key={`text${i}`}
                                                overwriteStyle={styles.mainText}
                                            >
                                                {i == 0 ? (
                                                    <Heading>{e.headline}</Heading>
                                                ) : (
                                                    <HeadingSec>{e.headline}</HeadingSec>
                                                )}
                                            </RichText>
                                        );
                                    }
                                    return (
                                        <RichText textContent={text} key={`text${i}`} overwriteStyle={styles.mainText}>
                                            {i == 0 ? (
                                                <Heading>{e.headline}</Heading>
                                            ) : (
                                                <HeadingSec>{e.headline}</HeadingSec>
                                            )}
                                        </RichText>
                                    );
                                })}
                            </div>
                        );
                    case "Slider":
                        const sliderImages = element?.elements?.filter(
                            (item) => item?.__typename === "Asset"
                        ) as IAsset[];
                        return (
                            <Fragment key={`${element.id}${i}`}>
                                {sliderImages && (
                                    <ImgGallery
                                        images={sliderImages.map((i) => ({
                                            src: i?.src,
                                            subtitle: i?.description || "kitchen",
                                        }))}
                                    />
                                )}
                            </Fragment>
                        );
                    case "HtmlEmbedding":
                    case "ContinuingPageGallery":
                    case "PhotoGallery":
                        return (
                            <UniversalComponentsRendererWrapper
                                key={`${element.id}${i}`}
                                colorText={palette.themeTextColor}
                                colorPrice={palette.themeContrastColour}
                            >
                                <UniversalComponentsRenderer items={[element]}></UniversalComponentsRenderer>
                            </UniversalComponentsRendererWrapper>
                        );
                    default:
                        return null;
                }
            }) ?? null}
        </>
    );

    return (
        <Wrapper>
            {pageContent?.imageFixedBackground && images?.[0]?.src && <LandingImage img={images?.[0]?.src} />}
            {props.micro && <Micro />}
            <PageContentWrapper textColor={palette.themeTextColor} backgroundColor={palette.themeBackgroundColor}>
                {pageContent?.imageFixedBackground && (
                    <CenteredContent>
                        <FlexWrapper twoColumnLayout={pageContent?.twoColumnLayout}>
                            <div>
                                <div className="mobileHeading">
                                    {firstTextElement?.headline && <h1>{firstTextElement?.headline}</h1>}
                                    {!!firstTextElement?.text &&
                                    richToPlainText(firstTextElement?.text)?.trim() != "" ? (
                                        <RichText textContent={firstTextElement?.text}></RichText>
                                    ) : null}
                                </div>
                                <PageBody />
                            </div>
                            {pageContent?.twoColumnLayout && (
                                <div>
                                    {currentNavItem?.subitems && (
                                        <TeaserGallery
                                            isTwoColumnsLayout={true}
                                            items={currentNavItem.subitems as any[]}
                                        />
                                    )}
                                </div>
                            )}
                        </FlexWrapper>
                    </CenteredContent>
                )}
                {TopComponent && (
                    <TopComponentWrapper priceColor={palette.themeContrastColour} textColor={palette.themeTextColor}>
                        <TopComponent />
                        <div style={{ margin: "20px 0" }} />
                    </TopComponentWrapper>
                )}
                {!pageContent?.imageFixedBackground && (
                    <CenteredContent>
                        <TwoColumnLayout twoColumnLayout={pageContent?.twoColumnLayout}>
                            <div>
                                <PageBody />
                            </div>
                            {currentNavItem && pageContent?.twoColumnLayout && (
                                <TeaserGallery isTwoColumnsLayout={true} items={currentNavItem.subitems as any[]} />
                            )}
                        </TwoColumnLayout>
                    </CenteredContent>
                )}
                {(pageContent as IPageBrand)?.connectedArticles?.length > 0 && (
                    <CenteredContent>
                        {(pageContent as IPageBrand)?.connectedArticles?.map((article, i) => {
                            if (article?.__typename !== "Article") {
                                return null;
                            }
                            return (
                                <Article key={i}>
                                    {article?.headline && <h3>{article?.headline}</h3>}
                                    <div
                                        css={css`
                                            display: flex;
                                        `}
                                    >
                                        <ArticleImageWrapper>
                                            {article?.extras.map((asset, j) => {
                                                if (asset?.__typename !== "Asset") {
                                                    return null;
                                                }
                                                if (pageProps.slug === pageProps.NewsSlug) {
                                                    return (
                                                        <ImageWithAspectRatio
                                                            width={332}
                                                            height={226}
                                                            key={j}
                                                            quality={720}
                                                            src={(asset as IAsset)?.src}
                                                            alt={asset.title}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <FullscreenImage
                                                        key={j}
                                                        quality={720}
                                                        subtitle={asset?.description}
                                                        img={[{ src: asset?.src, __typename: "Asset" }]}
                                                    >
                                                        {" "}
                                                    </FullscreenImage>
                                                );
                                            })}
                                        </ArticleImageWrapper>
                                        <div>
                                            {article?.mainText && <RichText textContent={article?.mainText}></RichText>}
                                        </div>
                                    </div>
                                </Article>
                            );
                        })}
                    </CenteredContent>
                )}
                {pageContent?.footnote && (
                    <CenteredContent mt={50}>
                        <RichText textContent={pageContent?.footnote} />
                    </CenteredContent>
                )}
                {currentNavItem &&
                    !pageContent?.twoColumnLayout &&
                    !["subnavsmall", "subnavbig", "subnavhidden", "subnavsmallfixedheader"].includes(layout) &&
                    layout !== "subnavhidden" && (
                        <CenteredContent mt={50} textColor={palette.themeTextColor}>
                            <TeaserGallery isTwoColumnsLayout={false} items={currentNavItem.subitems as any[]} />
                        </CenteredContent>
                    )}
                {layout == "subnavsmall" ||
                    (layout == "subnavsmallfixedheader" && (
                        <CenteredContent mt={50} textColor={palette.themeTextColor}>
                            <TeaserGallerySmall items={currentNavItem.subitems as any[]} />
                        </CenteredContent>
                    ))}
                {layout == "subnavbig" && (
                    <CenteredContent mt={50} textColor={palette.themeTextColor}>
                        <TeaserGalleryBig items={currentNavItem.subitems as any[]} />
                    </CenteredContent>
                )}
            </PageContentWrapper>
        </Wrapper>
    );
};
export default MainContent;
