import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";
import { theme } from "../../../../constants/themes";

export const Title: any = styled.div`
  width: 100%;
  height: calc(308px - 60px);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  top: 0;
  padding: 0px 20px;
  h1 {
    text-align: center;
    color: rgb(255, 255, 255);
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 40px;
    font-weight: 300;
    line-height: 48px;
    text-shadow rgb(68, 68, 68) 0px 1px 1px;
    margin: 0;
  };
  @media (${BREAKPOINTS_DM.silver_768}) {
    height: 187px;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    top: 0;
    h1 {
      text-align: center;
      width: 1170px;
      color: rgb(255, 255, 255);
      display: block;
      font-family: Roboto, sans-serif;
      font-size: 40px;
      font-weight: 300;
      line-height: 48px;
      text-shadow rgb(68, 68, 68) 0px 1px 1px;
      margin: 0;
    }
  };
`;

export const HeroWrapper: any = styled.div`
  background-color: ${theme.palette.default};
  min-height: 200px;
  > div {
    &:first-child {
      position: relative;
      ::before {
        width: 100%;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%; /* Adjust as needed to control shadow height */
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent);
        pointer-events: none;
        z-index: 0;
      }
    }

    img {
      height: 308px;
    }
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
  }
`;
