import { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const wrapper: any = css`

`;

export const h3: any = css`
    font-size: 15px;
    color: ${props => props.theme.palette.red};
    font-weight: 700;
    margin-bottom: 10px;
`;

export const img: any = css`
    width: 100%;
    height: auto;
    @media (${BREAKPOINTS_DM.silver_768})
    {
        height: auto;
    }
`;

export const footnote: any = css`
    margin: 40px 0 20px 0;
`;

export const imgAndHeadline: any = css`
    display: flex;
    flex-direction: column;
    @media (${BREAKPOINTS_DM.silver_768})
    {
        flex-direction: row;
        align-items: center;
    }
`;
