import styled from "styled-components";

export const MicroWrapper: any = styled.div`
  width: 100%;
  h1 {
    line-height: 42px;
  }
  iframe {
    width: 100%;
    height: 100vh;
    margin-top: 21px;
  }
`;
