import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const Wrapper: any = styled.div<{ isUniversalElement: boolean }>`
  background-color: ${({ isUniversalElement }) => isUniversalElement && "#fff"};
  width: 100%;
  color: #666;
`;

export const ContentContainer: any = styled.div`
  display: flex;
  flex-direction: column;
  width: 1170;
  margin: 0px auto;
  padding: 0 10px;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    flex-direction: row;
    padding: 0;
  }
  .c-frame {
    iframe {
      width: 280%;
      height: 364px;
    }
  }

  h2,
  h3 {
    &,
    span {
      color: #444;
      font-size: 18px;
      font-weight: 300;
      line-height: 21.6px;
      margin: 5px 0 10px;
    }
  }

  h1 {
    padding: 2px 10px 2px 0p;
    &,
    span {
      font-weight: 700;
    }
  }
  a > span {
  }
  p {
    margin-bottom: 21px;
    span {
      font-size: 16px;
      line-height: 21px;
      font-weight: 300;
      letter-spacing: 0.4px;
    }
  }
  strong {
    font-weight: 700;
  }
  span {
    font-size: 16px;
    line-height: 21px;
    font-weight: 300;
    letter-spacing: 0.4px;
  }
`;

export const ContentWrapper: any = styled.div<{
  pageBrand: boolean;
  isSideRight: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ pageBrand }) => pageBrand && "0 18px 0 0"};

  ul {
    display: block;
    list-style-type: disc;
    margin-bottom: 10px;
    padding-inline-start: 40px;
    li {
      list-style-type: disc;
      span {
        color: #666;
        font-size: 16px;
        line-height: 21px;
        font-weight: 300;
        letter-spacing: 0.4px;
        strong {
          font-weight: 700;
        }
      }
    }
  }
`;

export const ImageInText: any = styled.div<{
  isUniversalElement: boolean;
  firstTextElement: boolean;
  reverse: boolean;
}>`
  width: 100%;
  max-width: 1170px;
  margin: ${({ firstTextElement }) =>
    firstTextElement ? "auto" : "0 auto 60px"};
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? "column-reverse" : "column")};
  p {
    color: #666;
    margin-bottom: 10px;
    span {
      color: #666;
      font-size: 16px !important;
      line-height: 24px;
      font-weight: 300;
      letter-spacing: 0.4px;
    }
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
  }
`;

export const ImageWRapper: any = styled.div<{ center: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 34px 0 30px;
  ${({ center }) => center && "justify-content: center"};
  img {
    > img {
      height: auto;
    }
    min-height: "143px";
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const ImageContent: any = styled.div`
  height: auto;
  width: 100%;
  padding: 0 30px;
  margin: 0 0 30px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 50%;
  }
`;

export const figcaption: any = css`
  padding: 10.5px;
  font-size: 13px;
  font-weight: 300;
`;

export const UniversaElementWrapper: any = styled.div<{
  fixDataGallery: boolean;
}>`
  margin: 0px auto;
  width: 100%;
  max-width: 1170px;
  padding: 0 15px;
  .slick-prev,
  .slick-next {
    z-index: 9;
    background-color: rgba(255, 255, 255, 0.5) !important;
    width: 30px !important;
    height: 30px !important;
    :before {
      opacity: 1;
      display: inline-block;
      content: "" !important;
      background-image: url(/images/sprite-sa3ca3ca63e.png);
      background-repeat: no-repeat;
      background-position: 0 -172px;
      height: 18px;
      width: 12px;
      color: transparent !important;
    }

    :hover {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }

  .slick-next {
    right: 0 !important;
  }

  .slick-prev {
    left: 0 !important;
    :before {
      transform: rotate(180deg);
    }
  }

  .slick-slide {
    padding-top: 6px;
    &:not(.slick-current) {
      opacity: 0.5;
    }
  }
  .slick-slide:not(.slick-current) {
    opacity: ${({ fixDataGallery }) => (fixDataGallery ? "1" : ".5")};
  }
  .caption-description {
    color: #fff;
  }

  [data-gallery-slider-tracker] {
    img {
      padding-right: 5px;
      max-height: 73px;
    }
  }

  [data-caption] {
    color: #fff;
    padding: 0 10px;
  }

  /* [data-gallery] {
    img {
      height: ${({ fixDataGallery }) => (fixDataGallery ? "500px" : "100px")};
      object-fit: cover;
      @media (${BREAKPOINTS_DM.silver_768}) {
        height: ${({ fixDataGallery }) => (fixDataGallery ? "500px" : "160px")};
        width: ${({ fixDataGallery }) => (fixDataGallery ? "1140px" : "280px")};
      }
      margin: 5px 0;
    }
    div > div {
      display: flex;
      justify-content: center;
    }
  } */

  [data-gallery="mosaicimage"] {
    img {
      height: 300px;
      width: 292px;
      margin: 5px 0;
    }
    div > div {
      display: flex;
      flex-direction: column;
    }
  }

  [data-image-comparison-item] {
    width: 1140px;
    height: 600px;
    [data-image-left] {
      &:before {
        background-position-y: center;
        width: 1140px;
      }
    }
    [data-image-right] {
      height: 600px;
      &:before {
        background-position-y: center;
        width: 1140px;
      }
    }
  }
`;

export const ElementWrapper: any = styled.div<{
  withOutBorder: boolean;
  guesBook: boolean;
}>`
  border-top: ${({ withOutBorder }) =>
    withOutBorder ? "none" : "1px solid #888"};
  height: auto;
  padding-top: ${({ guesBook }) => !guesBook && "40px"};
  /* background-color: var(--color-primary); */
`;

export const Title: any = styled.div<{ isFirst: boolean }>`
  widht: 100%;
  max-width: 1170px;
  color: #666666;
  font-weight: 100;
  ${({ isFirst }) =>
    isFirst
      ? `
    font-weight: 700;
    margin: 5px auto 10px;
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: .02em;
  `
      : `
    color: #444;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.6px;
    padding: 0 30px;
    margin: 5px auto 10px;
  `}
`;

export const TextWrapper: any = styled.div<{ withImg: boolean }>`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  li,
  ul,
  span,
  div {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.5em;
    font-size: 16px;
    box-sizing: border-box;
  }
  h1{
    margin: 5px 0 24px;
  }
  h2{
    margin: 5px 0 16px;
  }
  h3{
    font-size: 18px;
    margin: 5px 0 10px;
  }
  h4,
  h5,
  h6,
  p,
  ol,
  ul
   {
    min-height: 1.5rem;
    maring-bottom: 10px;
  }

  width: 100%;
  padding: 0 30px;
  table {
    h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  li,
  ul,
  span,
  div {
    hyphens: initial;
  }
    width: 100%;
    td,
    th {
      padding: 5px 10px;
    }
  }
  h1 {
    &,
    span {
      font-weight: 300;
      font-size: 34px;
    }
  }
  h1 {
    margin: 0px 0 20px !important;
    a {
      background-color: #e5e5e5;
      border: 2px solid #ccc;
      color: #333;
      padding: 9.5px 16px;

      span {
        font-weight: 500 !important;
        font-size: 13px;
        letter-spacing: 1px;
        line-height: 22px;
      }
      &:hover {
        background-color: #e0e0e0;
        border-color: #adadad;
        span {
          color: #333;
        }
      }
    }
  }
  p {
    span {
      strong {
        font-weight: 700;
      }
    }
    a {
      color: var(--color-primary);
      span {
        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }
  a {
    color: var(--color-primary);
    span {
      &:hover {
        color: var(--color-primary);
      }
    }
  }
  h3 {
    span {
      color: #444;
      font-size: 18px;
      font-weight: 300;
      line-height: 21.6px;
      margin: 5px 0 10px;
    }
  }
  ul {
    padding-left: 40px;
    li {
      list-style-type: disc;
      margin: 18px 0;
    }
  }
`;

export const MixedWrapper: any = styled.div`
  @media (${BREAKPOINTS_DM.silver_768}) {
  }
`;

export const LinkWrapper: any = styled.a`
  &:hover {
    > div > div > p > a > span {
      color: #76b729;
    }
  }
`;

export const FirstTextElementWrapper: any = styled.div<{
  withoutHero: boolean;
}>`
  min-height: 40px;
  padding: 50px 0px 30px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 50px 30px 30px;
  }
`;

export const NavBarSubitemsWrapper: any = styled.div`
  width: 100%;
  background-color: #f5f5f5f5;
  padding: 15px 0;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: red;
  @media (${BREAKPOINTS_DM.silver_768}) {
    min-height: 65px;
  }
`;
export const NavBarSubitemsContent: any = styled.div`
  width: 100%;
  height: auto;
  max-width: 1170px;
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const NavBarSubitemsButton: any = styled.a<{ isCurrent: boolean }>`
  display: flex;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding: 6.25px 0px;
  color: ${({ isCurrent }) => (isCurrent ? "var(--color-primary)" : "#666")};
  &:hover {
    color: var(--color-primary);
  }
`;

export const HorizontalSeparator: any = styled.div`
  margin: 0px 10px;
  height: 14px;
  border-right: 1px solid #666;
`;

export const UniversalElementContent: any = styled.div`
  width: 100%;
  background-color: var(--color-primary);
  padding: 40px 0;
  margin-top -41px;
  .button-wrapper {
    width: 100%
  }
  .button {
    border: 2px solid #fff;
    color: #fff;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 16px;
    margin: 50px auto 0;
    padding: 13px 30px;
    width: fit-content;
    &:hover {
      background-color: var(--color-primary);
      text-decoration: underline;
    }
  }
`;
