import React, { FunctionComponent, useContext } from "react";
import { ITextElement, Context, IPage } from "src/common";

import * as styles from "./TeaserGallery.style";
import { ItemWrapper } from "./TeaserGallery.style";

interface IProps {
  items: IPage[];
  isTwoColumnsLayout: boolean;
}

export const TeaserGallery: FunctionComponent<IProps> = ({
  items,
  isTwoColumnsLayout,
}) => {
  const p = useContext(Context);
  const palette = p?.Settings;

  if (!items || items.length === 0) {
    return null;
  }
  return (
    <div
      css={`
        ${styles.flexWrapper}${isTwoColumnsLayout &&
        styles.twoColumns_flexWrapper}
      `}
    >
      {items.map((_item, i) => {
        const item: any = _item;

        if (item.__typename === "ExternalLink") {
          return <ItemWrapper
            key={i}
            color={palette.themeTextColor}
            primary={palette.themeColorPrimary}
          >
            {item?.externalLinkName && <h3>{item?.externalLinkName}</h3>}
            {item?.externalLinkAddress && (
              <a
                target="_blank"
                href={
                  "https://" +
                  `${item?.externalLinkAddress}`
                    .replace("http:", "https:")
                    .replace("https://", "")
                }
                css={styles.mainText}
                style={{
                  color:
                    i == 0 && isTwoColumnsLayout
                      ? "white"
                      : palette.themeColorPrimary
                      ? palette.themeColorPrimary
                      : "#222",
                }}
              >
                {"Mehr"}{" "}
              </a>
            )}
          </ItemWrapper>;
        }
        if (item?.slug == p.slug) {
          return null;
        }
        const textElement = item?.elements?.find(
          (e) => e?.__typename === "TextElement"
        ) as ITextElement;
        const firstParagraph = textElement?.text?.content?.find(
          (c) => c.children?.[0]?.text?.length > 1
        )?.children?.[0]?.text;
        if (!item?.slug) {
          return null;
        }
        const sayGalley = !!item?.elements?.find(
          (e) => e?.__typename === "Slider"
        );
        return (
          <ItemWrapper
            key={i}
            color={palette.themeTextColor}
            primary={palette.themeColorPrimary}
          >
            {item?.nameInNavigation && <h3>{item?.nameInNavigation}</h3>}
            {textElement?.headline && <h4>{textElement.headline}</h4>}
            {firstParagraph && <p css={styles.mainText}>{firstParagraph}</p>}
            {item?.slug && (
              <a
                href={item?.slug}
                css={styles.mainText}
                style={{
                  color:
                    i == 0 && isTwoColumnsLayout
                      ? "white"
                      : palette.themeColorPrimary
                      ? palette.themeColorPrimary
                      : "#222",
                }}
              >
                {sayGalley ? "zur Galerie" : "Mehr"}{" "}
              </a>
            )}
          </ItemWrapper>
        );
      })}
    </div>
  );
};
